import fixedSubscription from "~/integrations/fixed-subscription";
import { _fetchData } from "~/helpers/store";

const getDefaultState = () => {
	return {
		promise: null,
		data: [],
		error: null,
	};
};

export const state = getDefaultState;
export const actions = {
	async fetchData(context, { subscriptionId, force = false }) {
		return _fetchData(
			() =>
				Promise.all([
					fixedSubscription.getSubscriptionUser(subscriptionId),
					fixedSubscription.getSubscriptionOwner(subscriptionId),
					fixedSubscription.getSubscriptionPayer(subscriptionId),
				]),
			{ context, force, name: "subscriptionUserOwnerPayer" },
		);
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	},
};
export const getters = {
	loading(state) {
		return !!state.promise;
	},
	error(state) {
		return state.error;
	},
	getUserOwnerPayer(state) {
		if (state?.data?.length === 3) {
			return {
				user: state.data[0],
				owner: state.data[1],
				payer: state.data[2],
			};
		}
	},
};
