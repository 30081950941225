import { pickBy } from "../object-utility";

const getFullPrices = (product) => {
	return {
		price: product.priceWithVat || undefined,
		priceWithoutVat: product.price || undefined,
		salePrice: product.salePriceWithVat || undefined,
		salePriceWithoutVat: product.salePrice || undefined,
		memberPrice: product?.memberPrice?.prices?.withVat?.memberPrice || undefined,
		memberPriceWithoutVat: product?.memberPrice?.prices?.withoutVat?.memberPrice || undefined,
	};
};

const getSwapPrices = (product) => {
	return {
		swapMonthlyPrice: product.swapMonthlyPrice || undefined,
		swapDiscountedMonthlyPrice: product.swapDiscountedMonthlyPrice || undefined,
		swapTotalPrice: product.swapTotalPrice || undefined,
		swapDiscountedTotalPrice: product.swapDiscountedTotalPrice || undefined,
	};
};

const getDownPaymentPrices = (product) => {
	return {
		downPaymentPrice: product.priceWithVat / product.installmentsInMonths || undefined,
		discountedDownPaymentPrice: product.salePriceWithVat / product.installmentsInMonths || undefined,
		downPaymentPriceWithoutVat: product.price / product.installmentsInMonths || undefined,
		discountedDownPaymentPriceWithoutVat: product.salePrice / product.installmentsInMonths || undefined,
		downPaymentMemberPrice:
			product.memberPrice?.prices?.withVat.memberPrice / product.installmentsInMonths || undefined,
		downPaymentMemberPriceWithoutVat:
			product.memberPrice?.prices?.withoutVat.memberPrice / product.installmentsInMonths || undefined,
		downPaymentInstallmentsInMonths: parseInt(product.installmentsInMonths) || undefined,
	};
};

export const getPriceObject = (product) => {
	return pickBy({
		...getFullPrices(product),
		...getSwapPrices(product),
		...getDownPaymentPrices(product),
	});
};
