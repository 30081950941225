export function stringifyQuery(query) {
	if (!query) {
		return "";
	} else {
		const queryAsString = Object.entries(query)
			.filter(([key, value]) => !!value)
			.map(([key, value]) => {
				if (Array.isArray(value)) return value.map((item, index) => `${key}[${index}]=${item}`).join("&");
				else return `${key}=${value}`;
			})
			.join("&");
		return encodeURI("?" + queryAsString);
	}
}
