const apiUrl = (isClient = import.meta.client) => {
	let apiUrl = "https://atest-api.telenor.no";

	const isProduction = () => {
		return window.location.hostname.indexOf("test") < 0 && window.location.hostname.indexOf("localhost") < 0;
	};
	if (isClient && isProduction()) {
		apiUrl = apiUrl.replace("atest-", "");
	}
	return apiUrl;
};

export default { apiUrl };
