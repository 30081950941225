import { formatPrice } from "~/helpers/formatting/price";
const actions = {
	REMOVED: "REMOVED",
	NO_CHANGE: "NO_CHANGE",
	NEW: "NEW",
};

const getMonthlyPrice = (product) => product?.prices?.MONTHLY?.amount;

// getMonthlyDiscountedPrice :: JSON -> Boolean
export const getMonthlyDiscountedPrice = (product) => getMonthlyPrice(product) - getMonthlyDiscount(product);

// getMonthlyDiscount :: JSON -> Boolean
const getMonthlyDiscount = (product) => Math.abs(product?.discounts?.MONTHLY?.price?.amount) || 0;

// getOneTimePrice :: JSON -> Number
export const getOneTimePrice = (product) => product?.prices?.ONE_TIME?.amount || 0;

// getOneTimeDiscountedPrice :: JSON -> Boolean
export const getOneTimeDiscountedPrice = (product) => getOneTimePrice(product) - getOneTimeDiscount(product);

// getMonthlyDiscount :: JSON -> Boolean
const getOneTimeDiscount = (product) => Math.abs(product?.discounts?.ONE_TIME?.price?.amount) || 0;

export const getNewMainProducts = (validatedOrder) =>
	validatedOrder?.orderInformation?.offers[0]?.products
		?.filter(isMainProduct)
		?.filter((product) => product?.action === actions.NEW) || [];

export const getRemovedMainProducts = (validatedOrder) =>
	validatedOrder?.orderInformation?.offers[0]?.products
		?.filter(isMainProduct)
		?.filter((product) => product?.action === actions.REMOVED);

export const getNoChangeMainProducts = (validatedOrder) =>
	validatedOrder?.orderInformation?.offers[0]?.products
		?.filter(isMainProduct)
		?.filter((product) => product?.action === actions.NO_CHANGE);

export const getMainProductPriceLine = ({ mainOffer, mainProducts, additionalInfo }) => ({
	name: mainProductName({ mainProducts, mainOffer }),
	price: mainProducts?.every((prod) => prod.action === actions.NO_CHANGE)
		? `${formatPrice.monthly(mainProductsPrice({ mainProducts, mainOffer }))}`
		: `${formatPrice.monthly(mainProductsPrice({ mainProducts, mainOffer }) - getMonthlyDiscount(mainOffer))}`,
	expired: mainProducts?.every((product) => product?.action === actions.REMOVED),
	discountedPrice: mainProducts?.every((prod) => prod.action === actions.NO_CHANGE)
		? `${formatPrice.monthly(
				mainProductsPrice({ mainProducts, mainOffer }) - Math.abs(mainProductsDiscountedPrice(mainProducts)),
			)}`
		: `${formatPrice.monthly(
				mainProductsPrice({ mainProducts, mainOffer }) -
					Math.abs(mainProductsDiscountedPrice(mainProducts)) -
					getMonthlyDiscount(mainOffer),
			)}`,
	discounts:
		Math.abs(mainProductsDiscountedPrice(mainProducts)) > 0
			? {
					name: `Kampanjepris (${mainProductDiscountDuration(mainProducts)} md.)`,
					discount: `-${formatPrice.monthly(Math.abs(mainProductsDiscountedPrice(mainProducts)))}`,
					duration: mainProductDiscountDuration(mainProducts),
				}
			: null,
	additionalInfo: additionalInfo ?? null,
});

export const getCurrentSubscriptionPriceLine = ({ currentSub }) => ({
	name: currentSub?.mainProduct?.names?.text,
	price: `${formatPrice.monthly(mainProductsPrice({ mainProducts: [currentSub?.mainProduct] }))}`,
	expired: true,
});

// mainProductName :: Array Json -> String
export const mainProductName = ({ mainProducts }) =>
	mainProducts?.reduce(
		(acc, curr) =>
			acc
				? acc.concat(` og ${curr.productName || curr.texts?.name?.text}`)
				: acc.concat(`${curr.productName || curr.texts?.name?.text}`),
		"",
	);

export const mainProductDiscountDuration = (products) =>
	products?.find(hasDiscounts)?.discounts?.MONTHLY?.effectiveNumberOfMonths;

export const getAddonProducts = (validatedOrder) =>
	validatedOrder?.orderInformation?.offers[0]?.products
		?.filter((product) => !isMainProduct(product))
		?.reduce(concatEqualProducts, []);

export const getEquipmentToOrder = (validatedOrder) =>
	validatedOrder?.orderInformation?.offers?.map(
		(product) => product?.products?.filter((product) => !isMainProduct(product))?.reduce(concatEqualProducts, [])[0],
	);

export const getPriceLines = ({ addons, units }) =>
	addons?.map((product) => ({
		name: `${product?.productName || product?.texts?.name?.text}`,
		amount: `(${product?.numSelected} stk.)`,
		price: product?.prices?.MONTHLY
			? `${formatPrice.monthly(getMonthlyPrice(product) * product?.numSelected)}`
			: `${formatPrice.oneTime(getOneTimePrice(product) * product?.numSelected)}`,
		discountedPrice: product?.prices?.MONTHLY
			? `${formatPrice.monthly(getMonthlyDiscountedPrice(product) * product?.numSelected)}`
			: `${formatPrice.oneTime(getOneTimeDiscountedPrice(product) * product?.numSelected)}`,
		discounts: discountLine(product, units),
		totalPrice: getMonthlyPrice(product) * product?.numSelected,
	}));

// discountLine :: JSON -> String
export const discountLine = (product) =>
	hasDiscounts(product)
		? {
				name: product?.downPaymentAgreement?.description
					? product?.downPaymentAgreement?.description
					: `Kampanjepris ${
							isMonthlyDiscount(product) ? "(" + product?.discounts?.MONTHLY?.effectiveNumberOfMonths + " md.)" : ""
						}`,
				discount: isMonthlyDiscount(product)
					? `${formatPrice.monthly(getMonthlyDiscount(product))}`
					: `${formatPrice.oneTime(getOneTimeDiscount(product))}`,
			}
		: null;

// mainProductsPrice :: Array JSON -> Number
export const mainProductsPrice = ({ mainProducts }) =>
	mainProducts?.reduce((acc, curr) => acc + curr?.prices?.MONTHLY?.amount || 0, 0);

// mainProductsDiscountedPrice :: JSON -> Number
export const mainProductsDiscountedPrice = (mainProducts) =>
	mainProducts?.reduce(
		(acc, curr) => (hasDiscounts(curr) ? acc + Math.abs(curr?.discounts?.MONTHLY?.price?.amount) : acc + 0),
		0,
	);

// mainProductsOrOfferHasDiscounts :: JSON -> Boolean
export const mainProductsOrOfferHasDiscounts = ({ mainOffer, mainProducts }) =>
	[mainOffer, ...mainProducts]?.some(hasDiscounts);

// isMainProduct :: JSON -> Boolean
export const isMainProduct = (product) => product.isMainProduct;

// hasDiscounts :: JSON -> Boolean
export const hasDiscounts = (product = {}) => {
	if (
		product?.discounts?.ONE_TIME?.price?.amount === product?.prices?.ONE_TIME?.amount &&
		product?.discounts?.ONE_TIME &&
		product?.prices?.ONE_TIME
	) {
		return false;
	} else if (product.discounts) {
		return Object?.keys(product?.discounts)?.length > 0 || false;
	}
	return false;
};

// isMonthlyDiscount :: JSON -> Boolean
export const isMonthlyDiscount = (product) => product?.discounts?.MONTHLY || false;

// concatEqualProducts :: (Array JSON, JSON) -> Array JSON
export const concatEqualProducts = (acc, curr) =>
	!acc?.find((product) => product?.productId === curr?.productId)
		? acc?.concat([{ ...curr, numSelected: 1 }])
		: acc.map((product) =>
				product?.productId === curr?.productId ? { ...product, numSelected: product?.numSelected + 1 } : product,
			);

export const getOneTimePriceLines = ({ addons, units }) => {
	return addons?.map((product) => ({
		name: `${product?.productName || product?.texts?.name?.text}`,
		amount: `(${product?.numSelected} stk.)`,
		price: `${formatPrice.oneTime(getOneTimePrice(product) * product?.numSelected)}`,
		discountedPrice: Object.values(product?.oneTimeDiscount || {}).length
			? formatPrice.oneTime(product?.oneTimeDiscount?.amount)
			: `${formatPrice.oneTime(getOneTimeDiscountedPrice(product) * product?.numSelected)}`,
		discounts: product?.oneTimeDiscount ? antennaDiscountLine(product, units) : discountLine(product, units),
	}));
};

export const antennaDiscountLine = (product) => {
	if (Object.keys(product?.oneTimeDiscount)?.length) {
		return {
			name: product?.oneTimeDiscount?.productName,
			discount: `${formatPrice.oneTime(product?.oneTimeDiscount?.amount)}`,
		};
	}
	return false;
};

export const isFree = (product) => {
	return !!(
		(product?.prices?.ONE_TIME && product?.prices?.ONE_TIME?.amount === 0) ||
		(product?.prices?.MONTHLY && product?.prices?.MONTHLY?.amount === 0) ||
		(!product?.prices?.MONTHLY && !product?.prices?.ONE_TIME)
	);
};

export const validatedPricesIsFree = (product) => {
	return product?.amount && product.amount === 0;
};
export const getBindingFeeText = (bindingInfo, asObject = false) => {
	let bindingPeriod = "";
	let fee = bindingInfo.price?.BINDING_TERMINATION_FEE?.amount;
	let feeInfo = fee ? `(bruddgebyr ${fee},-)` : "";
	switch (bindingInfo.periodType) {
		case "MONTHS":
			bindingPeriod = `${bindingInfo.period} md.`;
			break;
		case "DAYS":
			bindingPeriod = `${bindingInfo.period} dager`;
			break;
		case "YEARS":
			bindingPeriod = `${bindingInfo.period} år`;
			break;
	}
	const asText = `${bindingPeriod} binding ${feeInfo}`;
	const feeObject = { fee: formatPrice.oneTimePrefixCurrency(fee), duration: bindingPeriod };
	return asObject ? feeObject : asText;
};
