import streamingPlusIntegration from "~/integrations/streaming-plus";
import { _fetchData } from "~/helpers/store";
const getDefaultState = () => {
	return {
		promise: null,
		data: [],
		error: null,
		selectedAvailableBundleType: null,
		loading: false,
	};
};
export const state = getDefaultState;
export const actions = {
	async fetchData(context, force = false) {
		return _fetchData(() => streamingPlusIntegration.fetchStreamingPlusBundleCatalog(), {
			context,
			force,
			name: "streaming-plus-bundle-catalog",
		});
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	setSelectedAvailableBundleType(state, type) {
		state.selectedAvailableBundleType = type;
	},
	setLoading(state, loading) {
		state.loading = loading;
	},
};
export const getters = {
	data: (state) => {
		return state.data;
	},
	error: (state) => {
		return state.error;
	},
	loading: (state) => {
		return !!state.promise || state.loading;
	},
	selectedAvailableBundleType: (state) => {
		return state.selectedAvailableBundleType;
	},
	selectedAvailableBundle: (state, getters) => {
		return (
			getters.data?.availableBundles?.find((bundle) => bundle?.bundleType === getters.selectedAvailableBundleType) ||
			null
		);
	},
	getProviderCatalogByProviderId: (state, getters) => (providerId) => {
		return getters?.selectedAvailableBundle?.providers?.find((provider) => provider.providerId === providerId);
	},
	getPlanVariantByVariantId: (state, getters) => (variantId) => {
		let planVariant = null;
		getters?.selectedAvailableBundle?.providers?.forEach((provider) => {
			provider.plans.forEach((plan) => {
				plan.variants.forEach((variant) => {
					if (variant.variantId === variantId) {
						planVariant = {
							providerId: provider.providerId,
							planId: plan.planId,
							variantId: variant.variantId,
						};
					}
				});
			});
		});
		return planVariant;
	},
	includedPointCount: (state, getters) => {
		return getters.selectedAvailableBundle?.includedPointCount;
	},
};
