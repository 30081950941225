import apigeeIntegration from "./self-service-adapter";
import systemUserIntegration from "~/integrations/self-service-system-adapter";
import { v4 as uuid } from "uuid";
import qs from "qs";

const api = apigeeIntegration("/customers/v1");
const systemUserApi = systemUserIntegration("/customers/v1");

const DISABLE_CACHE = {
	cache: { override: true },
};

const getCustomerData = () => api.get("/");
const getCustomerDataWithoutCache = () => api.get(`/?time=${new Date().getTime()}`, DISABLE_CACHE);
const resolveCustomer = (userId) => api.get(`/userids/${userId}`);
const getContactDetails = () => api.get(`/contactdetails?rand=${uuid()}`, DISABLE_CACHE);
const updateContactDetails = (contactInfo) =>
	api.put("/contactdetails", qs.stringify(contactInfo), {
		headers: {
			"Content-type": "application/x-www-form-urlencoded",
		},
	});
const searchUser = (firstName, lastName, birthDate, postalCode) =>
	api
		.get(
			`/search?firstName=${firstName}&lastName=${lastName}&birthDate=${birthDate}${
				postalCode ? "&postalCode=" + postalCode : ""
			}`,
		)
		.then((user) => {
			if (!user) {
				throw new Error("Fant ikke bruker");
			}
			return user;
		});

const searchUserForLoggedOutUsers = (firstName, lastName, birthDate, postalCode) =>
	systemUserApi
		.get(
			`/search?firstName=${firstName}&lastName=${lastName}&birthDate=${birthDate}${
				postalCode ? "&postalCode=" + postalCode : ""
			}`,
		)
		.then((user) => {
			if (!user) {
				throw new Error("Fant ikke bruker");
			}
			return user;
		});
//Send code to customers email, customer must present this code to validate the email address.
const sendEmailVerificationCode = (emailAddress) =>
	api.post(`/contactdetails/verification/send/email`, qs.stringify({ emailAddress: emailAddress }), {
		headers: {
			"Content-type": "application/x-www-form-urlencoded",
		},
	});
//Verify that the code is the same code as sent to the customer, update customers email if the code was correct.
const confirmEmailVerificationCode = (code, emailAddress) =>
	api.put(`/contactdetails/verification/email`, qs.stringify({ code: code, emailAddress: emailAddress }), {
		headers: {
			"Content-type": "application/x-www-form-urlencoded",
		},
	});

//Send code to customers email, customer must present this code to validate the email address.
const sendSmsVerificationCode = (phoneNumber) =>
	api.post(`/contactdetails/verification/send/sms`, qs.stringify({ phoneNumber: phoneNumber }), {
		headers: {
			"Content-type": "application/x-www-form-urlencoded",
		},
	});
//Verify that the code is the same code as sent to the customer, update customers email if the code was correct.
const confirmSmsVerificationCode = (code, phoneNumber) =>
	api.put(`/contactdetails/verification/sms`, qs.stringify({ code: code, phoneNumber: phoneNumber }), {
		headers: {
			"Content-type": "application/x-www-form-urlencoded",
		},
	});

export {
	getCustomerData,
	getCustomerDataWithoutCache,
	resolveCustomer,
	searchUser,
	searchUserForLoggedOutUsers,
	getContactDetails,
};

export default {
	getCustomerData,
	getCustomerDataWithoutCache,
	resolveCustomer,
	getContactDetails,
	searchUser,
	searchUserForLoggedOutUsers,
	updateContactDetails,
	sendEmailVerificationCode,
	confirmEmailVerificationCode,
	sendSmsVerificationCode,
	confirmSmsVerificationCode,
};
