export default {
	state() {
		return {
			token: "",
		};
	},
	actions: {
		setToken(state, token) {
			state.token = token;
		},
	},
	mutations: {
		setToken(state, token) {
			state.token = token;
		},
	},
};

export const state = () => ({
	token: "",
});
export const actions = {
	setToken(state, token) {
		state.token = token;
	},
};
export const mutations = {
	setToken(state, token) {
		state.token = token;
	},
};
export const getters = {};
