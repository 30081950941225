/**
 * Converts a kebab-case string to camelCase.
 * @param {string} word - The kebab-case string to convert.
 * @returns {string} - The converted camelCase string.
 */
const kebabToCamelCase = (word: string): string => {
	return word
		.split("-")
		.filter((segment) => segment.length > 0) // Remove empty strings from leading/trailing/multiple hyphens
		.map(
			(segment, index) =>
				index === 0
					? segment.toLowerCase() // Lowercase the first segment
					: segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase(), // Capitalize first letter of subsequent segments
		)
		.join("");
};

/**
 * Converts a Nuxt 2 store path to the Nuxt 3 format by converting kebab-case segments to camelCase.
 * @param {string} path - The Nuxt 2 store path.
 * @returns {string} - The converted Nuxt 3 store path.
 */
const convertNuxt2StorePathToNuxt3 = (path: string): string => {
	return path
		.split("/")
		.map((segment) => {
			// If the segment doesn't contain a hyphen, return it as is
			if (!segment.includes("-")) {
				return segment;
			}
			// Convert kebab-case segment to camelCase
			return kebabToCamelCase(segment);
		})
		.join("/");
};

export default { convertNuxt2StorePathToNuxt3 };
