// This helper uses the BCP 47 language tag for Norway :: no-NO
// NB! You can not do maths with a formatted price

const nbsp = new RegExp(String.fromCharCode(160), "g");

export default (price) => {
	if (price === undefined) return 0;
	if (typeof price === "string") price = parseInt(price);
	return new Intl.NumberFormat("no-NO", {
		style: "currency",
		currency: "NOK",
	})
		.format(price)
		.replace("NOK", "")
		.replace(",", ".")
		.replace(".00", ",-")
		.replace(nbsp, " ");
};
