import fixedSubscription from "~/integrations/fixed-subscription";
import { _fetchData } from "~/helpers/store";

export const state = () => ({
	promise: null,
	data: [],
	error: null,
	offers: [],
	promiseSettled: null,
	myOffers: [],
});
export const actions = {
	async fetchData(context, force = false) {
		return _fetchData(() => fixedSubscription.getSubscriptions(), { context, force, name: "fixed subscriptions" });
	},
};

export const mutations = {
	setData(state, data) {
		state.data = data?.subscriptions || [];
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	addOffer(state, offer) {
		state.offers.push(offer);
	},
	addMyOffer(state, offer) {
		if (!state.myOffers.find((element) => element.offerId === offer.offerId)) {
			state.myOffers.push(offer);
		}
	},
	setPromiseSettled(state, promiseSettled) {
		state.promiseSettled = promiseSettled;
	},
};
export const getters = {
	loading(state) {
		return !!state.promise;
	},
	userSubscriptions(state) {
		return state.data.filter((sub) => sub.type !== "BBT");
	},
	tvAndFrihetSubscriptions(state, getters) {
		return getters.subscriptions?.filter((sub) => sub.type === "TV");
	},
	dataFetched: (state) => !!state.data.length,
	subscriptionOffers(state) {
		return state?.offers;
	},
	myOffers(state) {
		return state?.myOffers;
	},
	getSubscriptionById: (state, getters) => (subscriptionId) => {
		return getters.userSubscriptions.filter((sub) => sub.id === subscriptionId)?.[0];
	},
	error: (state) => {
		return state.error;
	},
	broadbandSubscriptions(state) {
		return state.data.filter((sub) => sub.category === "BB");
	},
	promiseSettled: (state) => state.promiseSettled,
};
