export default {
	namespaced: true,
	state() {
		return {
			editingEnabled: true,
			layoutResizing: false,
			currentEditingLayoutWidth: "",
			loggedIn: false,
			showAllSegmentedContent: false,
			labels: true,
			dragType: "",
		};
	},
	mutations: {
		setDragType(state, dragType) {
			state.dragType = dragType;
		},
		setEditingEnabled(state, editingEnabled) {
			state.editingEnabled = editingEnabled;
		},
		setLabels(state, labels) {
			state.labels = labels;
		},
		setSimulateLoggedIn(state, loggedIn) {
			state.loggedIn = loggedIn;
			console.log(loggedIn);
		},
		setShowAllSegmentedContent(state, showAllSegmentedContent) {
			state.showAllSegmentedContent = showAllSegmentedContent;
		},
		setLayoutResizing(state, resizing) {
			state.layoutResizing = resizing;
		},
		setCurrentEditingLayoutWidth(state, width) {
			state.currentEditingLayoutWidth = width;
		},
	},
	actions: {
		setSegments({ commit }, segments) {
			commit("setSegments", segments, { root: true });
		},
		spoofUserAgent({ commit }, userAgent) {
			commit("setUserAgent", userAgent, { root: true });
		},
	},
};
