import fixedSubscription from "~/integrations/fixed-subscription";
import { _fetchData } from "~/helpers/store";
const getDefaultState = () => {
	return {
		promise: null,
		data: [],
		error: null,
	};
};

export const state = () => getDefaultState();
export const actions = {
	async fetchData(context, { subscriptionId, force }) {
		return _fetchData(() => fixedSubscription.getSubscriptionProducts(subscriptionId), {
			context,
			force,
			name: "premiumTvContentSubscription",
		});
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	},
};
export const getters = {
	loading: (state) => {
		return state.promise !== null;
	},
	error: (state) => {
		return state.error;
	},
	channelPackages: (state, getters) => {
		return getters.premiumTvContentSubscription.filter((product) => product.type === "CHANNEL_PACKAGE") || [];
	},
	premiumChannels: (state, getters) => {
		return getters.premiumTvContentSubscription.filter((product) => product.type === "PREMIUM_CHANNEL") || [];
	},
	streamingServices: (state, getters) => {
		return getters.premiumTvContentSubscription.filter((product) => product.type === "STREAMING_SERVICE") || [];
	},
	premiumTvContentSubscription: (state, getters, rootState, rootGetters) => {
		const premiumTvContent = rootGetters["mittTelenor/fixed/premiumTvContent/premiumTvContent"];
		let premiumTvContentSubscription = [];
		if (state?.data?.offers && premiumTvContent.length) {
			Object.keys(state.data?.offers)?.forEach((key) => {
				if (key === "CHANNEL_PACKAGE" || key === "PREMIUM_CHANNEL" || key === "STREAMING_SERVICE") {
					state.data?.offers?.[key]?.forEach((product) => {
						const premiumTvContentProduct = premiumTvContent.filter((p) => p.productId === product.productId)?.[0];
						premiumTvContentSubscription.push(
							getters.mergeAndFormatProduct({
								fixedSubscriptionProduct: product,
								premiumTvContentProduct: premiumTvContentProduct,
								type: key,
								isSubscribed: false,
							}),
						);
					});
				}
			});
		}
		if (state.data?.subscribedProducts && premiumTvContent.length) {
			Object.keys(state.data?.subscribedProducts).forEach((key) => {
				if (key === "CHANNEL_PACKAGE" || key === "PREMIUM_CHANNEL" || key === "STREAMING_SERVICE") {
					state.data?.subscribedProducts?.[key]?.forEach((product) => {
						const premiumTvContentProduct = premiumTvContent.filter((p) => p.productId === product.productId)?.[0];
						premiumTvContentSubscription.push(
							getters.mergeAndFormatProduct({
								fixedSubscriptionProduct: product,
								premiumTvContentProduct: premiumTvContentProduct,
								type: key,
								isSubscribed: true,
							}),
						);
					});
				}
			});
		}
		return premiumTvContentSubscription;
	},
	getPremiumTvContentProductById: (state, getters) => (id) => {
		return getters.premiumTvContentSubscription?.filter((product) => product.productId === id)?.[0] || null;
	},
	customTag: (state, getters) => (id) => {
		//Dont ask...
		if (id === "CH0022") {
			return "Hjemme og på farten";
		}
	},
	isNetflix: (state) => (id) => {
		if (id === "CH0018") {
			return true;
		}
		return false;
	},
	isProductWithAdminSettings: (state, getters) => (id) => {
		//I promise it was much worse before
		if (getters.isNetflix(id)) {
			return true;
		}
		return false;
	},
	mergeAndFormatProduct:
		(state, getters) =>
		({ fixedSubscriptionProduct, premiumTvContentProduct, type, isSubscribed }) => {
			return {
				id: fixedSubscriptionProduct.id,
				productId: fixedSubscriptionProduct.productId,
				validFrom: fixedSubscriptionProduct.validFrom,
				validTo: fixedSubscriptionProduct.validTo,
				price: fixedSubscriptionProduct?.prices?.[0],
				name: premiumTvContentProduct?.name,
				description: premiumTvContentProduct?.description,
				logo: premiumTvContentProduct?.images?.logo,
				darkThemeLogo: premiumTvContentProduct?.images?.darkThemeLogo,
				bannerImageSmall: premiumTvContentProduct?.images?.bannerImageSmall,
				bannerImageLarge: premiumTvContentProduct?.images?.bannerImageLarge,
				content: premiumTvContentProduct?.content,
				type: type,
				isSubscribed: isSubscribed,
				cancelable: fixedSubscriptionProduct.cancelable,
				status: fixedSubscriptionProduct.status,
				customTag: getters.customTag(fixedSubscriptionProduct.productId),
				isProductWithAdminSettings: getters.isProductWithAdminSettings(fixedSubscriptionProduct.productId),
			};
		},
};
