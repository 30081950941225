import api from "./api";

export default {
	getByUrl: async (url, isPreview, token) => {
		let queryString = "";

		if (isPreview) queryString = "?preview=true&token=" + token;

		let result = await api(`/content/page/${encodeURIComponent(url)}${queryString}`, {
			method: "GET",
		});
		return result.data;
	},
	getByID: async (id) => {
		let result = await api("/content/component/" + encodeURIComponent(id), {
			method: "GET",
		});
		return result.data;
	},
	getResolveLink: async (id) => {
		let result = await api("/content/resolveLink/" + encodeURIComponent(id), {
			method: "GET",
		});
		return result.data;
	},
	getFlow: async (id) => {
		let result = await api("/content/flow/" + encodeURIComponent(id), {
			method: "GET",
		});
		return result.data;
	},
};
