const _dataExists = (context) => {
	switch (context.state?.data?.constructor) {
		case Array:
			return context.state?.data?.length;
		case Object:
			return Object.keys(context?.state?.data)?.length;
		default:
			return false;
	}
};

export const _fetchData = (service, { context, force = false, name }) => {
	if (_dataExists(context) && !force) {
		console.log(`Data already fetched for ${name}, using stored data.`);
		return Promise.resolve(context?.state?.data);
	}
	if (context?.state?.promise) {
		return context?.state?.promise;
	}
	if (Object.prototype.hasOwnProperty.call(context?.state, "isFetched") && force) {
		context.commit("setIsFetched", false);
	}
	if (Object.prototype.hasOwnProperty.call(context?.state, "promiseSettled")) {
		context.commit("setPromiseSettled", false);
	}
	context.commit("setError", null);
	const promise = service()
		.then((data) => {
			context.commit("setData", data);
			return data;
		})
		.catch((err) => {
			context.commit("setError", err);
			throw err;
		})
		.finally(() => {
			context.commit("setPromise", null);
			if (Object.prototype.hasOwnProperty.call(context?.state, "promiseSettled")) {
				context.commit("setPromiseSettled", true);
			}
		});
	context.commit("setPromise", promise);

	return promise;
};
