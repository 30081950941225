import { sizeScreenDesktop } from "@/assets/tokens/js/es6.js";
export default {
	computed: {
		dragging() {
			return this.$store.state.gizmo?.dragType;
		},
		dataPath() {
			if (!isNaN(parseInt(this.$store.state.testIndex)))
				return "/abTestPages/" + this.$store.state.testIndex + "/layouts/" + this.index;
			return "/layouts/" + this.index;
		},
	},
	async mounted() {
		if (this.$config.public.isPreview) {
			const interact = await import("interactjs");
			const store = this.$store;

			interact.default("[" + this.$options._scopeId + "] .section-layout").resizable({
				edges: { left: true, right: true },
				listeners: {
					move(event) {
						var target = event.target;

						target.style.width = event.rect.width + "px";
						let deltaPercentage = (target.clientWidth / parseInt(sizeScreenDesktop.split("px")[0])) * 100;
						let closestLayoutPercentage = Math.round(deltaPercentage / 10) * 10;
						if (closestLayoutPercentage < 40) {
							store.commit("gizmo/setCurrentEditingLayoutWidth", `${closestLayoutPercentage}`);
						} else {
							store.commit("gizmo/setCurrentEditingLayoutWidth", `${closestLayoutPercentage}`);
						}
					},
					start(event) {
						let deltaPercentage = (event.target.clientWidth / parseInt(sizeScreenDesktop.split("px")[0])) * 100;
						let closestLayoutPercentage = Math.round(deltaPercentage / 10) * 10;
						event.target.classList.add("no-select");
						event.target.parentNode.classList.add("resizing");
						store.commit("gizmo/setEditingEnabled", false);
						store.commit("gizmo/setLayoutResizing", true);
						store.commit("gizmo/setCurrentEditingLayoutWidth", `${closestLayoutPercentage}`);
					},
					end(event) {
						let target = event.target;

						target.classList.remove("no-select");
						event.target.parentNode.classList.remove("resizing");
						store.commit("gizmo/setEditingEnabled", true);
						store.commit("gizmo/setLayoutResizing", false);

						let deltaPercentage = (target.clientWidth / parseInt(sizeScreenDesktop.split("px")[0])) * 100;
						let closestLayoutPercentage = Math.round(deltaPercentage / 10) * 10;

						if (closestLayoutPercentage > 120) {
							parent.postMessage(
								{
									action: "change",
									payload: {
										dataPath: event.target.dataset.path + "/width",
										value: closestLayoutPercentage + "%",
									},
								},
								"*",
							);
							setTimeout(() => {
								parent.postMessage(
									{
										action: "change",
										payload: {
											dataPath: event.target.dataset.path + "/wrap",
											value: false,
										},
									},
									"*",
								);
							}, 500);
						} else if (closestLayoutPercentage < 110) {
							parent.postMessage(
								{
									action: "change",
									payload: {
										dataPath: event.target.dataset.path + "/width",
										value: closestLayoutPercentage + "%",
									},
								},
								"*",
							);
							setTimeout(() => {
								parent.postMessage(
									{
										action: "change",
										payload: {
											dataPath: event.target.dataset.path + "/wrap",
											value: true,
										},
									},
									"*",
								);
							}, 500);
						}
						event.target.style.width = "";
					},
				},
			});
		}
	},
};
