import apigeeIntegration from "./self-service-adapter";
import telenorid from "../telenorid/telenorid";
import axios from "axios";
import { FixedSaveV1ApiFactory } from "@telenornorgeinternal/fixed-save-api/dist/api/fixed-save-v1-api";

const fixedOrderApi = axios.create({
	baseURL: "/api/fixed-order/",
	withCredentials: true,
	timeout: 20000, // jeeeez
});
fixedOrderApi.interceptors.request.use(
	async (request) => {
		const user = await telenorid.getOrLoginUser();
		if (user) {
			request.headers.Authorization = "Bearer " + user.access_token;
		}
		return request;
	},
	(error) => {
		return Promise.reject(error);
	},
);

const api = apigeeIntegration("/fixed-order/v1");
const fixedSave = apigeeIntegration("/fixed-save");

//* Change orders
/* deprecated*/ const validateOrder = async (subscriptionId, order) =>
	api.post(`/subscriptions/${subscriptionId}/change/validator`, order);
/* deprecated*/ const dispatchOrder = async (subscriptionId, order) =>
	api.post(`/subscriptions/${subscriptionId}/change`, order);
const validateChange = async (subscriptionId, order) =>
	api.post(`/subscriptions/${subscriptionId}/change/validator`, order);
const dispatchOrderChange = async (subscriptionId, order) =>
	fixedOrderApi.post("order-change", { subscriptionId, ...order });

//* Change Technology Orders
const validateChangeTech = async (order) => api.post("/changetechnology/validator", order);
const freightCostChangeTech = async (order) => api.post("/changetechnology/freightcost", order);
const dispatchOrderChangeTech = async (order) => api.post("/changetechnology", order);

//* New subscriptions - LOC = Logged Out Customers
const validateNew = async (order) => api.post("/order/new/validator", order);
const dispatchOrderNew = async (order) => fixedOrderApi.post("/order-new", order);

//* Add Equipment
const validateAddEquipment = async (id, order) => api.post(`/subscriptions/${id}/products/add/validator`, order);
const dispatchAddEquipment = async (subscriptionId, order) =>
	fixedOrderApi.post(`/add-equipment`, { subscriptionId, ...order });

//* Conflict with content provider
const orderCompensation = async (id, order) => api.post(`/subscriptions/${id}/products/add`, order);

//* Save Offer
const dispatchSaveOffer = async (order) => {
	return fixedOrderApi.post("order-save", order);
};

//* dispatchers
const dispatch = ({ dispatcher, subscriptionId, order, summary, validatedOrder, address, bundleOfferId }) => {
	switch (dispatcher) {
		case "newsale":
			return dispatchOrderNew({ order, summary, validatedOrder, address, bundleOfferId });
		case "change-bandwidth":
		case "add-tv":
			return dispatchOrderChange(subscriptionId, { order, validatedOrder, summary, address, bundleOfferId });
		case "add":
		case "add-equipment":
			return dispatchAddEquipment(subscriptionId, { order, validatedOrder, summary, address, bundleOfferId });
		case "termination-save":
			return dispatchSaveOffer({ order, summary, validatedOrder, address, bundleOfferId });
	}
};

//* validators
const validate = ({ validator, order, subscriptionId }) => {
	switch (validator) {
		case "newsale":
			return validateNew(order);
		case "add-equipment":
			return validateAddEquipment(subscriptionId, order.order);
		case "change-bandwidth":
		case "add-tv":
			return validateChange(subscriptionId, order);
		case "termination-save":
			return validateSaveOffer(order);
	}
};

//* Validate Save Offer
const validateSaveOffer = async (changeOrder) => {
	const fixedSaveApi = new FixedSaveV1ApiFactory(null, null, fixedSave);
	return fixedSaveApi.orderValidate({ changeOrder });
};

//* Add Product
const validateAddProduct = async (id, order) => api.post(`/subscriptions/${id}/products/add/validator`, order);
const dispatchAddProduct = async (id, order) => api.post(`/subscriptions/${id}/products/add`, order);

//* Delete Product
const validateDeleteProduct = async (subscriptionId, productId) =>
	api.delete(`/subscriptions/${subscriptionId}/products/${productId}/validate`);
const dispatchDeleteProduct = async (subscriptionId, productId) =>
	api.delete(`/subscriptions/${subscriptionId}/products/${productId}`);

export default {
	validateOrder,
	dispatchOrder,
	validateChangeTech,
	freightCostChangeTech,
	dispatchOrderChangeTech,
	validateNew,
	dispatchOrderNew,
	validateChange,
	dispatchOrderChange,
	validate,
	dispatch,
	validateAddEquipment,
	orderCompensation,
	validateAddProduct,
	dispatchAddProduct,
	validateDeleteProduct,
	dispatchDeleteProduct,
};
