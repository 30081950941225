import apigeeIntegration from "../../../../integrations/self-service-adapter";

const api = apigeeIntegration("/email-subscriptions/v1");
api.defaults.headers.common["x-telenor-dealer-id"] = 6662;
api.defaults.headers.common["X-Telenor-Channel-Name"] = "MINE_SIDER";

// get Email accounts
const getAccounts = () => api.get("/accounts");
const refreshAccounts = () => api.get(`/accounts?time=${new Date().getTime()}`);

// get specific email account
const getAccount = (accountName) => api.get(`/accounts/${accountName}`);
const refreshAccount = (accountName) => api.get(`/accounts/${accountName}?time=${new Date().getTime()}`);

// change password on an email account
const requestPasswordReset = (accountName, password) =>
	api.post(`/accounts/${accountName}/password`, {
		password: password,
	});

// get subscriptions
const getSubscriptions = () => api.get("/subscriptions");

// check whether the email account has subscription already
const hasSubscriptionEmailAccount = (accountName) =>
	api.get(`/subscriptions/${accountName}?time=${new Date().getTime()}`);

const createNewEmail = (body) => api.post("/accounts", body);

// change contact number on an email account
const changePhoneNumber = (accountName, body) => api.post(`/accounts/${accountName}/phonenumber`, body);

// delete an email account
const deleteAccount = (accountName) => api.delete(`/accounts/${accountName}`);

// administer addon products
const getAccountAddonProducts = (accountName) => api.get(`/accounts/${accountName}/addonProducts`);
const addAddonProduct = (accountName, productId) => api.post(`/accounts/${accountName}/addonProducts/${productId}`);
const deleteAddonProduct = (accountName, productId) =>
	api.delete(`/accounts/${accountName}/addonProducts/${productId}`);

export default {
	getAccounts,
	getAccount,
	refreshAccount,
	refreshAccounts,
	createNewEmail,
	getSubscriptions,
	hasSubscriptionEmailAccount,
	getAccountAddonProducts,
	addAddonProduct,
	deleteAddonProduct,
	requestPasswordReset,
	changePhoneNumber,
	deleteAccount,
};
