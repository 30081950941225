export default {
	586: "Man-fre 10:00-20:00 (10:00-18:00)",
	4981: "Man-fre 10:00-20:00 (10:00-18:00)",
	3843: "Man-fre 09:00-21:00 (09:00-20:00)",
	4984: "Man-fre 10:00-18:00 (10:00-18:00)",
	4922: "Man-fre 10:00-21:00 (10:00-19:00)",
	3414: "Man-fre 10:00-20:00 (10:00-18:00)",
	1342: "Man-fre 10:00-20:00 (10:00-18:00)",
	3883: "Man-fre 10:00-20:00 (10:00-18:00)",
	3918: "Man-fre 09:00-19:00 (10:00-16:00)",
	2617: "Man-fre 10:00-20:00 (10:00-18:00)",
	102: "Man-fre 10:00-20:00 (10:00-18:00)",
	3837: "Man-fre 09:00-20:00 (10:00-18:00)",
	4065: "Man-fre 10:00-21:00 (09:00-18:00)",
	292: "Man-fre 09:00-21:00 (09:00-18:00)",
	5924: "Man-ons 09:00-17:00 Tors-fre 09:00-18:00 (09:00-15:00)",
	4829: "Man-fre 09:00-16:00 (10:00-15:00)",
	4839: "Man-fre 10:00-17:00 (10:00-17:00)",
	3595: "Man-fre 09:00-20:00 (09:00-18:00)",
	6158: "Man-fre 10:00-21:00 (09:00-18:00)",
	1705: "Man-fre 10:00-20:00 (10:00-18:00)",
	3841: "Man-fre 10:00-19:00 (10:00-16:00)",
	126: "Man-fre 10:00-20:00 (10:00-18:00)",
	4422: "Man-fre 09:00-20:00 (10:00-18:00)",
	429: "Man-fre 10:00-21:00 (10:00-20:00)",
	4772: "Man-fre 10:00-21:00 (09:00-18:00)",
	273: "Man-fre 10:00-21:00 (09:00-19:00)",
	2062: "Man-fre 09:00-21:00 (09:00-20:00)",
	6791: "Man-fre 10:00-20:00 (10:00-18:00)",
	4758: "Man-fre 10:00-20:00 (10:00-18:00)",
	3707: "Man-fre 10:00-20:00 (10:00-18:00)",
	2099: "Man-fre 10:00-19:00 (10:00-18:00)",
	3023: "Man-fre 10:00-18:00 (10:00-16:00)",
	4762: "Man-fre 10:00-20:00 (09:00-20:00)",
	1352: "Man-fre 10:00-20:00 (10:00-18:00)",
	3020: "Man-fre 10:00-20:00 (10:00-17:00)",
	2438: "Man-fre 09:00-21:00 (09:00-18:00)",
	6695: "Man-fre 10:00-21:00 (10:00-18:00)",
	1360: "Man-fre 10:00-19:00 (10:00-18:00)",
	1693: "Man-fre 10:00-21:00 (10:00-19:00)",
	2829: "Man-fre 09:00-20:00 (10:00-18:00)",
	1664: "Man-fre 09:00-20:00 (10:00-18:00)",
	2456: "Man-fre 10:00-20:00 (10:00-16:00)",
	2833: "Man-fre 09:00-20:00 (09:00-18:00)",
	4774: "Man-fre 10:00-21:00 (10:00-18:00)",
	2055: "Man-fre 10:00-20:00 (10:00-18:00)",
	2153: "Man-fre 10:00-21:00 (10:00-18:00)",
	5819: "Man-fre 10:00-21:00 (10:00-19:00)",
	4046: "Man-fre 10:00-21:00 (10:00-18:00)",
	4819: "Man-fre 10:00-20:00 (10:00-18:00)",
	1538: "Man-fre 09:00-20:00 (09:00-18:00)",
	113: "Man-fre 10:00-20:00 (10:00-18:00)",
	3771: "Man-fre 10:00-21:00 (10:00-18:00)",
	3599: "Man-fre 10:00-20:00 (10:00-18:00)",
	4895: "Man-fre 10:00-21:00 (10:00-19:00)",
	2969: "Man-fre 10:00-21:00 (09:00-19:00)",
	1333: "Man-fre 09:00-20:00 (09:00-18:00)",
	2585: "Man-fre 10:00-20:00 (10:00-18:00)",
	1167: "Man-fre 10:00-21:00 (10:00-18:00)",
	6691: "Man-fre 09:00-20:00 (09:00-18:00)",
	248: "Man-fre 10:00-21:00 (10:00-18:00)",
	427: "Man-fre 10:00-21:00 (10:00-18:00)",
	4809: "Man-fre 09:00-20:00 (09:00-18:00)",
	438: "Man-fre 10:00-21:00 (10:00-19:00)",
	2303: "Man-fre 10:00-20:00 (10:00-18:00)",
	4827: "Man-fre 10:00-20:00 (10:00-18:00)",
	4842: "Man-fre 10:00-20:00 (10:00-19:00)",
	6743: "Man-fre 10:00-20:00 (10:00-18:00)",
	6653: "Man-fre 09:00-19:00 (09:00-17:00)",
	1681: "Man-fre 10:00-20:00 (10:00-18:00)",
	4773: "Man-fre 10:00-20:00 (10:00-18:00)",
	1461: "Man-fre 10:00-21:00 (10:00-18:00)",
	4841: "Man-fre 09:00-20:00 (09:00-18:00)",
	877: "Man-fre 10:00-22:00 (10:00-20:00)",
	841: "Man-fre 09:00-20:00 (09:00-18:00)",
	469: "Man-fre 10:00-20:00 (10:00-18:00)",
	171: "Man-fre 10:00-20:00 (10:00-18:00)",
	4066: "Man-fre 10:00-21:00 (10:00-19:00)",
	3665: "Man-fre 10:00-21:00 (09:00-19:00)",
	6986: "Man-fre 10:00-21:00 (10:00-18:00)",
	980: "Man-fre 09:00-16:30 (10:00-15:00)",
	2879: "Man-fre 10:00-20:00 (10:00-18:00)",
	1563: "Man-fre 10:00-19:00 (10:00-16:00)",
	1495: "Man-fre 10:00-20:00 (10:00-18:00)",
	2792: "Man-fre 09:00-20:00 (09:00-18:00)",
	514: "Man-fre 09:00-20:00 (09:00-18:00)",
	3666: "Man-fre 10:00-21:00 (10:00-19:00)",
	3929: "Man-fre 10:00-21:00 (09:00-18:00)",
	4682: "Man-fre 10:00-21:00 (10:00-19:00)",
	3797: "Man-fre 10:00-21:00 (10:00-19:00)",
	296: "Man-fre 10:00-21:00 (09:00-18:00)",
	6038: "Man-fre 09:00-20:00 (09:00-18:00)",
	3930: "Man-fre 10:00-21:00 (09:00-19:00)",
	434: "Man-fre 10:00-20:00 (10:00-18:00)",
	3828: "Man-fre 10:00-19:00 (10:00-16:00)",
	944: "Man-fre 10:00-21:00 (10:00-18:00)",
	3360: "Man-fre 10:00-21:00 (09:00-18:00)",
	4832: "Man-fre 09:00-20:00 (09:00-18:00)",
	4883: "Man-fre 09:00-21:00 (10:00-20:00)",
	4886: "Man-fre 09:00-20:00 (10:00-18:00)",
	2160: "Man-fre 10:00-20:00 (10:00-18:00)",
	4952: "Man-fre 09:00-21:00 (09:00-20:00)",
	4047: "Man-fre 10:00-20:00 (10:00-18:00)",
	4990: "Man-fre 10:00-20:00 (10:00-18:00)",
};
