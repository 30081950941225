export default {
	issuer: "https://id-test.telenor.no",
	jwks_uri: "https://id-test.telenor.no/.well-known/openid-configuration/jwks",
	authorization_endpoint: "https://id-test.telenor.no/connect/authorize",
	token_endpoint: "https://id-test.telenor.no/connect/token",
	userinfo_endpoint: "https://id-test.telenor.no/connect/userinfo",
	end_session_endpoint: "https://id-test.telenor.no/connect/endsession",
	check_session_iframe: "https://id-test.telenor.no/connect/checksession",
	revocation_endpoint: "https://id-test.telenor.no/connect/revocation",
	introspection_endpoint: "https://id-test.telenor.no/connect/introspect",
	device_authorization_endpoint: "https://id-test.telenor.no/connect/deviceauthorization",
	frontchannel_logout_supported: true,
	frontchannel_logout_session_supported: true,
	backchannel_logout_supported: true,
	backchannel_logout_session_supported: true,
	scopes_supported: [
		"openid",
		"email",
		"phone",
		"tnn.legacy",
		"profile",
		"ial1",
		"ial2",
		"ial3",
		"ial0",
		"debug",
		"account.profiles",
		"saml",
		"tnn.ids",
		"tnn.profile.attributes",
		"extsub",
		"offline_access",
	],
	claims_supported: [
		"sub",
		"email_verified",
		"email",
		"phone_number_verified",
		"phone_number",
		"legacy_status",
		"legacy_roles",
		"legacy_usertype",
		"legacy_username",
		"kurtid",
		"birthdate",
		"middle_name",
		"given_name",
		"family_name",
		"name",
		"analytics_uuid",
		"ial",
		"ibis.internal.external_issuer",
		"ibis.internal.external_sid",
		"ibis.internal.external_sub",
		"ibis.internal.external_idtoken",
		"account_profiles",
		"tnuid",
		"profiles",
		"attributes",
		"extsub",
	],
	grant_types_supported: [
		"authorization_code",
		"client_credentials",
		"refresh_token",
		"implicit",
		"urn:ietf:params:oauth:grant-type:device_code",
	],
	response_types_supported: [
		"code",
		"token",
		"id_token",
		"id_token token",
		"code id_token",
		"code token",
		"code id_token token",
	],
	response_modes_supported: ["form_post", "query", "fragment"],
	token_endpoint_auth_methods_supported: ["client_secret_basic", "client_secret_post"],
	id_token_signing_alg_values_supported: ["RS256"],
	subject_types_supported: ["public"],
	code_challenge_methods_supported: ["plain", "S256"],
	request_parameter_supported: true,
	self_service: "https://id-test.telenor.no/api/meta/v1/self-service",
};
