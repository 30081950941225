export function toString(queryObject) {
	return Object.keys(queryObject)
		.map((key) => {
			return key + "=" + encodeURIComponent(queryObject[key]);
		})
		.join("&");
}

export function toObject() {
	let search = location.search.substring(1);
	if (search)
		return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
	return {};
}

// Compatibility with NodeJS 'querystring' module

export const parse = toObject;
export const stringify = toString;

export default {
	toObject,
	toString,
	parse,
	stringify,
};
