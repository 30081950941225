import capitalize from "~/helpers/formatting/capitalize";

// isTV :: JSON -> Boolean
export const isTV = (offer) => Object.hasOwn(offer?.mainProducts || {}, "TV");

// isBBOnly :: JSON -> Boolean
export const isBB = (offer) => Object.hasOwn(offer?.mainProducts || {}, "BB");

// isDualPlay :: JSON -> Boolean
export const isDualPlay = (offer) => isTV(offer) && isBB(offer);

// offersToCards :: JSON -> Array JSON
export const offersToCards = ({ offers, content, dynamicContent }) =>
	offers
		?.map((offer) => {
			const matchingContent = matchOfferToContent({ offer: offer, content: content.offers });
			const enrichContent = dynamicContent({
				"from-price": getCheapestDiscount(offer),
				"campaign-price": getCheapestDiscount(offer),
				"price-normal": getCheapestPrice(offer),
				"monthly-fee": getFee({ type: "MONTHLY", offer }),
				"campaign-duration": getFee({ type: "MONTHLY", offer }),
			});
			return {
				badge: {
					text: isBrownField(offer) ? "Forhåndsbestill" : hasDiscounts(offer) ? "Kampanje" : "",
					color: "blue",
				},
				sortIndex: isDualPlay(offer) ? "b" : offer?.mainProducts?.TV ? "c" : "a",
				isDualPlay: isDualPlay(offer),
				offerId: offer?.offerId,
				bundleId: offer?.meta?.bundleOfferId,
				title: matchingContent ? matchingContent?.heading : `${offer?.offerId}::${offer?.serviceTechnology}`,
				image: matchingContent ? matchingContent?.image : "bilde",
				description: enrichContent(matchingContent?.ingress),
				hasDiscounts: hasDiscounts(offer),
				features: getFeatures({ features: content.configuration.features, offer, dynamicContent }) || [],
				footer: {
					title: enrichContent(matchingContent?.footer?.header),
					description: hasDiscounts(offer) ? enrichContent(matchingContent?.footer?.ingress) : "",
				},
			};
		})
		?.sort((cardA, cardB) => (cardA?.sortIndex < cardB?.sortIndex ? -1 : cardA > cardB ? 1 : 0));

export const emptyCard = () => ({
	badge: { text: "", color: "blue" },
	sortIndex: "",
	isDualPlay: "",
	offerId: "",
	bundleId: "",
	title: "",
	image: "",
	description: "",
	hasDiscounts: "",
	features: Array.from({ length: 4 }).map((feature) => ({
		...feature,
		icon: "success",
	})),
	footer: "",
	footnote: "",
});
// identity :: a -> a
const identity = (x) => x;

export const streetAddress = (address) =>
	[capitalize(address?.streetName || address?.addressLine1), address?.houseNumber, address?.houseLetter]
		?.filter((x) => x)
		?.join(" ");

// getFeatures :: JSON -> Array JSON
const getFeatures = ({ features, offer, dynamicContent }) => {
	return (
		features
			.filter((feature) => feature?.offersToIncludeFeature?.split(",")?.includes(offer?.meta?.bundleOfferId))
			.map((feature) => {
				const enrichContent = dynamicContent({
					"max-broadband-speed": `${getMaxBroadbandSpeed(offer)?.speed} ${getMaxBroadbandSpeed(offer)?.unit}`,
					"dualplay-discount": offer?.discounts?.MONTHLY?.price?.amount,
				});
				return {
					icon: hasFeature(feature?.type, offer) ? "success" : "close",
					additionalClasses: hasFeature(feature?.type, offer) ? "" : "not-included",
					heading: enrichContent(feature?.bullet),
					richtext: enrichContent(feature?.textAfter),
					showTextAfter: feature?.showTextAfter,
					hasFeature: hasFeature(feature?.type, offer),
				};
			})
			.sort((a, b) => b.hasFeature - a.hasFeature) || []
	);
};

// getMaxBroadbandSpeed :: JSON -> JSON
const getMaxBroadbandSpeed = (offer) =>
	offer?.mainProducts?.BB?.reduce(
		(acc, curr) =>
			(acc =
				acc.speed < curr?.bandwidth?.download?.value
					? { speed: curr?.bandwidth?.download?.value, unit: capitalize(curr?.bandwidth?.download?.unit) }
					: acc),
		{ speed: -Infinity, unit: "" },
	);

// hasFeature :: (String, Offer) -> True|False
const hasFeature = (conditional, offer) => {
	let binding = offer.mainProducts?.BB?.some((speed) =>
		speed?.additionalProducts?.some((product) => product?.offerTags?.includes("BINDING")),
	);
	switch (conditional) {
		case "hasBinding":
			return binding;
		case "noBinding":
			return !binding;
		case "noEstablishmentFees": //! OBS segmanic error - read as NO EstablishmentFee -> true
			return !hasMainProductsFee(offer) && !hasPublicGrantFee(offer);
		case "hasEstablishmentFee":
			return hasMainProductsFee(offer) || hasPublicGrantFee(offer);
		case "hasTV":
			return isDualPlay(offer) || isTV(offer);
		case "isDualPlay":
			return isDualPlay(offer);
		default:
			return true;
	}
};

// hasPublicGrantFee :: Offer -> True|False
const hasPublicGrantFee = (offer) => offer?.projectInformation?.publicGrantInformation?.establishmentAmount > 0;

// hasMainProductsFee :: Offer -> True|False
const hasMainProductsFee = (offer) =>
	allMainProducts(offer)
		?.flatMap((product) => product?.additionalProducts?.filter((addon) => addon?.productType === "FEE"))
		?.map((addon) => getOneTimeDiscountedPrice(addon) > 0)
		?.some(identity);

// hasMonthlyDiscount :: JSON -> Boolean
const hasMonthlyDiscount = (object) => !!object?.discounts?.MONTHLY;

// removeOffersWithoutContent :: JSON -> JSON
export const removeOffersWithoutContent = (offers, content) => {
	let offerContent = content?.mainProduct?.offerId;
	return offers.filter((offer) => {
		return offerContent
			?.split(",")
			?.flatMap((gizmoOfferId) => gizmoOfferId?.includes(offer?.meta?.bundleOfferId))
			?.some((x) => x);
	});
};

// hasDiscounts :: JSON -> Boolean
export const hasDiscounts = (offer) =>
	[hasMonthlyDiscount(offer), ...(allMainProducts(offer)?.map(hasMonthlyDiscount) || [])]?.some(identity);

// allMainBBOrTVProducts :: JSON -> Array JSON
// returns all main products
const allMainBBOrTVProducts = (offer) => {
	const products = getBBProducts(offer);
	if (!isDualPlay(offer)) {
		products.push(getTVProduct(offer));
	}
	return products;
};
// allMainProducts :: JSON -> Array Array JSON
const allMainProducts = (offer) =>
	isDualPlay(offer) ? [...allMainBBOrTVProducts(offer), getTVProduct(offer)] : allMainBBOrTVProducts(offer);

const mainProductGroups = (offer) =>
	isDualPlay(offer)
		? getBBProducts(offer)?.map((bb) => [bb, getTVProduct(offer)])
		: allMainBBOrTVProducts(offer)?.map((main) => [main]);

export const getEstablishmentFee = (offer) =>
	allMainProducts(offer)
		?.reduce((acc, curr) => (acc?.some((product) => product?.category === curr?.category) ? acc : [...acc, curr]), [])
		?.flatMap((product) => product?.additionalProducts?.filter((addon) => addon?.productType === "FEE")) //
		?.reduce((acc, curr) => (acc += getOneTimeDiscountedPrice(curr)), 0);

// getPublicGrantFee :: Offer -> Number
export const getPublicGrantFee = (offer) => offer?.projectInformation?.publicGrantInformation?.establishmentAmount;

export const getFee = ({ type, offer }) =>
	allMainProducts(offer)
		?.reduce((acc, curr) => (acc?.some((product) => product?.category === curr?.category) ? acc : [...acc, curr]), [])
		?.flatMap((product) => product?.additionalProducts?.filter((addon) => addon?.productType === "FEE")) //
		?.reduce((acc, curr) => (acc += type === "MONTHLY" ? getMonthlyPrice(curr) : getOneTimePrice(curr)), 0);

// getMonthlyPrice :: JSON -> Number
export const getMonthlyPrice = (product) => product?.prices?.MONTHLY?.amount;

// getMonthlyDiscountedPrice :: JSON -> Boolean
export const getMonthlyDiscountedPrice = (product) => getMonthlyPrice(product) - getMonthlyDiscount(product);

// getMonthlyDiscount :: JSON -> Boolean
export const getMonthlyDiscount = (product) => Math.abs(product?.discounts?.MONTHLY?.price?.amount) || 0;

// getOneTimePrice :: JSON -> Number
export const getOneTimePrice = (product) => product?.prices?.ONE_TIME?.amount || 0;

// getMonthlyDiscount :: JSON -> Boolean
export const getOneTimeDiscount = (product) => Math.abs(product?.discounts?.ONE_TIME?.price?.amount) || 0;

// getOneTimeDiscountedPrice :: JSON -> Boolean
export const getOneTimeDiscountedPrice = (product) => getOneTimePrice(product) - getOneTimeDiscount(product);

// getTVProduct :: JSON -> JSON
export const getTVProduct = (offer) => offer?.mainProducts?.TV?.[0] || [];

// getTVProduct :: JSON -> JSON
export const getBBProducts = (offer) => offer?.mainProducts?.BB || [];

export const getLargestBroadbandDiscount = (offer) =>
	getBBProducts(offer)?.reduce(
		(largestDiscount, discount) =>
			getMonthlyDiscount(discount) > largestDiscount ? getMonthlyDiscount(discount) : largestDiscount,
		0,
	);

export const getLargestMainProductDiscount = (offer) =>
	mainProductGroups(offer)
		?.map((group) => group?.reduce((sum, product) => (sum += getMonthlyDiscount(product)), 0))
		?.reduce((largestDiscount, discount) => (discount > largestDiscount ? discount : largestDiscount), 0);

export const getTvDiscount = (offer) =>
	[getTVProduct(offer)]?.reduce(
		(largestDiscount, discount) =>
			getMonthlyDiscount(discount) > largestDiscount ? getMonthlyDiscount(discount) : largestDiscount,
		0,
	);

// getCheapestPrice :: JSON -> Number
// Since the mainProducts are separated into individual products we have to combine
// prices for dualplay.
export const getCheapestPrice = (offer) => {
	const price = allMainBBOrTVProducts(offer)?.reduce(
		(acc, curr) =>
			isDualPlay(offer)
				? getMonthlyPrice(curr) + getMonthlyPrice(getTVProduct(offer)) < acc
					? (acc = getMonthlyPrice(curr) + getMonthlyPrice(getTVProduct(offer)))
					: acc
				: getMonthlyPrice(curr) < acc
					? (acc = getMonthlyPrice(curr))
					: acc,
		Infinity,
	);
	return offer?.discounts?.MONTHLY?.effectiveNumberOfMonths ? price : price - getMonthlyDiscount(offer);
};

// getCheapestDiscount :: JSON -> Number
export const getCheapestDiscount = (offer) =>
	allMainBBOrTVProducts(offer)?.reduce(
		(acc, curr) =>
			isDualPlay(offer)
				? getMonthlyDiscountedPrice(curr) + getMonthlyDiscountedPrice(getTVProduct(offer)) < acc
					? (acc = getMonthlyDiscountedPrice(curr) + getMonthlyDiscountedPrice(getTVProduct(offer)))
					: acc
				: getMonthlyDiscountedPrice(curr) < acc
					? (acc = getMonthlyDiscountedPrice(curr))
					: acc,
		Infinity,
	) - getMonthlyDiscount(offer);

export const getCampaignDuration = (offer) =>
	allMainBBOrTVProducts(offer)
		?.map((product) => product?.discounts?.MONTHLY?.effectiveNumberOfMonths || undefined)
		?.filter(identity)?.[0];

// matchContentToOffer :: JSON -> JSON
export const matchOfferToContent = ({ offer, content }) =>
	content?.find((contentOffer) => contentOffer?.offerId?.split(",")?.includes(offer?.meta?.bundleOfferId));

// hasTV :: JSON -> Boolean
export const hasTV = (offer) => offer?.mainProducts?.TV?.length > 0;

// getOfferById :: Array JSON -> String -> JSON
export const getOfferById = (offers) => (id) => {
	return offers?.find((offer) => offer?.offerId === id);
};

// getOfferByBundleId :: Array JSON -> String -> JSON
export const getOfferByBundleId = (offers) => (id) => offers?.find((offer) => offer?.meta?.bundleOfferId === id);

const products = {
	FIBER: "Fiber",
	GPON: "Fiber",
	HFC: "Internett",
	FWA: "Trådløst Bredbånd",
};
export const translateProductName = (availableTech, dualplayAvailable) => {
	let productName = products[availableTech];
	if (dualplayAvailable) {
		productName += " og TV";
	}
	return productName;
};

export const translateSelectedMainProduct = (selectedOffer) => {
	if (!selectedOffer) return "";
	let productName;
	if (Object.values(selectedOffer?.mainProducts)?.length > 1) {
		productName = products[selectedOffer.serviceTechnology] + " og TV";
	} else if (selectedOffer?.mainProducts?.BB) {
		productName = products[selectedOffer.serviceTechnology];
	} else {
		productName = "TV";
	}
	return productName;
};

// * Technology types
// isFiber :: JSON -> Boolean
// export const isFiber = ({ serviceTechnology }) => serviceTechnology === 'GPON'; // AE

// isTBB :: JSON -> Boolean
// export const isTBB = ({ serviceTechnology }) => serviceTechnology === 'FWA';

//* Offer content types

// isBrownField :: JSON -> Boolean
export const isBrownField = (offer) => offer?.meta?.bundleOfferId?.indexOf("PLANNED") > -1;

// isGreenField :: JSON -> Boolean
export const isGreenField = (offer) =>
	isBrownField(offer) && offer?.meta?.addressProperties?.developmentProject === "GF";

export const hasRequirements = (offer) => offer?.constraints?.requires?.length > 0;

export const requirements = (offer) => (hasRequirements(offer) ? "Du trenger" : "");
