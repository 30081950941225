import { getBenefits, getInsuranceExtraInfoText } from "~/helpers/hardware-and-insurance/insuranceActionsHelper";

export const mapInsuranceProducts = ({ products, benefits }) => {
	const mappedProducts = products.map((insuranceProduct) => {
		return mapInsuranceProduct({ insuranceProduct, benefits });
	});

	return mappedProducts.sort((a, b) => {
		return a.sortOrder - b.sortOrder;
	});
};

export const mapInsuranceProduct = ({ insuranceProduct, benefits }) => {
	const insuranceName = insuranceProduct?.publicName;
	const insuranceType = () => {
		if (insuranceName?.includes("Plussforsikring")) {
			return "plussforsikring";
		} else if (insuranceName?.includes("Skjermforsikring")) {
			return "skjermforsikring";
		} else {
			return "";
		}
	};

	const isPlusInsurance = insuranceType() === "plussforsikring";

	const isSwapInsurance = insuranceName?.includes("SWAP");

	const deviceType = () => {
		if (insuranceName.includes("Smartklokke")) {
			return "SMART_WATCH";
		} else if (insuranceName.includes("Nettbrett")) {
			return "TABLET";
		} else {
			return "PHONE";
		}
	};

	const gizmoInfo = getBenefits({
		benefits: benefits,
		isPlusInsurance: isPlusInsurance,
		deviceType: deviceType(),
		isSwapInsurance: isSwapInsurance,
	});

	const selectedInfoText = getInsuranceExtraInfoText({
		selectedInfoText: gizmoInfo.selectedInsuranceInfoText,
		poid: insuranceProduct.productId,
	});

	return {
		...insuranceProduct,
		badge: gizmoInfo?.badge,
		benefit: gizmoInfo?.benefits,
		preSelected: gizmoInfo?.preSelected,
		selectedInfoText,
	};
};
