import fixedSubscription from "~/integrations/fixed-subscription";
import { _fetchData } from "~/helpers/store";

const getDefaultState = () => {
	return {
		promise: null,
		data: null,
		error: null,
		isFetched: false,
		promiseSettled: false,
	};
};

export const state = getDefaultState;
export const actions = {
	async fetchData(context, { subscriptionId, force = false }) {
		return _fetchData(() => fixedSubscription.getSubscription(subscriptionId), {
			context,
			force,
			name: "fixed-subscription",
		});
	},
	async getSubscriptionProducts(context, { subscriptionId }) {
		return fixedSubscription.getSubscriptionProducts(subscriptionId);
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	resetState(state, includeServerData) {
		if (includeServerData) {
			Object.assign(state, getDefaultState());
		} else {
			state.promise = null;
			state.data = null;
			state.error = null;
		}
	},
	setIsFetched(state, isFetched) {
		state.isFetched = isFetched;
	},
	setPromiseSettled(state, promiseSettled) {
		state.promiseSettled = promiseSettled;
	},
};
export const getters = {
	loading(state) {
		return state.promise !== null;
	},
	dataFetched: (state) => !!state.data,
	error(state) {
		return state.error;
	},
	fixedSubscription(state) {
		return state?.data;
	},
	isFrihet(state) {
		return state?.data?.agreementInfo?.type === "frihet";
	},
	isTwe(state) {
		return state?.data?.type === "TV";
	},
	isFrihetWithTweEnabled(state, getters, rootState, rootGetters) {
		return rootGetters["mittTelenor/fixed/frihetSubscription/isTvEnabled"] || false;
	},
	equipment(state) {
		return (
			state?.data?.products?.filter(
				(product) =>
					state?.data?.type !== "FMBB"
						? product?.productType === "EQUIPMENT"
						: product?.names.text.includes("Antenne") || product?.productType.includes("ALLTID_WIFI_UTSTYR"),
				// (product?.productType === "ADD_ON" && !product.names.text.includes('T-We'))
			) || []
		);
	},
	subscribedMainProduct(state) {
		return state?.data?.mainProduct;
	},
	services(state) {
		return state?.data?.type === "FMBB"
			? state?.data?.products.filter(
					(product) => product?.productType === "ANTIVIRUS" || product?.productType === "SIKKERHET",
				)
			: state?.data?.products?.filter((product) => product?.productType === "ADD_ON");
	},
	subscriptionProducts(state) {
		return state?.data?.products;
	},
	isFMBB(state) {
		return state?.data?.type === "FMBB";
	},
	isLumino(state) {
		return state?.data?.valueChain === "TNN";
	},
	promiseSettled: (state) => state.promiseSettled,
	isBlockedByMissingPayment(state) {
		return state?.data?.barredStatus === "BLOCKED_BY_MISSING_PAYMENT";
	},
};
