const getSearchField = (apartmentDto) => (key) => {
	return apartmentDto[key] ? { [key]: apartmentDto[key] } : null;
};

const getSearches = (searchFields) =>
	searchFields.map((field) =>
		Object.keys(field)[0] === "streetName"
			? {
					match_phrase_prefix: {
						[Object.keys(field)[0]]: {
							query: Object.values(field)[0],
							max_expansions: "5000",
						},
					},
				}
			: { match: { [Object.keys(field)[0]]: Object.values(field)[0] } },
	);

export const apartmentQuery = (addressDto) => {
	const searchFields = ["streetName", "houseNumber", "houseLetter", "postCode"]
		.map((field) => getSearchField(addressDto)(field))
		.filter((result) => result);

	return {
		size: 500,
		query: {
			bool: {
				must: [{ exists: { field: "apartmentNumber" } }, ...getSearches(searchFields)],
				must_not: !addressDto.houseLetter
					? [{ match: { addressType: "M" } }, { match: { addressType: "PB" } }, { exists: { field: "houseLetter" } }]
					: [{ match: { addressType: "M" } }, { match: { addressType: "PB" } }],
			},
		},
	};
};
