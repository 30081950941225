import segmentComponent from "~/helpers/segmentation/filterComponent";
import hasSegmentation from "~/helpers/segmentation/hasSegmentation";

export default {
	computed: {
		segments() {
			return this.$store.state.customer?.segments || [];
		},
	},
	methods: {
		filterComponent(object) {
			if (!object) return false;
			if (this.$store.getters["segmentationDisabled"] || !hasSegmentation(object)) return true;

			return segmentComponent(object, this.$store.state);
		},
	},
};
