export default {
	computed: {
		backgroundColor() {
			switch (this.color) {
				case "white":
					return "color-theme--white";
				case "dark":
					return "color-theme--dark";
				case "twe-web-app":
					return "color-theme--twe-web-app";
				case "off-white":
					return "color-theme--off-white";
				case "mid-blue":
					return "color-theme--mid-blue";
				case "light-blue":
					return "color-theme--light-blue";
				// legacy
				case "purple":
				case "turquoise":
				case "light purple":
				case "green":
				case "yellow":
				case "orange":
				case "red":
				case "pink":
				case "blue":
					return "color-theme--off-white";
				// bg mappings -->
				case "black":
					return "bg-black";
				case "gray":
					return "bg-gray";
				case "dark-to-white":
					return "bg-dark-to-white";
				case "transparent":
					return "transparent";
				case "blueGray":
					return "bg-twe-light-gray-blue";
				default:
					return "color-theme--white";
			}
			// switch (this.color) {
			//     case 'white':
			//         return 'bg-white';
			//     case 'black':
			//         return 'bg-black';
			//     case 'gray':
			//         return 'bg-gray';
			//     case 'purple':
			//         return 'bg-light-purple';
			//     case 'blue':
			//         return 'bg-light-blue';
			//     case 'turquoise':
			//         return 'bg-light-turquoise';
			//     case 'light purple':
			//         return 'bg-light-purple';
			//     case 'green':
			//         return 'bg-light-green';
			//     case 'yellow':
			//         return 'bg-light-yellow';
			//     case 'orange':
			//         return 'bg-light-orange';
			//     case 'red':
			//         return 'bg-light-red';
			//     case 'pink':
			//         return 'bg-light-pink';
			//     case 'dark':
			//         return "bg-dark";
			//     case 'dark-to-white':
			//         return "bg-dark-to-white";
			//     case 'transparent':
			//         return 'transparent';
			//     case 'blueGray':
			//         return 'bg-twe-light-gray-blue';
			//     default:
			//         return 'bg-white';
			// }
		},
	},
};
