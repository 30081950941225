import streamingPlusIntegration from "~/integrations/streaming-plus";
import { _fetchData } from "~/helpers/store";
const getDefaultState = () => {
	return {
		promise: null,
		data: [],
		error: null,
	};
};
export default {
	state: getDefaultState,
	actions: {
		async fetchData(context, { force }) {
			return _fetchData(() => streamingPlusIntegration.fetchStreamingPlusAccount(), {
				context,
				force,
				name: "streaming-plus-account",
			});
		},
	},
	mutations: {
		setData(state, data) {
			state.data = data;
		},
		setPromise(state, promise) {
			state.promise = promise;
		},
		setError(state, error) {
			state.error = error;
		},
	},
	getters: {
		data(state) {
			return state.data;
		},
		error(state) {
			return state.error;
		},
		loading(state) {
			return !!state.promise;
		},
		isAccountNotFound(state, getters) {
			return getters.error?.response?.data?.errorCode === 404000;
		},
	},
};

export const state = () => getDefaultState();
export const actions = {
	async fetchData(context, { force, accountId }) {
		return _fetchData(() => streamingPlusIntegration.fetchStreamingPlusAccount(accountId), {
			context,
			force,
			name: "streaming-plus-account",
		});
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
};
export const getters = {
	data(state) {
		return state.data;
	},
	error(state) {
		return state.error;
	},
	loading(state) {
		return !!state.promise;
	},
	isAccountNotFound(state, getters) {
		return getters.error?.response?.data?.errorCode === 404000;
	},
};
