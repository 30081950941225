export default {
	eCommerceCheckoutPage: defineAsyncComponent(() => import("~/components/layouts/eCommerceCheckoutPage.vue")),
	eCommerceProductPage: defineAsyncComponent(() => import("~/components/layouts/eCommerceProductPage.vue")),
	fixedMtwPage: defineAsyncComponent(() => import("~/components/layouts/fixedMtwPage/fixedMtwPage.vue")),
	fixedOrderRunPage: defineAsyncComponent(() => import("~/components/layouts/fixedOrderRunPage/fixedOrderRunPage.vue")),
	mobileMtwPage: defineAsyncComponent(() => import("~/components/layouts/mobileMtwPage.vue")),
	pillarArticle: defineAsyncComponent(() => import("~/components/layouts/pillarArticle.vue")),
	requiresLogin: defineAsyncComponent(() => import("~/components/layouts/requiresLogin/requiresLogin.vue")),
	standardArticle: defineAsyncComponent(() => import("~/components/layouts/standardArticle.vue")),
	default: defineAsyncComponent(() => import("~/components/layouts/standardPage/default.vue")),
	streamingPlusPage: defineAsyncComponent(() => import("~/components/layouts/streamingPlusPage/streamingPlusPage.vue")),
	stripped: defineAsyncComponent(() => import("~/components/layouts/stripped.vue")),
	tweBoxInfo: defineAsyncComponent(() => import("~/components/layouts/tweBoxInfo.vue")),
	tweBoxWebApp: defineAsyncComponent(() => import("~/components/layouts/tweBoxWebApp/tweBoxWebApp.vue")),
	tweDefault: defineAsyncComponent(() => import("~/components/layouts/tweDefault.vue")),
};