const replaceNonBreakingSpace = (string) => string.replace(new RegExp(String.fromCharCode(160), "g"), " ");
const IntlNumber = (options) =>
	new Intl.NumberFormat("no-NO", {
		...(options.prefix
			? { style: "currency", currency: "NOK" } // ads kr in front of number, i.e. 1250 -> kr 1 250
			: { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }),
		// trailingZeroDisplay: 'stripIfInteger', // trailingZeroDisplay :: strips trailing zeroes after comma
	});

const format = (value, options = {}) =>
	Array.of(value)
		.map((string) => (typeof string === "string" ? parseFloat(string) : string))
		.map((number) => (options.floor ? Math.floor(number) : number))
		.map((number) => (options.ceil ? Math.ceil(number) : number))
		.map((number) => (options.round ? Math.round(number) : number))
		.map((number) => IntlNumber(options).format(number))
		.map((price) => price.replace(",00", ",-"))
		// .map(price => options.prefix ? price : price + ',-')
		.map((price) => (options.monthly ? price + "/md." : price))
		.map(replaceNonBreakingSpace)
		.join();

export const formatPrice = {
	monthly: (number, options) => `${format(number, { ...options, monthly: true, prefix: false })}`,
	oneTime: (number, options) => `${format(number, { ...options, monthly: false })}`,
	monthlyPrefixCurrency: (number) => `${format(number, { prefix: true, monthly: true })}`,
	oneTimePrefixCurrency: (number) => `${format(number, { prefix: true })}`,
};

export default {
	formatPrice,
};
