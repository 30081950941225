/**
 * Do not edit directly
 * Generated on Mon, 13 May 2024 12:01:20 GMT
 */

export const colorDataCategoricalLightBlue = "#0f97ff";
export const colorDataCategoricalLightDarkBlue = "#00108a";
export const colorDataCategoricalLightPink = "#ff4173";
export const colorDataCategoricalLightTeal = "#006781";
export const colorDataCategoricalLightOrange = "#ff5a28";
export const colorDataCategoricalLightPurple = "#8a3ffc";
export const colorDataCategoricalLightGreen = "#007921";
export const colorDataCategoricalLightRed = "#8a0000";
export const colorDataCategoricalDarkBlue = "#b4ffff";
export const colorDataCategoricalDarkDarkBlue = "#0f97ff";
export const colorDataCategoricalDarkPink = "#e50063";
export const colorDataCategoricalDarkTeal = "#2ea1be";
export const colorDataCategoricalDarkOrange = "#ff5a28";
export const colorDataCategoricalDarkPurple = "#8a3ffc";
export const colorDataCategoricalDarkGreen = "#35a751";
export const colorDataCategoricalDarkRed = "#eb0000";
export const colorDataMonochromaticBlue900 = "#000f3c";
export const colorDataMonochromaticBlue800 = "#00108a";
export const colorDataMonochromaticBlue700 = "#2d28cd";
export const colorDataMonochromaticBlue600 = "#2741e7";
export const colorDataMonochromaticBlue500 = "#196af5";
export const colorDataMonochromaticBlue400 = "#0f97ff";
export const colorDataMonochromaticBlue300 = "#00c8ff";
export const colorDataMonochromaticBlue200 = "#74e9fb";
export const colorDataMonochromaticBlue100 = "#b4ffff";
export const colorDataMonochromaticBlue50 = "#d2ffff";
export const colorDataMonochromaticBlue25 = "#e8fdff";
export const colorDataMonochromaticPink900 = "#390e26";
export const colorDataMonochromaticPink800 = "#580e35";
export const colorDataMonochromaticPink700 = "#870b4d";
export const colorDataMonochromaticPink600 = "#b60463";
export const colorDataMonochromaticPink500 = "#e50063";
export const colorDataMonochromaticPink400 = "#ff4173";
export const colorDataMonochromaticPink300 = "#ff7fa1";
export const colorDataMonochromaticPink200 = "#ffb1c6";
export const colorDataMonochromaticPink100 = "#ffd1dd";
export const colorDataMonochromaticPink50 = "#fff0f4";
export const colorDataMonochromaticGreen900 = "#00330f";
export const colorDataMonochromaticGreen800 = "#004914";
export const colorDataMonochromaticGreen700 = "#00691d";
export const colorDataMonochromaticGreen600 = "#007921";
export const colorDataMonochromaticGreen500 = "#008a25";
export const colorDataMonochromaticGreen400 = "#35a751";
export const colorDataMonochromaticGreen300 = "#85cb98";
export const colorDataMonochromaticGreen200 = "#b1e3be";
export const colorDataMonochromaticGreen100 = "#cef1d7";
export const colorDataMonochromaticGreen50 = "#edfff2";
export const colorPrimaryDark = "#070452";
export const colorPrimaryMid = "#1c16c5";
export const colorPrimaryTelenor = "#00c8ff";
export const colorPrimaryLight = "#adffff";
export const colorPrimarySuperlight = "#ebffff";
export const colorCtaDefault = "#1020f9";
export const colorCtaHover = "#070452";
export const colorCtaActive = "#0e09aa";
export const colorCtaDefaultBackground = "#5280ff";
export const colorCtaHoverBackground = "#5280ff";
export const colorCtaDisabled = "#b2bad1";
export const colorCtaFocus = "#8533ff";
export const colorCtaDarkDefault = "#42f0f0";
export const colorCtaDarkHover = "#d6ffff";
export const colorCtaDarkActive = "#adffff";
export const colorCtaDarkDefaultBackground = "#ffffff";
export const colorCtaDarkHoverBackground = "#ffffff";
export const colorCtaDarkDisabled = "#4f5a7d";
export const colorCtaDarkFocus = "#ff2483";
export const colorNeutralsBlack = "#000b2e";
export const colorNeutrals1000Shade = "#18223f";
export const colorNeutrals900Shade = "#293351";
export const colorNeutrals800Shade = "#374262";
export const colorNeutrals700Shade = "#4f5a7d";
export const colorNeutrals600Shade = "#677398";
export const colorNeutrals500Core = "#7c88ab";
export const colorNeutrals400Tint = "#929dbf";
export const colorNeutrals300Tint = "#b2bad1";
export const colorNeutrals200Tint = "#c5cbdd";
export const colorNeutrals100Tint = "#e5e8f0";
export const colorNeutrals50Tint = "#f2f3f7";
export const colorNeutrals25Tint = "#f9f9fb";
export const colorNeutralsWhite = "#ffffff";
export const colorSuccess800Shade = "#114517";
export const colorSuccess500Core = "#24a831";
export const colorSuccess200Tint = "#d8fddc";
export const colorSuccess50Tint = "#ecfef1";
export const colorAttention800Shade = "#4e410e";
export const colorAttention500Core = "#fde408";
export const colorAttention200Tint = "#fffbd6";
export const colorAttention50Tint = "#fffdeb";
export const colorWarning800Shade = "#5c2600";
export const colorWarning500Core = "#f06906";
export const colorWarning200Tint = "#ffe7d6";
export const colorWarning50Tint = "#fff3eb";
export const colorCritical800Shade = "#5c0000";
export const colorCritical500Core = "#c70505";
export const colorCritical200Tint = "#ffd6d6";
export const colorCritical50Tint = "#ffebeb";
export const colorInformation800Shade = "#0e0a7f";
export const colorInformation500Core = "#1020f9";
export const colorInformation200Tint = "#d6e7ff";
export const colorInformation50Tint = "#ebf3ff";
export const effectShadowHoverS0 = "0px 0px 4px -2px rgba(0, 0, 0, 0.12)";
export const effectShadowHoverS1 = "0px 4px 12px 0px rgba(0, 0, 0, 0.08)";
export const effectShadowHoverS2 = "0px 12px 16px 0px rgba(0, 0, 0, 0.02)";
export const effectShadowHoverM0 = "0px 0px 4px -2px rgba(0, 0, 0, 0.12)";
export const effectShadowHoverM1 = "0px 4px 16px 0px rgba(0, 0, 0, 0.08)";
export const effectShadowHoverM2 = "0px 16px 32px 4px rgba(0, 0, 0, 0.04)";
export const effectShadowHoverL0 = "0px 0px 4px -2px rgba(0, 0, 0, 0.12)";
export const effectShadowHoverL1 = "0px 4px 16px 0px rgba(0, 0, 0, 0.08)";
export const effectShadowHoverL2 = "0px 24px 48px 4px rgba(0, 0, 0, 0.06)";
export const effectShadowS0 = "0px 0px 4px -2px rgba(0, 0, 0, 0.12)";
export const effectShadowS1 = "0px 2px 8px 0px rgba(0, 0, 0, 0.08)";
export const effectShadowS2 = "0px 2px 8px 0px rgba(0, 0, 0, 0.02)";
export const effectShadowM0 = "0px 0px 4px -2px rgba(0, 0, 0, 0.12)";
export const effectShadowM1 = "0px 2px 8px 0px rgba(0, 0, 0, 0.08)";
export const effectShadowM2 = "0px 4px 12px 0px rgba(0, 0, 0, 0.04)";
export const effectShadowL0 = "0px 0px 4px -2px rgba(0, 0, 0, 0.12)";
export const effectShadowL1 = "0px 2px 8px 0px rgba(0, 0, 0, 0.08)";
export const effectShadowL2 = "0px 8px 16px 0px rgba(0, 0, 0, 0.04)";
export const fontTitle2xl = {
	fontSize: 56,
	textDecoration: "none",
	fontFamily: "Telenor Evolution ",
	fontWeight: 400,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 64,
	paragraphIndent: 0,
	paragraphSpacing: 0,
	textCase: "none",
};
export const fontTitleXl = {
	fontSize: 48,
	textDecoration: "none",
	fontFamily: "Telenor Evolution ",
	fontWeight: 400,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 56,
	paragraphIndent: 0,
	paragraphSpacing: 0,
	textCase: "none",
};
export const fontTitleL = {
	fontSize: 40,
	textDecoration: "none",
	fontFamily: "Telenor Evolution ",
	fontWeight: 400,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 48,
	paragraphIndent: 0,
	paragraphSpacing: 0,
	textCase: "none",
};
export const fontTitleM = {
	fontSize: 32,
	textDecoration: "none",
	fontFamily: "Telenor Evolution ",
	fontWeight: 400,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 36,
	paragraphIndent: 0,
	paragraphSpacing: 0,
	textCase: "none",
};
export const fontTitleS = {
	fontSize: 24,
	textDecoration: "none",
	fontFamily: "Telenor Evolution ",
	fontWeight: 400,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 30,
	paragraphIndent: 0,
	paragraphSpacing: 0,
	textCase: "none",
};
export const fontTitleXs = {
	fontSize: 20,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 400,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 26,
	paragraphIndent: 0,
	paragraphSpacing: 0,
	textCase: "none",
};
export const fontTitle2xs = {
	fontSize: 16,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 400,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 22,
	paragraphIndent: 0,
	paragraphSpacing: 0,
	textCase: "none",
};
export const fontTitleBold2xlBold = {
	fontSize: 56,
	textDecoration: "none",
	fontFamily: "Telenor Evolution ",
	fontWeight: 700,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 64,
	paragraphIndent: 0,
	paragraphSpacing: 0,
	textCase: "none",
};
export const fontTitleBoldXlBold = {
	fontSize: 48,
	textDecoration: "none",
	fontFamily: "Telenor Evolution ",
	fontWeight: 700,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 56,
	paragraphIndent: 0,
	paragraphSpacing: 0,
	textCase: "none",
};
export const fontTitleBoldLBold = {
	fontSize: 40,
	textDecoration: "none",
	fontFamily: "Telenor Evolution ",
	fontWeight: 700,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 48,
	paragraphIndent: 0,
	paragraphSpacing: 0,
	textCase: "none",
};
export const fontTitleBoldMBold = {
	fontSize: 32,
	textDecoration: "none",
	fontFamily: "Telenor Evolution ",
	fontWeight: 700,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 36,
	paragraphIndent: 0,
	paragraphSpacing: 0,
	textCase: "none",
};
export const fontTitleBoldSBold = {
	fontSize: 24,
	textDecoration: "none",
	fontFamily: "Telenor Evolution ",
	fontWeight: 700,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 30,
	paragraphIndent: 0,
	paragraphSpacing: 0,
	textCase: "none",
};
export const fontTitleBoldXsBold = {
	fontSize: 20,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 700,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 26,
	paragraphIndent: 0,
	paragraphSpacing: 0,
	textCase: "none",
};
export const fontTitleBold2xsBold = {
	fontSize: 16,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 700,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 22,
	paragraphIndent: 0,
	paragraphSpacing: 0,
	textCase: "none",
};
export const fontTextXl = {
	fontSize: 24,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 300,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 34,
	paragraphIndent: 0,
	paragraphSpacing: 24,
	textCase: "none",
};
export const fontTextL = {
	fontSize: 20,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 300,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 28,
	paragraphIndent: 0,
	paragraphSpacing: 20,
	textCase: "none",
};
export const fontTextM = {
	fontSize: 18,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 300,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 26,
	paragraphIndent: 0,
	paragraphSpacing: 18,
	textCase: "none",
};
export const fontTextS = {
	fontSize: 16,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 300,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 22,
	paragraphIndent: 0,
	paragraphSpacing: 16,
	textCase: "none",
};
export const fontTextXs = {
	fontSize: 14,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 300,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 20,
	paragraphIndent: 0,
	paragraphSpacing: 14,
	textCase: "none",
};
export const fontText2xs = {
	fontSize: 12,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 300,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 16,
	paragraphIndent: 0,
	paragraphSpacing: 12,
	textCase: "none",
};
export const fontTextBoldXlBold = {
	fontSize: 24,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 500,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 34,
	paragraphIndent: 0,
	paragraphSpacing: 24,
	textCase: "none",
};
export const fontTextBoldLBold = {
	fontSize: 20,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 500,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 28,
	paragraphIndent: 0,
	paragraphSpacing: 20,
	textCase: "none",
};
export const fontTextBoldMBold = {
	fontSize: 18,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 500,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 26,
	paragraphIndent: 0,
	paragraphSpacing: 18,
	textCase: "none",
};
export const fontTextBoldSBold = {
	fontSize: 16,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 500,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 22,
	paragraphIndent: 0,
	paragraphSpacing: 16,
	textCase: "none",
};
export const fontTextBoldXsBold = {
	fontSize: 14,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 500,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 20,
	paragraphIndent: 0,
	paragraphSpacing: 14,
	textCase: "none",
};
export const fontTextBold2xsBold = {
	fontSize: 12,
	textDecoration: "none",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 500,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 16,
	paragraphIndent: 0,
	paragraphSpacing: 12,
	textCase: "none",
};
export const fontTextItalicXlItalic = {
	fontSize: 24,
	textDecoration: "none",
	fontFamily: "Arial",
	fontWeight: 400,
	fontStyle: "italic",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 34,
	paragraphIndent: 0,
	paragraphSpacing: 18,
	textCase: "none",
};
export const fontTextItalicLItalic = {
	fontSize: 20,
	textDecoration: "none",
	fontFamily: "Arial",
	fontWeight: 400,
	fontStyle: "italic",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 28,
	paragraphIndent: 0,
	paragraphSpacing: 15,
	textCase: "none",
};
export const fontTextItalicMItalic = {
	fontSize: 18,
	textDecoration: "none",
	fontFamily: "Arial",
	fontWeight: 400,
	fontStyle: "italic",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 26,
	paragraphIndent: 0,
	paragraphSpacing: 13.5,
	textCase: "none",
};
export const fontTextItalicSItalic = {
	fontSize: 16,
	textDecoration: "none",
	fontFamily: "Arial",
	fontWeight: 400,
	fontStyle: "italic",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 22,
	paragraphIndent: 0,
	paragraphSpacing: 12,
	textCase: "none",
};
export const fontTextItalicXsItalic = {
	fontSize: 14,
	textDecoration: "none",
	fontFamily: "Arial",
	fontWeight: 400,
	fontStyle: "italic",
	fontStretch: "normal",
	letterSpacing: 0.1,
	lineHeight: 20,
	paragraphIndent: 0,
	paragraphSpacing: 10.5,
	textCase: "none",
};
export const fontTextItalic2xsItalic = {
	fontSize: 12,
	textDecoration: "none",
	fontFamily: "Arial",
	fontWeight: 400,
	fontStyle: "italic",
	fontStretch: "normal",
	letterSpacing: 0.1,
	lineHeight: 16,
	paragraphIndent: 0,
	paragraphSpacing: 9,
	textCase: "none",
};
export const fontTextLinkXlLink = {
	fontSize: 24,
	textDecoration: "underline",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 300,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 34,
	paragraphIndent: 0,
	paragraphSpacing: 24,
	textCase: "none",
};
export const fontTextLinkLLink = {
	fontSize: 20,
	textDecoration: "underline",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 300,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 28,
	paragraphIndent: 0,
	paragraphSpacing: 20,
	textCase: "none",
};
export const fontTextLinkMLink = {
	fontSize: 18,
	textDecoration: "underline",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 300,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 26,
	paragraphIndent: 0,
	paragraphSpacing: 18,
	textCase: "none",
};
export const fontTextLinkSLink = {
	fontSize: 16,
	textDecoration: "underline",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 300,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 22,
	paragraphIndent: 0,
	paragraphSpacing: 16,
	textCase: "none",
};
export const fontTextLinkXsLink = {
	fontSize: 14,
	textDecoration: "underline",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 300,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 20,
	paragraphIndent: 0,
	paragraphSpacing: 14,
	textCase: "none",
};
export const fontTextLink2xsLink = {
	fontSize: 12,
	textDecoration: "underline",
	fontFamily: "Telenor Evolution UI",
	fontWeight: 300,
	fontStyle: "normal",
	fontStretch: "normal",
	letterSpacing: 0,
	lineHeight: 16,
	paragraphIndent: 0,
	paragraphSpacing: 12,
	textCase: "none",
};
export const fontFamilyTelenorBase = '"Telenor Evolution", Helvetica, Arial, sans-serif';
export const fontFamilyTelenorUi = '"Telenor Evolution UI", Helvetica, Arial, sans-serif';
export const assetFontTelenorEvolution400Normal = "fonts/TelenorEvolution-Normal";
export const assetFontTelenorEvolution700Normal = "fonts/TelenorEvolution-Bold";
export const assetFontTelenorEvolutionUi300Normal = "fonts/TelenorEvolutionUI-Light";
export const assetFontTelenorEvolutionUi400Normal = "fonts/TelenorEvolutionUI-Light";
export const assetFontTelenorEvolutionUi500Normal = "fonts/TelenorEvolutionUI-Medium";
export const assetFontTelenorEvolutionUi700Normal = "fonts/TelenorEvolutionUI-Medium";
export const sizeScreenXs = "767px";
export const sizeScreenS = "960px";
export const sizeScreenM = "1220px";
export const sizeScreenL = "1220px";
export const sizeScreenXl = "1920px";
export const sizeScreenPhone = "767px";
export const sizeScreenTablet = "960px";
export const sizeScreenDesktop = "1220px";
export const sizeScreenUltra = "1920px";
export const spacingNone = "0"; // No spacing
export const spacing2xs = "2px";
export const spacingXs = "4px";
export const spacingS = "8px";
export const spacingM = "16px";
export const spacingL = "24px";
export const spacingXl = "32px";
export const spacing2xl = "40px";
export const spacing3xl = "48px";
export const spacing4xl = "64px";
export const spacing5xl = "80px";
export const spacing6xl = "120px";
export const borderRadiusS = "8px";
export const borderRadiusM = "16px";
export const borderRadiusL = "24px";
