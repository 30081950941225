import apigeeIntegration from "./self-service-adapter";

const api = apigeeIntegration("/consumer-fault-search/v1");

const startFlow = async (subId) => api.get(`subscription/${subId}/flow/ssnl_bb_ftv/start`);

const nextStep = async (subId, stepId, sessionId, body, conclusion) =>
	api.put(`subscription/${subId}/flow/ssnl_bb_ftv/step`, {
		stepId: stepId,
		session: sessionId,
		inputParameters: {
			...body,
		},
		conclusion,
	});

export default {
	startFlow,
	nextStep,
};
