import hasSegmentation from "~/helpers/segmentation/hasSegmentation";

export default (component, state) => {
	const segments = state.customer?.segments || [];

	if (hasSegmentation(component)) {
		const deviceSegmentation = component.segmentation.device || component.segmentation.devices;

		if (!state.execSegmentation) return false;

		let show = true;

		if (deviceSegmentation) {
			let userAgent = state.userAgent?.toLowerCase();

			if (deviceSegmentation.must && deviceSegmentation.must.length > 0) {
				if (!deviceSegmentation.must.some((device) => userAgent?.indexOf(device?.toLowerCase()) > -1)) return false;
			}

			if (deviceSegmentation.mustNot && deviceSegmentation.mustNot.length > 0) {
				if (deviceSegmentation.mustNot.some((device) => userAgent?.indexOf(device?.toLowerCase()) > -1)) return false;
			}
		}

		if (
			component.segmentation.must ||
			component.segmentation.mustNot ||
			component.segmentation.mustNotBeLoggedIn ||
			component.segmentation.mustBeLoggedIn
		) {
			if (!state.customer?.firstName && !state.gizmo?.loggedIn)
				// Customer is NOT logged in
				return component.segmentation.mustNotBeLoggedIn;
			// ...but this content is exclusively for people who are not logged in
			else if (component.segmentation.mustNotBeLoggedIn)
				// Customer IS logged in, and should not see content
				return false;

			if (component.segmentation.must?.length > 0) {
				show = component.segmentation.must.some((segment) => segments.includes(segment));
			}

			if (component.segmentation.mustNot?.length > 0) {
				show = !component.segmentation.mustNot.some((segment) => segments.includes(segment));
			}
		}

		return show;
	} else {
		return true;
	}
};
