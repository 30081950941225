export const state = () => ({
	flow: null,
	steps: [],
	hasValidContactDetails: false,
	currentStep: null,
	lastUsedSubId: null
});
export const actions = {};
export const mutations = {
	setFlow(state, flow) {
		state.flow = flow;
	},
	setCurrentStep(state, currentStep) {
		state.currentStep = currentStep;
	},
	updateSteps(state, steps) {
		state.steps = steps;
	},
	setHasValidContactDetails(state, valid) {
		state.hasValidContactDetails = valid;
	},
	setLastUsedSubId(state, subId) {
		state.lastUsedSubId = subId;
	}
};
export const getters = {
	//these getters are used to resolve flow in gizmo
	currentFlow(state) {
		return state.flow;
	},
	addressIsSelected(state, getters, rootState, rootGetters) {
		return !!rootGetters?.["address/address"];
	},
	isCaptchaVerified(state, getters, rootState) {
		return !!rootState?.reCaptcha?.token.length;
	},
	bbOnly(state, getters, rootState, rootGetters) {
		return (
			rootGetters["fixedOrder/input/selectedOffer"]?.categories.includes("BB") &&
			!rootGetters["fixedOrder/input/selectedOffer"]?.categories.includes("TV")
		);
	},
	tvOnly(state, getters, rootState, rootGetters) {
		return (
			!rootGetters["fixedOrder/input/selectedOffer"]?.categories.includes("BB") &&
			rootGetters["fixedOrder/input/selectedOffer"]?.categories.includes("TV")
		);
	},
	isDualPlay(state, getters, rootState, rootGetters) {
		return (
			rootGetters["fixedOrder/input/selectedOffer"]?.categories.includes("BB") &&
			rootGetters["fixedOrder/input/selectedOffer"]?.categories.includes("TV")
		);
	},
	isHFC(state, getters, rootState, rootGetters) {
		return rootGetters["fixedOrder/input/selectedOffer"]?.serviceTechnology === "HFC";
	},
	isFiber(state, getters, rootState, rootGetters) {
		return rootGetters["fixedOrder/input/selectedOffer"]?.serviceTechnology === "GPON";
	},
	isTBB(state, getters, rootState, rootGetters) {
		return (
			rootGetters["fixedOrder/input/selectedOffer"]?.serviceTechnology === "FWA" &&
			!rootGetters["fixedOrder/input/selectedOffer"]?.offerId.includes("CABIN")
		);
	},
	isMyOffer(state, getters, rootState, rootGetters) {
		return rootGetters["fixedOrder/input/selectedMainProducts"]?.some((mainProduct) =>
			mainProduct?.offerTags?.includes("MY_OFFER"),
		);
	},
	isTBBCabin(state, getters, rootState, rootGetters) {
		return (
			rootGetters["fixedOrder/input/selectedOffer"]?.serviceTechnology === "FWA" &&
			rootGetters["fixedOrder/input/selectedOffer"]?.offerId.includes("CABIN")
		);
	},
	isUserAuthenticated(state, getters, rootState, rootGetters) {
		return !!rootGetters.getAuthenticatedCustomer;
	},
	isLoggedOut(state, getters, rootState, rootGetters) {
		return !rootGetters.getAuthenticatedCustomer;
	},
	hasSelectedInstallation(state, getters, rootState) {
		return !!rootState["fixedOrder"].input.selections.installations.length;
	},
	isWholesale(state, getters, rootState, rootGetters) {
		let selectedOffer = rootGetters["fixedOrder/input/selectedOffer"];
		return (
			rootState["fixedOrder"].input.metaInformation?.feasibilitySummary?.technology?.filter(
				(technology) => technology.wholesale,
			)?.[0]?.type === selectedOffer?.serviceTechnology && !!selectedOffer?.serviceTechnology
		);
	},
	isNotWholesale(state, getters) {
		return !getters.isWholesale;
	},
	dateIsSelected(state, getters, rootState, rootGetters) {
		return !!rootGetters["fixedOrder/input/connectDate"];
	},
	isOTConsent(state, getters, rootState, rootGetters) {
		return rootGetters?.["fixedOrder/input/offers"]?.some(
			(offer) => offer?.projectInformation?.publicGrantInformation?.consentRequired,
		);
	},
	steps(state) {
		return state.steps;
	},
	homeStatus(state, getters, rootState, rootGetters) {
		return rootGetters["fixedOrder/input/homeStatus"];
	},
	isPlannedTechnology(state, getters, rootState, rootGetters) {
		return getters.homeStatus === "OFF-NET" && rootGetters["fixedOrder/input/isBrownfield"];
	},
	isNotPlannedTechnology(state, getters, rootState, rootGetters) {
		return !rootGetters["fixedOrder/input/isBrownfield"];
	},
	offNetTBB(state, getters) {
		return getters.homeStatus === "OFF-NET" && getters.isTBB;
	},
	offNetFiber(state, getters) {
		return getters.homeStatus === "OFF-NET" && getters.isFiber;
	},
	onNetFiber(state, getters) {
		return getters.homeStatus === "ON-NET" && getters.isFiber;
	},
	addTV(state, getters) {
		return getters.currentFlow?.name === "add-tv";
	},
	unknownHomeStatus(state, getters) {
		return getters.homeStatus === "UNKNOWN";
	},
	hasAddedEquipment(state, getters, rootState, rootGetters) {
		return rootGetters["fixedOrder/input/hasOrderedEquipment"];
	},
	hasSelectedMainProducts(state, getters, rootState) {
		return !!rootState["fixedOrder"].input?.selections?.mainProducts?.length;
	},
	finishedRecommendation(state, getteers, rootState) {
		return rootState["fixedOrder"]?.input.hasFinishedRecommendation;
	},
	hasSelectedEquipment(state, getters, rootState) {
		return !!rootState["fixedOrder"].input.selections?.equipment?.length;
	},
	noTVOffers(state, getters, rootState, rootGetters) {
		return !rootGetters["fixedOrder/input/offers"]?.find(
			(offer) => offer?.categories?.length === 1 && offer?.categories.includes("TV"),
		);
	},
	hasTVOffers(state, getters, rootState, rootGetters) {
		return !!rootGetters["fixedOrder/input/offers"]?.find(
			(offer) => offer?.categories?.length === 1 && offer?.categories.includes("TV"),
		);
	},
	hasValidContactDetails(state) {
		return state.hasValidContactDetails;
	},
	noOffers(state, getters, rootState, rootGetters) {
		return rootGetters["fixedOrder/input/promiseSettled"] && !rootState["fixedOrder"].input.data.length;
	},
	isMDU(state, getters, rootState) {
		return rootState["fixedOrder"].input.metaInformation?.agreementInfo?.isMdu;
	},
	lastUsedSubId:(state) => state.lastUsedSubId
};
