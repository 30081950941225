import apigeeIntegration from "./self-service-adapter";

const api = apigeeIntegration("/debt-management/v1");

const acknowledgeAlerts = () => api.delete(`/debt/alerts`);
const getAlerts = () => api.get(`/debt/alerts`);
const getConsent = () => api.get(`/consent`);
const getDebt = () => api.get(`/debt`);
const giveConsent = () => api.post(`/consent`);
const removeConsent = () => api.delete(`/consent`);
export default {
	getConsent,
	getDebt,
	giveConsent,
	removeConsent,
	getAlerts,
	acknowledgeAlerts,
};
