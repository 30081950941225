import AddressSearch from "~/integrations/address-search";
import customers from "~/integrations/customers";
import { toLatLon } from "utm";
import geolocationIntegration from "~/integrations/geolocation-integration";

const unsupportedPaths = ["mitt-telenor/tv-internett/twe/innholds-velger/twe-box", "loginCallbackTweBox"];

const getDevicePosition = async (silent) => {
	let permitsGeolocation = {
		state: "prompt",
	};

	if (navigator.permissions) permitsGeolocation = await navigator.permissions.query({ name: "geolocation" });

	// Set or ask the user for permission to get location (if not silent)
	if (permitsGeolocation.state === "granted" || !silent) {
		try {
			let position = await new Promise((resolve, reject) => {
				navigator.geolocation.getCurrentPosition(resolve, reject);
			});

			return position?.coords;
		} catch (e) {
			// Geolocation query failed
			return false;
		}
	} else {
		return false;
	}
};

const getCoordinatesFromLoggedInUserData = async () => {
	const translateEPSG32633CoordinatesToLatLng = (x, y) => {
		try {
			return toLatLon(Number(x), Number(y), 33, "N", undefined, false);
		} catch (ex) {
			return {};
		}
	};

	const getCoordinatesFromAddress = async (address) => {
		const result = await AddressSearch.search(address);
		console.log("ADDRESS SEARCH RESULT: ", result);

		if (result && result.length === 1) {
			return translateEPSG32633CoordinatesToLatLng(result[0].coordX, result[0].coordY);
		}
	};

	const formatAddressString = (address) => `${address.streetName} ${address.streetNumber} ${address.postalPlace}`;

	const customer = await customers.getCustomerData();
	const { address } = customer;
	if (address) {
		return getCoordinatesFromAddress(formatAddressString(address));
	}
};

export const state = () => {
	return {
		devicePosition: {
			latitude: 0.0,
			longitude: 0.0,
		},
		customerPosition: {
			latitude: 0.0,
			longitude: 0.0,
		},
		approximatePosition: {
			latitude: 0.0,
			longitude: 0.0,
		},
	};
};
export const mutations = {
	setCustomerPosition(state, position) {
		state.customerPosition = position;
	},
	setDevicePosition(state, position) {
		state.devicePosition = position;
	},
	setApproximatePosition(state, position) {
		state.approximatePosition = position;
	},
};
export const actions = {
	async requestDevicePosition({ commit }, silent) {
		const position = await getDevicePosition(silent);

		if (position) commit("setDevicePosition", { latitude: position.latitude, longitude: position.longitude });

		return position;
	},
	async requestCustomerPosition({ commit, rootState }) {
		if (rootState.customer) {
			const coordinates = await getCoordinatesFromLoggedInUserData();
			if (coordinates) commit("setCustomerPosition", coordinates);
		}
	},
	async requestApproximatePosition({ commit }) {
		const position = await geolocationIntegration.getApproximateLocation();

		if (position)
			commit("setApproximatePosition", { latitude: position.data.latitude, longitude: position.data.longitude });

		return position;
	},
	async requestBestAvailablePosition({ state, dispatch }, silent) {
		if (unsupportedPaths.some((path) => window.location.href.includes(path))) {
			return null;
		}

		if (state.devicePosition.latitude) return state.devicePosition;
		if (state.customerPosition.latitude) return state.devicePosition;

		await dispatch("requestDevicePosition", silent);

		if (state.devicePosition.latitude) return state.devicePosition;

		await dispatch("requestCustomerPosition", silent);

		if (state.devicePosition.latitude) return state.customerPosition;

		await dispatch("requestApproximatePosition", silent);

		if (state.approximatePosition.latitude) return state.approximatePosition;
	},
};
export const getters = {
	position(state) {
		if (state.devicePosition.longitude) return state.devicePosition;
		if (state.customerPosition.longitude) return state.customerPosition;
		if (state.approximatePosition.longitude) return state.approximatePosition;
		return {};
	},
};
