import { _fetchData } from "~/helpers/store";
import nettvernPlus from "~/integrations/nettvern-plus";

export default {
	state() {
		return {
			promise: null,
			data: null,
			error: null,
			nettvernContent: null,
		};
	},
	actions: {
		async fetchData(context, options) {
			return _fetchData(() => nettvernPlus.getReportForLoggedOutUser(), {
				context,
				force: !!options?.force,
				name: "nettvern",
			});
		},
	},
	mutations: {
		setData(state, data) {
			state.data = data;
		},
		setPromise(state, promise) {
			state.promise = promise;
		},
		setError(state, error) {
			state.error = error;
		},
		setNettvernContent(state, nettvernContent) {
			state.nettvernContent = nettvernContent;
		},
	},
	getters: {
		nettvernData(state) {
			return state?.data;
		},
		loading: (state) => (!state?.data || state.promise !== null) && !state.error,
		isError: (state) => state.error,
		getNettvernContent: (state) => state.nettvernContent,
	},
};

export const state = () => ({
	promise: null,
	data: null,
	error: null,
	nettvernContent: null,
});
export const actions = {
	async fetchData(context, options) {
		return _fetchData(() => nettvernPlus.getReportForLoggedOutUser(), {
			context,
			force: !!options?.force,
			name: "nettvern",
		});
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	setNettvernContent(state, nettvernContent) {
		state.nettvernContent = nettvernContent;
	},
};
export const getters = {
	nettvernData(state) {
		return state?.data;
	},
	loading: (state) => (!state?.data || state.promise !== null) && !state.error,
	isError: (state) => state.error,
	getNettvernContent: (state) => state.nettvernContent,
};
