/*
This is a compatibility plugin to patch the difference between tnweb-nuxt 2 and tnweb-nuxt 3 to compensate for the
lack of nuxtServerInit() in Nuxt 3.
 */

import telenorid from "~/telenorid/telenorid.js";
import { ConsoleInstrumentation, getWebInstrumentations, initializeFaro, LogLevel } from "@grafana/faro-web-sdk";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";

export default defineNuxtPlugin((nuxtApp) => {
	const { commit, dispatch, state } = nuxtApp.$store;
	const { $config } = nuxtApp;
	commit("setHost", window.location.host);

	if (!("toJSON" in Error.prototype))
		Object.defineProperty(Error.prototype, "toJSON", {
			value: function () {
				var alt = {};

				Object.getOwnPropertyNames(this).forEach(function (key) {
					alt[key] = this[key];
				}, this);

				return alt;
			},
			configurable: true,
			writable: true,
		});

	if ($config.public.faroUrl) {
		initializeFaro({
			url: $config.public.faroUrl,
			apiKey: "4b8pz27u7Wow86oMQt",
			instrumentations: [
				...getWebInstrumentations({ captureConsole: true }),
				new ConsoleInstrumentation({
					disabledLevels: [LogLevel.LOG, LogLevel.INFO, LogLevel.DEBUG],
				}),
				new TracingInstrumentation(),
			],
			app: {
				name: "tnweb-nuxt",
				version: "1.0.0",
			},
		});
	}

	/*
	When the user switches tabs, we need to make sure that the TelenorID state is still valid - be it logged in or out
	 */
	document.addEventListener("visibilitychange", async () => {
		if (document.visibilityState === "visible") {
			if (state.customer) {
				// Temporary fix for issue #121
				/*try {
					await telenorid.querySessionStatus();
				} catch (err){
					console.error(err);
					// Session expired / logout, clear user
					await telenorid.properlyRemoveUser();
					await dispatch('clearCustomer');
				}*/
			} else {
				const user = await telenorid.getOrLoginUser(true);
				if (user) dispatch("setUser", user);
			}
		}
	});

	onNuxtReady(() => {
		let viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
		if (viewportWidth < 768) {
			console.log("Mobile detected");
			commit("setMobile", true);
		}

		const userAgent = navigator?.userAgent;
		commit("setUserAgent", userAgent);
	});
});
