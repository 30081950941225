import streamingPlusIntegration from "~/integrations/streaming-plus";
import { _fetchData } from "~/helpers/store";

const getDefaultState = () => {
	return {
		promise: null,
		data: [],
		error: null,
	};
};
export const state = getDefaultState;
export const actions = {
	async fetchData(context, { force }) {
		return _fetchData(() => streamingPlusIntegration.fetchStreamingPlusPrices(), {
			context,
			force,
			name: "streamingPlusPrices",
		});
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
};
export const getters = {
	data(state) {
		return state.data;
	},
	error(state) {
		return state.error;
	},
	loading(state) {
		return !!state.promise;
	},
	getPriceByProductId: (state, getters) => (bundleType, productId) => {
		return getters?.data?.pricePlans
			?.find((pricePlan) => pricePlan.bundleType === bundleType)
			?.prices?.find((price) => price.productId === productId);
	},
	getPriceByProductType: (state, getters) => (bundleType, productType) => {
		return getters?.data?.pricePlans
			?.find((pricePlan) => pricePlan.bundleType === bundleType)
			?.prices?.find((price) => price.productType === productType);
	},
};
