import filterComponent from "~/helpers/segmentation/filterComponent";
import hasSegmentation from "~/helpers/segmentation/hasSegmentation";

export default function useComponentSegmentation(component) {
	if (!component) return false;

	const { $store } = useNuxtApp();

	if ($store.getters["segmentationDisabled"] || !hasSegmentation(component)) return true;

	return filterComponent(component, $store.state);
}
