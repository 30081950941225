import productfeasibility from "~/integrations/productfeasibility";
import tvContent from "~/integrations/tv-content";
import { _fetchData } from "~/helpers/store";
const getDefaultState = () => {
	return {
		promise: null,
		data: {},
		error: null,
		unsavedSelectedFrihetBBSpeedProduct: null,
		unsavedSelectedFrihetTvProduct: null,
		unsavedSelectedEquipments: [],
	};
};
export const state = getDefaultState;
export const actions = {
	async fetchData(context, { subscriptionId, force }) {
		return _fetchData(() => productfeasibility.getExistingOffersForSubscription(subscriptionId), {
			context,
			force,
			name: "frihet-subscription",
		});
	},
	async validateSelectedFrihetProducts(context, { subscriptionId, data }) {
		return tvContent.validateSelectedFrihetProducts(subscriptionId, data);
	},
	async updateSelectedFrihetProducts(context, { subscriptionId, data }) {
		return tvContent.updateSelectedFrihetProducts(subscriptionId, data);
	},
	async getFrihetFreightCost(context, { subscriptionId, data }) {
		return tvContent.getFrihetFreightCost(subscriptionId, data);
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	setUnsavedSelectedFrihetBBSpeedProduct(state, product) {
		state.unsavedSelectedFrihetBBSpeedProduct = product;
	},
	setUnsavedSelectedFrihetTvProduct(state, product) {
		state.unsavedSelectedFrihetTvProduct = product;
	},
	setUnsavedSelectedEquipmentsProducts(state, products) {
		state.unsavedSelectedEquipments = products;
	},
	resetState(state, includeServerData) {
		if (includeServerData) {
			Object.assign(state, getDefaultState());
		} else {
			state.unsavedSelectedFrihetBBSpeedProduct = null;
			state.unsavedSelectedFrihetTvProduct = null;
			state.unsavedSelectedEquipments = [];
		}
	},
};
export const getters = {
	error(state) {
		return state.error;
	},
	loading(state) {
		return !!state.promise;
	},
	offers(state) {
		return state.data;
	},
	frihetBBSpeedOffer(state) {
		let frihetBBOffer = null;
		const frihetBBOffers = state.data?.existingTechOffers?.filter((offer) => offer?.categories?.includes("BB"));
		frihetBBOffers?.forEach((offer) => {
			if (offer.available) {
				frihetBBOffer = offer;
			}
		});
		return frihetBBOffer;
	},
	frihetBBSpeedProducts(state, getters) {
		return getters.frihetBBSpeedOffer?.mainProducts?.BB || [];
	},
	frihetTvOffer(state) {
		let frihetTvOffer = null;
		const frihetTvOffers = state.data?.existingTechOffers?.filter((offer) => offer?.categories?.includes("TV"));
		frihetTvOffers?.forEach((offer) => {
			if (offer.available) {
				frihetTvOffer = offer;
			}
		});
		return frihetTvOffer;
	},
	frihetTvProducts(state, getters) {
		return getters.frihetTvOffer?.mainProducts?.TV || [];
	},
	//TV products are handled differently from BB since we receive two products with the same ID
	isTvEnabled(state, getters) {
		let isEnabled = false;
		getters.frihetTvProducts?.forEach((product) => {
			if (
				product.category === "TV" &&
				product.constraints.subscribed &&
				getters.enableTvProduct.productId === product.productId
			) {
				isEnabled = true;
			}
		});
		return isEnabled;
	},
	unsavedSelectedFrihetBBSpeedProduct(state) {
		return state.unsavedSelectedFrihetBBSpeedProduct;
	},
	unsavedSelectedFrihetTvProduct(state) {
		return state.unsavedSelectedFrihetTvProduct;
	},
	savedSelectedFrihetBBSpeedProduct(state, getters) {
		return getters.frihetBBSpeedProducts?.filter(
			(frihetBBSpeedProduct) => frihetBBSpeedProduct?.constraints?.action === "REMOVE",
		)?.[0];
	},
	selectedFrihetBBSpeedProduct(state, getters) {
		if (getters.unsavedSelectedFrihetBBSpeedProduct) return getters.unsavedSelectedFrihetBBSpeedProduct;
		return getters.frihetBBSpeedProducts?.filter(
			(frihetBBSpeedProduct) => frihetBBSpeedProduct?.constraints?.action === "REMOVE",
		)?.[0];
	},
	enableTvProduct(state, getters) {
		return getters.frihetTvProducts.filter(
			(product) => product.category === "TV" && product.constraints.action === "ADD",
		)?.[0];
	},
	disableTvProduct(state, getters) {
		return getters.frihetTvProducts.filter(
			(product) => product.category === "TV" && product.constraints.action !== "ADD",
		)?.[0];
	},
	savedSelectedFrihetTvProduct(state, getters) {
		if (getters.isTvEnabled) {
			return getters.enableTvProduct;
		} else {
			return getters.disableTvProduct;
		}
	},
	selectedFrihetTvProduct(state, getters) {
		if (getters.unsavedSelectedFrihetTvProduct) return getters.unsavedSelectedFrihetTvProduct;
		if (getters.isTvEnabled) {
			return getters.enableTvProduct;
		}
		return getters.disableTvProduct;
	},
	unsavedSelectedEquipments(state) {
		return state.unsavedSelectedEquipments;
	},
	requireWifiEquipment(state, getters) {
		return getters?.selectedFrihetBBSpeedProduct?.equipmentRequired || false;
	},
	requireTvEquipment(state, getters) {
		return getters?.selectedFrihetTvProduct?.equipmentRequired || false;
	},
	isEquipmentAdded(state) {
		return state.unsavedSelectedEquipments?.length ? true : false;
	},
	isRemovingTv(state, getters) {
		return (
			getters.unsavedSelectedFrihetTvProduct &&
			getters.unsavedSelectedFrihetTvProduct?.productId === getters.disableTvProduct?.productId
		);
	},
	isAddingTv(state, getters) {
		return (
			getters.unsavedSelectedFrihetTvProduct &&
			getters.unsavedSelectedFrihetTvProduct?.productId === getters.enableTvProduct?.productId
		);
	},
	getSelectedFrihetOrders(state, getters) {
		const orders = [];
		if (getters.unsavedSelectedFrihetTvProduct) {
			orders.push({
				offerId: getters.frihetTvOffer.offerId,
				mainProduct: {
					productId: getters.unsavedSelectedFrihetTvProduct.productId,
					addons: [],
				},
			});
		}
		if (getters.unsavedSelectedFrihetBBSpeedProduct) {
			orders.push({
				offerId: getters.frihetBBSpeedOffer.offerId,
				mainProduct: {
					productId: getters.unsavedSelectedFrihetBBSpeedProduct.productId,
					addons: [],
				},
			});
		}
		getters.unsavedSelectedEquipments.forEach((equipment) => {
			orders.forEach((order) => {
				if (
					order?.offerId === equipment?.linkedOfferId &&
					order?.mainProduct?.productId === equipment?.linkedProductId
				) {
					order.mainProduct.addons.push({
						productId: equipment.productId,
						numSelected: equipment.quantity,
					});
				}
			});
		});
		return orders;
	},
};
