import axios from "axios";

import { setupCache } from "axios-cache-interceptor";

export default function (baseURL) {
	const axiosConfig = {
		baseURL: "/api/self-service" + baseURL,
	};

	if (process.env.HTTPS && import.meta.server) {
		const https = require("https");
		axiosConfig.httpsAgent = new https.Agent({
			rejectUnauthorized: false,
		});
	}

	const api = axios.create(axiosConfig);

	if (import.meta.client) {
		setupCache(api, {
			maxAge: 15 * 60 * 1000, // 15 minutes,
			exclude: {
				query: false,
			},
			cacheTakeover: false, // TODO: Remove when Apigee fixes cors pragma issue
		});
	}

	api.interceptors.response.use(
		function (response) {
			return response.data;
		},
		(error) => {
			if (error?.config?.headers) delete error.config.headers["Authorization"];

			if (error?.constructor?.name === "AxiosError") {
				return Promise.reject({
					message: error.message,
					code: error.code,
					response: {
						status: error.response?.status,
						data: error.response?.data,
					},
					request: {
						url: error.config?.url,
						method: error.config?.method,
						data: error.config?.data,
					},
				});
			}

			return Promise.reject(error);
		},
	);
	return api;
}
