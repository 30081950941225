export default {
	state() {
		return {
			focusedIndex: 0,
			pageUriReference: "",
			isModalOpen: false,
		};
	},
	mutations: {
		setFocusedIndex(state, index) {
			state.focusedIndex = index;
		},
		setPageUriReference(state, value) {
			state.pageUriReference = value;
		},
		setIsModalOpen(state, value) {
			state.isModalOpen = value;
		},
	},
	getters: {
		focusedIndex(state) {
			return state.focusedIndex;
		},
		pageUriReference(state) {
			return state.pageUriReference;
		},
		isModalOpen(state) {
			return state.isModalOpen;
		},
	},
};

export const state = () => ({
	focusedIndex: 0,
	pageUriReference: "",
	isModalOpen: false,
});
export const actions = {};
export const mutations = {
	setFocusedIndex(state, index) {
		state.focusedIndex = index;
	},
	setPageUriReference(state, value) {
		state.pageUriReference = value;
	},
	setIsModalOpen(state, value) {
		state.isModalOpen = value;
	},
};
export const getters = {
	focusedIndex(state) {
		return state.focusedIndex;
	},
	pageUriReference(state) {
		return state.pageUriReference;
	},
	isModalOpen(state) {
		return state.isModalOpen;
	},
};
