import debtManagementV1 from "~/integrations/debt-management-v1";
import { v4 as uuid } from "uuid";

const getDefaultState = () => {
	return {
		isDebtFetched: false,
		isConsentFetched: false,
		debtError: undefined,
		consentError: undefined,
		isAlertsFetched: false,
		alerts: {},
		alertsError: {},
		debt: {},
		consent: {},
		isAllFetched: false,
	};
};

export const state = getDefaultState;
export const actions = {
	async fetchConsentAndAlerts({ state, dispatch, commit }) {
		try {
			await dispatch("fetchConsent");
			if (state.isConsentFetched && state.consent.active) {
				try {
					await dispatch("fetchAlerts");
				} catch (error) {
					commit("setAlertsError");
				}
			}
		} catch (error) {
			commit("setConsentError", error);
		} finally {
			commit("setAllFetched", true);
		}
	},
	async fetchDebts({ commit }) {
		try {
			const debt = await debtManagementV1.getDebt();
			debt.loans.forEach((element) => {
				element.creditLimit = (element.creditLimit / 100).toFixed(2).replace(".", ",");
				element.id = uuid();
			});
			commit("setDebt", debt);
			commit("setDebtFetched", true);
		} catch (error) {
			commit("setDebtError", error);
		}
	},
	async fetchConsent({ commit }) {
		try {
			const consent = await debtManagementV1.getConsent();
			commit("setConsent", consent);
			commit("setConsentFetched", true);
		} catch (error) {
			commit("setConsentError", error);
		}
	},
	async fetchAlerts({ commit }) {
		try {
			const alerts = await debtManagementV1.getAlerts();
			commit("setAlerts", alerts);
			commit("setAlertsFetched", true);
		} catch (error) {
			commit("setAlertsError", error);
		}
	},
	clearState({ commit }) {
		commit("resetState");
	},
};
export const mutations = {
	resetState(state) {
		Object.assign(state, getDefaultState());
	},
	setDebt(state, debt) {
		state.debt = debt;
	},
	setDebtError(state, data) {
		state.debtError = data;
	},
	setDebtFetched(state, data) {
		state.isDebtFetched = data;
	},
	setConsent(state, consent) {
		state.consent = consent;
	},
	setConsentError(state, data) {
		state.consentError = data;
	},
	setConsentFetched(state, data) {
		state.isConsentFetched = data;
	},
	setAllFetched(state, data) {
		state.isAllFetched = data;
	},
	setAlerts(state, data) {
		state.alerts = data;
	},
	setAlertsError(state, data) {
		state.alertsError = data;
	},
	setAlertsFetched(state, data) {
		state.isAlertsFetched = data;
	},
};
export const getters = {
	creditStatus(state) {
		if (!state.consent.active && state.consent.expiresAt === null) return 3;
		else if (!state.consent.active) return 2;
		else if (state.alerts.hasAlerts) return 1;
		else return 4;
	},
	creditById: (state) => (id) => {
		if (state.isDebtFetched) return state.debt.loans.find((el) => el.id === id);
	},
};
