import Axios from "axios";
// import authenticatedUserClient from "~/components/mitt-telenor/common/integrations/self-service-adapter";

export default () => {
	let url = "https://shoppingcart.web.test.apc.telenor.net/";
	if (import.meta.client) {
		if (
			window.location.host === "staging.telenor.no" ||
			window.location.host === "telenor.no" ||
			window.location.host === "www.telenor.no"
		)
			url = "https://cart.telenor.no";
	} else if (import.meta.server) {
		if (process.env.NODE_ENV === "production") {
			url = "https://cart.telenor.no";
		}
	}

	const axios = Axios.create({
		baseURL: url,
		withCredentials: true,
	});

	axios.interceptors.response.use((res) => res.data);

	// Append internal API URL to relative environment/browser path
	const systemClient = Axios.create({
		baseURL: url,
		withCredentials: true,
	});

	systemClient.interceptors.response.use((res) => res.data);

	// const userClient = authenticatedUserClient("/shopping-cart/v1");

	// Append internal API URL to relative environment/browser path
	const getBestClient = async () => {
		return axios; // Always use system user client for now
		/* 
    if (process.client) {
      if(window.location.hostname === "localhost")
        return axios;
      try {
        const user = await telenorid.getOrLoginUser();
        if (user && user.firstName !== "Gizmo")
          return userClient;
      } catch(err){
        console.log(err);
      }
    } else {
      if(process.env.NODE_ENV === "development")
        return axios;
    }

    return systemClient;
    */
	};

	const getCartContent = async () => {
		const api = await getBestClient();
		return api.get(`/`);
	};

	const addItem = async (item) => {
		const api = await getBestClient();
		return api.put(`/`, item);
	};

	const removeItem = async (itemId) => {
		const api = await getBestClient();
		return api.delete(`/${itemId}`);
	};

	const emptyCart = async () => {
		const api = await getBestClient();
		return api.delete("/");
	};

	return {
		getCartContent,
		removeItem,
		addItem,
		emptyCart,
	};
};
