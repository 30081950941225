import { _fetchData } from "~/helpers/store";
import { concatEqualProducts } from "~/components/broadband-consumer/FixedOrder/steps/GizmoFixedOrderSummary/FixedSummaryModel";
import fixedTermination from "~/integrations/fixed-termination";
import formatPrice from "~/helpers/formatting/formatPrice.js";

const terminationTypes = {
	ALL: "Internett og TV",
	TV: "TV",
	BB: "Internett",
};

const combinations = ["Internett og TV", "Internett", "TV"];

export const state = () => ({
	promise: null,
	data: null,
	error: null,
	cancellationReasons: null,
	loadingCancellationReasons: true,
	isCustomerMoving: false,
	analyticsReasonCode: null,
	terminationInfo: {
		reasonCode: "OTHER",
		terminationType: null,
		returnkitAddressType: "PERMANENT",
		terminationTime: null,
	},
});

export const actions = {
	async fetchTerminationValidation(context, options) {
		return _fetchData(
			() =>
				fixedTermination.validateTermination({
					...context.rootGetters?.["mittTelenor/fixed/fixedSubscriptionTermination/apiCompatibleOrder"],
				}),
			{
				context,
				force: !!options?.force,
				name: "termination",
			},
		);
	},
	async getCancellationReasons(context, { subscriptionId }) {
		const data = await fixedTermination.getTerminationReasons(subscriptionId);
		context.commit("setCancellationReasons", data);
		context.commit("setLoadingCancellationReasons", false);
	},
	async terminateSub(context, { mock = false }) {
		context.commit("fixedOrder/tracking/setTrackingType", "termination", { root: true });
		if (mock) {
			return {
				orderSuccessful: true,
				orderId: 12345,
			};
		}

		return await fixedTermination.terminateSubscription({
			...context.rootGetters?.["mittTelenor/fixed/fixedSubscriptionTermination/apiCompatibleOrder"],
		});
	},
	resetStore({ commit }) {
		commit("setReasonCode", null);
		commit("setTerminationType", null);
		commit("setTerminationDate", null);
		commit("setCancellationReasons", null);
		commit("setLoadingCancellationReasons", true);
		commit("setAnalyticsReasonCode", null);
	},
};

export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	setCancellationReasons(state, data) {
		state.cancellationReasons = data;
	},
	setLoadingCancellationReasons(state, loading) {
		state.loadingCancellationReasons = loading;
	},
	setTerminationType(state, type) {
		state.terminationInfo.terminationType = type;
	},
	setIsCustomerMoving(state, isCustomerMoving) {
		state.isCustomerMoving = isCustomerMoving;
	},
	setAnalyticsReasonCode(state, reason) {
		state.isCustomerMoving = reason === "MOVE";

		state.analyticsReasonCode = reason;
	},
	setTerminationDate(state, date) {
		state.terminationInfo.terminationTime = date;
	},
};

export const getters = {
	data(state) {
		return state?.data;
	},
	loading: (state) => (!state?.data || state.promise !== null) && !state.error,
	validationError: (state) => state.error,
	hasSaveOffers(state, getters, rootState, rootGetters) {
		return !!rootGetters["fixedOrder/input/offers"].length;
	},
	errorFetchingOffers(state, getters, rootState) {
		return !!rootState["fixedOrder"].input.error;
	},
	//getter to resolve flow in gizmo
	eligibleForSave(state, getters) {
		return (
			getters.hasSaveOffers &&
			!state.isCustomerMoving &&
			state.terminationInfo?.reasonCode !== "DEATH" &&
			!getters.errorFetchingOffers
		);
	},
	cancellationTypes: (state) => {
		return state.cancellationReasons
			?.map((reason) => ({
				label: terminationTypes[reason.terminationType],
				value: reason.terminationType,
			}))
			.filter((reason) => reason.label != null)
			.sort((reasonA, reasonB) => combinations.indexOf(reasonA.label) - combinations.indexOf(reasonB.label));
	},
	loadingCancellationReasons: (state) => state.loadingCancellationReasons,
	apiCompatibleOrder(state, getters, rootState) {
		return {
			...state.terminationInfo,
			subscriptionId: rootState["fixedOrder"].input.subscriptionId,
		};
	},
	productsToBeTerminated: (state) => {
		return state.data?.orderInformation?.offers[0]?.products?.reduce(concatEqualProducts, [])?.map((product) => ({
			name: product.productName,
			amount: product.numSelected + " stk",
			isMainProduct: product.isMainProduct,
		}));
	},
	//getter to resolve flow in gizmo
	isCustomerMoving: (state) => state.isCustomerMoving,
	minimumTerminationDate(state) {
		return state.cancellationReasons
			?.find((type) => type.terminationType === state.terminationInfo.terminationType)
			?.terminationReasons?.find((reason) => reason.type === state.terminationInfo.reasonCode)?.minimumTerminationDate;
	},
	analyticsReasonCode: (state) => state.analyticsReasonCode,
	totalTerminationCost: (state) => formatPrice(state?.data?.orderInformation?.totalTerminationCost?.amount),
};
