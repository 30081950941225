import apigeeIntegration from "./self-service-adapter";

const api = apigeeIntegration("/fixed-order/v2");
const apiV1 = apigeeIntegration("/fixed-order/v1");

const terminateSubscription = async (order) => api.post("/order/terminate", order);
const validateTermination = async (order) => api.post("/order/terminate/validator", order);
const getTerminationReasons = async (id) => api.get(`/order/terminate/${id}/offers`);

const validateReturnEquipment = async (subId, productId) =>
	apiV1.delete(`/subscriptions/${subId}/products/${productId}/validator`);
const terminateEquipment = async (subId, productId) => apiV1.delete(`/subscriptions/${subId}/products/${productId}`);

export default {
	terminateSubscription,
	validateTermination,
	getTerminationReasons,
	validateReturnEquipment,
	terminateEquipment,
};
