import apigeeIntegration from "./self-service-adapter";
const api = apigeeIntegration("/streamix/v1");

// Product Content Api
const fetchStreamingPlusProviderContent = async () => {
	return api.get(`/bff/productcontent`, { cache: { override: true } });
};

// Bundle Catalog Api
const fetchStreamingPlusBundleCatalog = async () => {
	return api.get(`/tenants/spnor/bundleCatalog`, { cache: { override: true } });
};

// Prices Api
const fetchStreamingPlusPrices = async () => {
	return api.get(`/bff/prices`, { cache: { override: true } });
};

// Facade Bundle Api
const fetchStreamingPlusSubscribedBundles = async () => {
	return api.get(`/me/bundles`, { cache: { override: true } });
};
const createStreamingPlusBundle = async ({ payLoad }) => {
	return api.post(`/me/bundles`, payLoad);
};
const updateStreamingPlusBundle = async ({ bundleId, payLoad }) => {
	return api.put(`/me/bundles/${bundleId}`, payLoad);
};
const validateStreamingPlusBundle = async ({ bundleId, payLoad }) => {
	return api.put(`/me/bundles/${bundleId}/validate`, payLoad);
};
const deleteStreamingPlusBundle = async ({ bundleId }) => {
	return api.delete(`/me/bundles/${bundleId}`);
};
const fetchStreamingPlusUpdateBundlePending = async ({ bundleId }) => {
	return api.get(`/me/bundles/${bundleId}/update-pending`, { cache: { override: true, timeout: 120000 } });
};

// Facade Third Party Status Api
const fetchStreamingPlusThirdPartyStatus = async ({ bundleId, providerId }) => {
	return api.get(`/me/bundles/${bundleId}/status/providers/${providerId}`, { cache: { override: true } });
};

// Facade Account Api
const fetchStreamingPlusAccount = async () => {
	return api.get(`/me`, { cache: { override: true } });
};
const createStreamingPlusAccount = async () => {
	return api.post(`/me`);
};
const deleteStreamingPlusAccount = async () => {
	return api.delete(`/me`);
};

export default {
	fetchStreamingPlusAccount,
	fetchStreamingPlusBundleCatalog,
	createStreamingPlusAccount,
	fetchStreamingPlusSubscribedBundles,
	fetchStreamingPlusProviderContent,
	createStreamingPlusBundle,
	updateStreamingPlusBundle,
	fetchStreamingPlusThirdPartyStatus,
	validateStreamingPlusBundle,
	deleteStreamingPlusBundle,
	deleteStreamingPlusAccount,
	fetchStreamingPlusUpdateBundlePending,
	fetchStreamingPlusPrices,
};
