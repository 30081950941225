import Ajv from "ajv";
import schema from "./shopping-cart-item.schema.json";
import { v4 as uuid } from "uuid";

const ajv = new Ajv({ allErrors: true });
const validator = ajv.compile(schema);

export default (type, data, metadata, id = uuid(), subItems = [], overrideSubItemId) => {
	const constructedModel = {
		type,
		data,
		metadata,
		subItems,
		id,
		overrideSubItemId,
	};

	validator(constructedModel);

	if (validator.errors) {
		throw new Error(JSON.stringify(validator.errors));
	}

	return constructedModel;
};
