import systemUserIntegration from "~/integrations/self-service-system-adapter";

const api = systemUserIntegration("/ecommerce-shipping/v1/provider");

// Modino pickup address
const originAddress = {
	streetName: "Trondheimsveien",
	streetNumber: "183",
	postalCode: "2020",
	city: "Skedsmokorset",
	country: "Norway",
};

const porterBuddy = {
	availability: async (data) => {
		try {
			const delivery = {
				originAddress,
				destinationAddress: {
					streetName: data.streetName,
					streetNumber: data.streetNumber,
					postalCode: data.postalCode,
					city: data.postalPlace,
					country: "Norway",
				},
				products: ["delivery"],
				parcels: [
					{
						description: "Varer fra Telenor Nettbutikk",
						weightGrams: 1000,
					},
				],
				items: [],
			};

			return await api.post("porterbuddy/availability", delivery);
		} catch (e) {
			console.error(e);
		}
	},
};

export default {
	porterBuddy,
};
