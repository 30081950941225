import { pickBy, uniqBy } from "~/helpers/object-utility.js";

export const isPhone = (item) =>
	item.type === "hardware" &&
	(item?.metadata?.category?.toLowerCase() === "mobiltelefoner" ||
		item?.metadata?.category?.toLowerCase() === "god som ny");
export const isDevice = (item) =>
	item.type === "hardware" &&
	["mobiltelefoner", "nettbrett", "god som ny", "smartklokker"].includes(item?.metadata?.category?.toLowerCase());
export const isWatch = (item) => item.type === "hardware" && item?.metadata?.category?.toLowerCase() === "smartklokker";
export const isPhoneOrWatch = (item) => isPhone(item) || isWatch(item);
export const productIsEligibleForSwap = (item) => {
	return isEligibleForSwap(item?.metadata?.category || item?.category || item?.name);
};
export const productIsEligibleForDownPayment = (item) => {
	return isEligibleForDownpayment(item?.metadata?.category || item?.category || item?.name);
};
export const isEligibleForSwap = (category) => {
	const categoriesThatCanOrderSwap = ["mobiltelefoner", "nettbrett", "mobil", "god som ny"];
	return categoriesThatCanOrderSwap.includes(category?.toLowerCase());
};
export const isEligibleForDownpayment = (category) => {
	const categoriesThatCanOrderNedbetaling = ["smartklokker"];
	return categoriesThatCanOrderNedbetaling.includes(category?.toLowerCase());
};
export const isEligibleForSubscription = (item) => {
	const productThatCanHaveSubscription = ["mobiltelefoner", "nettbrett", "mobil", "god som ny", "smartklokker"];
	return productThatCanHaveSubscription.includes(
		(item?.metadata?.category || item?.category || item?.name)?.toLowerCase(),
	);
};
export const getValidatedSubscriptionPrice = (subscriptions, subscriptionItems) => {
	return subscriptions?.find(
		(subscription) =>
			subscription?.msisdn ===
			subscriptionItems
				?.find((item) => item.type === "msisdn")
				?.metadata?.msisdnDetails?.msisdn?.replace(/^47(.{8,})$/, "$1"),
	)?.newPriceFull;
};

const getMetadataPrice = (terminal, isVatExempt = false) => {
	const memberPrice = terminal.metadata?.memberPrice?.prices ? terminal.metadata?.memberPrice : undefined;
	const hasSubscription = terminal.subItems?.some((subItem) => subItem.type === "subscription");

	if (isVatExempt) {
		if (memberPrice !== undefined && hasSubscription) {
			return memberPrice.prices.withoutVat.memberPrice;
		} else return terminal?.metadata?.salePriceWithoutVat || terminal?.metadata?.priceWithoutVat;
	} else {
		if (memberPrice !== undefined && hasSubscription) {
			return memberPrice.prices?.withVat?.memberPrice;
		} else return terminal?.metadata?.salePrice || terminal?.metadata?.price;
	}
};

const getStandaloneProducts = (terminals, sendingTerminalOnSubscriptionOrder, paymentRef) => {
	let standaloneProducts = [];
	const isVatExempt = !!terminals[0]?.metadata?.isVatExempt;

	for (const item of terminals) {
		const product = {
			hardwareProductId: item?.data?.id,
			price: getMetadataPrice(item, isVatExempt),
			quantity: 1,
		};
		if (!sendingTerminalOnSubscriptionOrder && isEligibleForSubscription(item)) {
			standaloneProducts.push(product);
		} else if (isAccessory(item)) {
			const existingProductIndex = standaloneProducts.findIndex(
				(standaloneProduct) => standaloneProduct.hardwareProductId === item?.data?.id,
			);
			const existingItem = standaloneProducts[existingProductIndex];
			if (existingProductIndex === -1) standaloneProducts.push(product);
			else {
				standaloneProducts[existingProductIndex] = { ...existingItem, quantity: existingItem?.quantity + 1 };
			}
		}
		for (const subItem of uniqBy(item.subItems, "data.id")) {
			if (isAccessory(subItem)) {
				if (subItem?.metadata?.preConfigId !== undefined && paymentRef !== undefined) {
					standaloneProducts.push({
						hardwareProductId: subItem.metadata.preConfigId,
						price: 0.01,
						quantity: 1,
					});
				}
				standaloneProducts.push({
					hardwareProductId: subItem.data.id,
					price: getMetadataPrice(subItem, isVatExempt),
					quantity: item?.subItems?.filter((item) => item?.data?.id === subItem?.data?.id)?.length,
				});
			}
		}
	}
	return standaloneProducts;
};

const getDevices = (terminals) => {
	const hardwareTerminals = terminals?.filter((item) => item?.type === "hardware");
	const isVatExempt = hardwareTerminals?.find((hwTerm) => !!hwTerm?.metadata?.deliveryDetails?.postalCode).metadata
		?.isVatExempt;

	const getHardwareObject = (hardwareItem) =>  {
		return pickBy({
			hardwareProductId: hardwareItem?.data?.id,
			insuranceProductId: getInsuranceProductId(hardwareItem),
			...getSwapOrDownPaymentProductId(hardwareItem),
			price: getMetadataPrice(hardwareItem, isVatExempt),
		});
	};

	const getSwapOrDownPaymentProductId = (hardwareItem) => {
		if ( hardwareItem?.metadata?.swap?.categories?.includes("FLEXIBLE") &&  hardwareItem?.metadata?.downPayment ) {
			return {
					downPaymentProductId: hardwareItem?.metadata?.swap?.agreementProductId.toString(),
				}; 
		} else {
			return {
				swapProductId: hardwareItem?.metadata?.swap?.agreementProductId,
			};
		}
	};

	const getInsuranceProductId = (hardwareItem) => {
		const isSwap = !!hardwareItem?.metadata?.swap?.agreementProductId;
		const getSwapInsuranceId = () => {
			const isMobile = hardwareItem.metadata.category === "Mobiltelefoner";
			const isTablet = hardwareItem.metadata.category === "Nettbrett";
			const hasInsurance = !!hardwareItem?.subItems?.find((item) => item?.type === "agreement")?.data?.id;

			const includedSwapInsuranceId = () => (isMobile ? "76335" : isTablet ? "54423" : undefined);

			return hasInsurance
				? hardwareItem?.subItems?.find((item) => item?.type === "agreement")?.data?.id
				: includedSwapInsuranceId();
		};

		const getInsuranceId = () => hardwareItem?.subItems?.find((item) => item?.type === "agreement")?.data?.id;

		return isSwap ? getSwapInsuranceId() : getInsuranceId();
	};

	// partially hardcoded return object
	const getSwapOrderObject = (swapOrderId) => {
		if (!swapOrderId) {
			return undefined;
		}

		const isProductionEnvironment = !(
			window.$nuxt?.$store?.state?.host?.split("telenor.no")[0]?.indexOf("test") > -1 ||
			window.$nuxt?.$store?.state?.host?.indexOf("localhost") > -1 ||
			window.location?.hostname?.indexOf("test") > -1 ||
			window.location?.hostname?.indexOf("localhost") > -1 ||
			window.location?.hostname?.indexOf("pippin") > -1
		);

		const productInProduction = {
			swapOrderId,
			hardwareProductId: "646b24274cedfd0001529ba6",
			price: 1,
		};

		const productInTest = {
			swapOrderId,
			hardwareProductId: "6391aea4cff47e0001fa270d",
			price: 1,
		};

		return isProductionEnvironment ? productInProduction : productInTest;
	};

	return hardwareTerminals.reduce(
		(acc, curr) =>
			[
				...acc,
				getHardwareObject(curr),
				getSwapOrderObject(
					curr?.metadata?.swapOrderId || curr?.metadata?.swap?.tradein?.gradingData?.existingSwapOrderId,
				),
			].filter((x) => x),
		[],
	);
};

const getCustomerReferences = (terminals) => {
	const msisdnDetails = terminals?.[0]?.subItems?.find((item) => item?.type === "msisdn")?.metadata?.msisdnDetails;
	const kurtId = msisdnDetails?.user?.kurtId || msisdnDetails?.user?.customerIds?.kurtId || msisdnDetails?.user?.kurt;
	return kurtId ? { kurtIdUser: `${kurtId}` } : undefined;
};

const getNewSubscriptionDataStructure = (terminals) => {
	const subscriptionProductId = getSubscriptionProductId(terminals);

	if (!(isOrderingNewSubscription(terminals) && subscriptionProductId)) {
		return undefined;
	} else if (subscriptionProductId?.length > 30) {
		// treating the symptoms - should actually be a change order where exisiting subscription is kept.
		return undefined;
	}

	const msisdnDetails = terminals?.[0]?.subItems?.find((item) => item?.type === "msisdn")?.metadata?.msisdnDetails;
	const msisdn = removeCountryCode(msisdnDetails?.msisdn);
	const customerReferences = getCustomerReferences(terminals);
	const mainSimcardProduct = { formFactor: "MULTI_SIM", simcardDeliveryAddressType: "USER_RESIDENCE" };
	return [
		pickBy({
			msisdn,
			subscriptionProductId,
			customerReferences,
			devices: getDevices(terminals),
			mainSimcardProduct,
			waitForEquipment: true,
		}),
	];
};

const getSubscriptionProductId = (terminals) =>
	terminals?.[0]?.subItems?.find((item) => item?.type === "subscription")?.data?.id;

const getImportSubscriptionDataStructure = (terminals) => {
	const subscriptionProductId = getSubscriptionProductId(terminals);

	if (!(isImportingSubscription(terminals) && subscriptionProductId)) {
		return undefined;
	}

	const msisdnDetails = terminals?.[0]?.subItems?.find((item) => item?.type === "msisdn")?.metadata?.msisdnDetails;
	const msisdn = removeCountryCode(msisdnDetails?.msisdn);
	const customerReferences = getCustomerReferences(terminals);
	const mainSimcardProduct = { formFactor: "MULTI_SIM", simcardDeliveryAddressType: "USER_RESIDENCE" };
	return [
		pickBy({
			subscriptionProductId,
			customerReferences,
			importInfo: {
				importDateAndTime: new Date(),
				autoCorrectImportDateAndTimeIfInvalid: true,
			},
			devices: getDevices(terminals),
			mainSimcardProduct,
			msisdn,
			waitForEquipment: true,
		}),
	];
};

const removeCountryCode = (number) => {
	if (!number) return undefined;
	if (number?.substring(0, 4) === "0047") {
		number = number.substring(4);
	}
	if (number?.substring(0, 3) === "+47") {
		number = number.substring(3);
	}
	if (number?.substring(0, 2) === "47" && number.length === 10) {
		number = number.substring(2);
	}
	return number;
};

const getChangeSubscriptionDataStructure = (terminals) => {
	if (!isChangingExistingSubscription(terminals)) return undefined;

	const changeSubscription = terminals?.[0]?.subItems?.find((item) => item?.type === "msisdn");
	const msisdn = removeCountryCode(changeSubscription?.metadata?.msisdnDetails?.msisdn);
	const selectedSubscriptionExceptNew = terminals?.[0]?.subItems?.find(
		(item) => item?.type === "subscription" && !item?.metadata?.existingSubscription,
	);
	const subscriptionProductId = selectedSubscriptionExceptNew?.data?.id;
	const addonProductsToAdd = selectedSubscriptionExceptNew?.subItems?.filter((subItem) => !subItem?.removed);
	const addonProductsToRemove = selectedSubscriptionExceptNew?.subItems?.filter((subItem) => subItem?.removed);
	return [
		pickBy({
			msisdn,
			addonProductsToAdd,
			addonProductsToRemove,
			subscriptionProductId,
			devices: getDevices(terminals),
			waitForEquipment: true,
		}),
	];
};

const getCorrectPaymentValue = (value) => {
	switch (value) {
		case "DEBIT_CARD":
			return "NETS";
		case "CARD":
			return "NETS";
		case "CREDIT_CARD":
			return "NETS";
		case "VIPPS":
			return "NETS";
		default:
			return value;
	}
};

export const isAccessory = (item) => {
	const categoriesCharacterizedAsAccessory = ["Lyd", "Tilbehør", "Ruter"];
	return item?.type === "hardware" && categoriesCharacterizedAsAccessory.includes(item?.metadata?.category);
};

const isSendingTerminalOnSubscriptionOrder = (terminals) => {
	return (
		isOrderingNewSubscription(terminals) ||
		isImportingSubscription(terminals) ||
		isChangingExistingSubscription(terminals)
	);
};

const isChangingExistingSubscription = (terminals) => {
	return terminals?.some((terminal) =>
		terminal?.subItems.some(
			(item) =>
				item?.type === "msisdn" &&
				item?.metadata?.msisdnDetails?.action === "KEEP_NUMBER" &&
				item?.metadata?.msisdnDetails?.accountId,
		),
	);
};

const isOrderingNewSubscription = (terminals) => {
	const notChangingOrImportingSubscription = !(
		isChangingExistingSubscription(terminals) || isImportingSubscription(terminals)
	);
	return (
		notChangingOrImportingSubscription &&
		terminals?.some(
			(terminal) =>
				terminal?.subItems.some((item) => item?.type === "subscription") || terminal?.type === "subscription",
		)
	);
};

const isImportingSubscription = (terminals) => {
	return terminals?.some((terminal) =>
		terminal?.subItems.some(
			(item) =>
				item?.type === "msisdn" &&
				item?.metadata?.msisdnDetails?.action === "KEEP_NUMBER" &&
				!item?.metadata?.msisdnDetails?.accountId,
		),
	);
};

export const oneTimePriceIsZero = (terminals) => {
	const sendingTerminalOnSubscriptionOrder = isSendingTerminalOnSubscriptionOrder(terminals);
	const standaloneProducts = getStandaloneProducts(terminals, sendingTerminalOnSubscriptionOrder);
	const phoneOrderedWithSubscription = getDevices(terminals);
	const getPriceExceptForSwap = (item) => (item?.swapProductId || item?.swapOrderId ? 0 : item?.price);
	return (
		[...phoneOrderedWithSubscription, ...standaloneProducts]?.reduce(
			(acc, curr) => acc + getPriceExceptForSwap(curr),
			0,
		) === 0
	);
};

const getPaymentInfo = (terminals, paymentRef) => {
	const type = getCorrectPaymentValue(terminals?.[0]?.metadata?.payment?.method?.type) || "NETS";
	const externalPaymentReference = terminals?.[0]?.metadata?.payment?.method?.externalPaymentReference || paymentRef;
	return externalPaymentReference === "placeholder-price-is-zero" && type !== "INSTALLMENTS"
		? { method: { type: "INVOICE" } }
		: { method: pickBy({ type, externalPaymentReference }) };
};

const getPersonals = (contactDetails) => {
	const phoneNumber = contactDetails?.data?.phoneNumber?.replace(/\s/g, "");
	const phoneNumberWithCountryCode = phoneNumber?.length === 8 ? `47${phoneNumber}` : phoneNumber;
	return {
		email: contactDetails?.data?.email,
		phoneNumber: phoneNumberWithCountryCode,
	};
};

const getHardwareOrder = (terminals, sendingTerminalOnSubscriptionOrder, paymentRef) => {
	const payment = getPaymentInfo(terminals, paymentRef);
	const onlyAccessoryProducts = terminals?.length && !terminals?.some((item) => isEligibleForSubscription(item));
	const hasAccessories = terminals?.[0]?.subItems?.some(isAccessory) || onlyAccessoryProducts;
	const standaloneProducts =
		sendingTerminalOnSubscriptionOrder && !hasAccessories
			? undefined
			: getStandaloneProducts(terminals, sendingTerminalOnSubscriptionOrder, paymentRef);
	const consent = terminals?.[0]?.metadata?.consent || null;
	return pickBy({
		standaloneProducts,
		delivery: {
			addressId: terminals?.[0]?.metadata?.deliveryDetails.addressId,
			deliveryService: terminals?.[0]?.metadata?.deliveryDetails.deliveryService,
			deliveryToken:
				terminals?.[0]?.metadata?.deliveryDetails.deliveryService === "PORTERBUDDY"
					? terminals?.[0]?.metadata?.deliveryDetails.deliveryWindow?.token
					: undefined,
		},
		payment,
		consent,
	});
};

export const getOrderStructure = (terminals, paymentRef) => {
	const purchasableItems = terminals?.filter((item) => item.type !== "contact-information");
	const newSubscriptions = getNewSubscriptionDataStructure(purchasableItems);
	const importSubscriptions = getImportSubscriptionDataStructure(purchasableItems);
	const changeSubscriptions = getChangeSubscriptionDataStructure(purchasableItems);
	const personals = getPersonals(terminals?.find((item) => item.type === "contact-information"));

	const sendingTerminalOnSubscriptionOrder = newSubscriptions || importSubscriptions || changeSubscriptions;
	const hardwareOrder = getHardwareOrder(purchasableItems, sendingTerminalOnSubscriptionOrder, paymentRef);

	return pickBy({
		hardwareOrder,
		creditEvaluation: { acceptManualCreditEvaluation: true },
		newSubscriptions,
		importSubscriptions,
		changeSubscriptions,
		personals,
	});
};
