import fixedSubscription from "~/integrations/fixed-subscription";
import { _fetchData } from "~/helpers/store";

export default {
	state() {
		return {
			promise: null,
			data: null,
			error: null,
			fetchedSubId: null,
		};
	},
	actions: {
		async fetchData(context, options) {
			return _fetchData(() => fixedSubscription.getAddonOffers(context.rootState["fixedOrder"].input.subscriptionId), {
				context,
				force: !!options.force,
				name: "subscription",
			});
		},
	},
	mutations: {
		setData(state, data) {
			state.data = data;
		},
		setPromise(state, promise) {
			state.promise = promise;
		},
		setError(state, error) {
			state.error = error;
		},
		setFetchedSubId(state, subId) {
			state.fetchedSubId = subId;
		},
	},
	getters: {
		offers(state) {
			return state?.data?.filter(
				(addon) => addon.productType?.includes("NY_ALLTID_WIFI_UTSTYR") || addon.productType?.includes("EQUIPMENT"),
			);
		},
		loading(state) {
			return !state?.data || !!state.promise;
		},
		dataFetched: (state) => !!state.data,
		installationOffers(state) {
			return state?.data?.filter((addon) => addon.productType?.includes("INSTALLATION"));
		},
		lastFetchedSubId: (state) => state.fetchedSubId,
	},
};

export const state = () => ({
	promise: null,
	data: null,
	error: null,
	fetchedSubId: null,
});
export const actions = {
	async fetchData(context, options) {
		return _fetchData(() => fixedSubscription.getAddonOffers(context.rootState["fixedOrder"].input.subscriptionId), {
			context,
			force: !!options.force,
			name: "subscription",
		});
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	setFetchedSubId(state, subId) {
		state.fetchedSubId = subId;
	},
};
export const getters = {
	offers(state) {
		return state?.data?.filter(
			(addon) => addon.productType?.includes("NY_ALLTID_WIFI_UTSTYR") || addon.productType?.includes("EQUIPMENT"),
		);
	},
	loading(state) {
		return !state?.data || !!state.promise;
	},
	dataFetched: (state) => !!state.data,
	installationOffers(state) {
		return state?.data?.filter((addon) => addon.productType?.includes("INSTALLATION"));
	},
	lastFetchedSubId: (state) => state.fetchedSubId,
};
