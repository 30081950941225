/**
 * Hashes text using the specified algorithm and return as hex string
 * @param {String} text
 * @param {String} [algorithm=SHA-256]
 * @returns {Promise<string>} Promise represents hex string of hashed text
 */
export default async (text, algorithm = "SHA-256") => {
	if (typeof text === "undefined" || text === "") return undefined;

	const textUint8 = new TextEncoder().encode(text); // encode as (utf-8) Uint8Array
	const hashBuffer = await crypto.subtle.digest(algorithm, textUint8); // hash the message
	const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
	return hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
};
