import systemUserIntegration from "./self-service-system-adapter";
const api = systemUserIntegration("/ecommerce-products/v3");

const getStoresWithProduct = async (hardwareProductId, latitude, longitude) => {
	if ((!latitude || latitude === 0.0) && (!longitude || longitude === 0.0)) {
		return await api.get(`/products/${hardwareProductId}/inventory/collect-at-store`);
	} else {
		return await api.get(
			`/products/${hardwareProductId}/inventory/collect-at-store?latitude=${latitude}&longitude=${longitude}`,
		);
	}
};

const reserveProduct = async (data) => await api.post("/reservations", data);

const checkAvailability = async (hardwareProductId) =>
	await api.get(`/products/${hardwareProductId}/dealers/availability`);

export default {
	getStoresWithProduct,
	reserveProduct,
	checkAvailability,
};
