export default defineNuxtPlugin(({ vueApp }) => {
	// matchers :: Array String -> Array RegExp
	const matchers = (variables) =>
		variables.map((variable) => new RegExp(`<span class="variable"> ${variable} </span>`, "g"));

	vueApp.config.globalProperties.$dynamicContent = (mapping) => (text) =>
		Object.keys(mapping).reduce(
			(acc, curr, currentIndex) =>
				acc?.replace(matchers(Object.keys(mapping))[currentIndex], Object.values(mapping)[currentIndex]),
			text,
		);

	/*vueApp.prototype.$dynamicContent = (mapping) => (text) => Object.keys(mapping).reduce(
		(acc, curr, currentIndex) =>
			acc?.replace(matchers(Object.keys(mapping))[currentIndex], Object.values(mapping)[currentIndex]),
		text
	)*/
});
