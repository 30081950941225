export const state = () => {
	return {
		accessToken: null,
		loading: false,
		error: null,
	};
};

export const mutations = {
	setAccessToken(state, accessToken) {
		state.accessToken = accessToken;
	},
	setLoading(state, loading) {
		state.loading = loading;
	},
	setError(state, error) {
		state.error = error;
	},
};
