// Excluded routes for dynamic-page-middleware.js
export const dynamicPageExclusion = [
	{ name: "sok" },
	{ name: "loginCallback" },
	{ name: "loginCallbackTweBox" },
	{ name: "silentLoginCallback" },
	{ name: "logout" },
	{ name: "componentTest" },
	{ name: "logoutCallback" },
	{ name: "login" },
	{ name: "checkout" },
	{ name: "esim-microsoft" },
	{ name: "esim-apple-watch" },
	{ name: "popupLoginCallback" },
	{ name: "reCaptch" },
	{ path: "twe-box" },
	{ path: "loginCallbackTweBox" },
	{ path: "/privat/minesider/mtw/esim/ms", exact: false },
];

// Excluded routes for tracking-plugin.js
export const trackingPluginExclusion = [...dynamicPageExclusion];

// Excluded routes for sso-login.client.js
export const ssoLoginPluginExclusion = [
	{ name: "login" },
	{ name: "logout" },
	{ name: "esim-apple-watch" },
	{ name: "esim-microsoft" },
	{ path: "/privat/minesider/mtw/esim/ms", exact: false },
	{ path: "/mitt-telenor/tv-internett/twe/innholds-velger/twe-box/", exact: false },
];

export const routeIsExcluded = (route, excludedRoutes, exactOverride = undefined) =>
	excludedRoutes.some((er) => {
		const routeName = route.name?.toLowerCase();
		const routePath = route.path?.toLowerCase();
		const excludedName = er.name?.toLowerCase();
		const excludedPath = er.path?.toLowerCase();

		const exactMatch = routeName === excludedName || routePath === excludedPath;
		const partialMatch = routeName?.indexOf(excludedName) > -1 || routePath?.indexOf(excludedPath) > -1;

		// Perform an exact or partial match if the exactOverride param is defined
		if (exactOverride != null) return exactOverride ? exactMatch : partialMatch;

		// Default to check for an exact match unless specified otherwise
		if (er.exact == null) er.exact = true;

		return er.exact ? exactMatch : partialMatch;
	});

export default {
	/**
	 * @param {object} route The route object.
	 * @param {[{[name]: string, [path]: string, [exact]: boolean}]} excludedRoutes An array of excluded routes with `name` and/or `path` and `exact` (default `true`) properties.
	 * @param {boolean} [exactOverride] Overrides exact match for all routes. Works using both `true` or `false`.
	 * @returns {boolean} `true` if either `route.name` or `route.path` matches the `name` or `path` properties
	 * respectively in the excludedRoutes array. Otherwise, `false`.
	 */
	routeIsExcluded,
	dynamicPageExclusion,
	trackingPluginExclusion,
	ssoLoginPluginExclusion,
};
