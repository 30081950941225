const updateProductTitle = (title) => {
	if (!title) return "";
	return (
		title
			.replace(/,/g, "")
			.replace(/\w\S*/g, (word) =>
				word.toLowerCase() !== "gb" ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toUpperCase(),
			)
			// /\b(\d+)([gG])\b/g) is a regex pattern that matches any number followed by a g or G Ex. 5g or 5G
			.replace(/\b(\d+)([gG])\b/g, (match, number, letter) => number + letter.toUpperCase())
			// /\b(\d+)\s*gb\b/gi) is a regex pattern that matches any number followed by a gb or GB Ex. 64 gb, 128GB, 256 gb, 512GB
			.replace(/(\d+)\s*gb/gi, (match, size) => size.toUpperCase() + "GB")
	);
};

const updateProductGradingValue = (gradingValue) => {
	if (!gradingValue) return null;
	return gradingValue.replace(/t/gi, "").toUpperCase();
};

const mapProduct = (
	productCategory,
	productCleanName,
	productId,
	productPrice,
	productBrand,
	productVariant,
	productStorageSize,
	productColor,
	productSubCategory = null,
	productNumberType = null,
	productPaymentMethod = null,
	productGradingValue = null,
	signInMethodUsed = null,
	hasMemberPrice = false,
	IsPreAttached = false,
	chosenDeliveryService,
) => {
	let product = `${productCategory};${productCleanName} (${productId});1;${productPrice};;`;
	if (productSubCategory) product += `eVar28=${productSubCategory}`;
	if (productBrand) product += productSubCategory ? `|eVar29=${productBrand}` : `eVar29=${productBrand}`;
	if (productVariant)
		product += !productSubCategory && !productBrand ? `eVar30=${productVariant}` : `|eVar30=${productVariant}`;
	if (productStorageSize) product += `|eVar31=${productStorageSize}`;
	if (productColor) product += `|eVar32=${productColor}`;
	if (productNumberType) product += `|eVar33=${productNumberType}`;
	if (productPaymentMethod) product += `|eVar34=${productPaymentMethod}`;
	if (productGradingValue) product += `|eVar121=${productGradingValue}`;
	if (signInMethodUsed) product += `|eVar123=${signInMethodUsed}`;
	if (hasMemberPrice) product += "|eVar126=true";
	if (IsPreAttached) product += `|eVar128=${IsPreAttached}`;
	if (chosenDeliveryService) product += `|eVar132=${chosenDeliveryService}`;

	return product;
};

const getSubCategory = (categories) => {
	if (!categories || categories.length < 2) return null;

	const subCategory = categories.find(
		(category) => category.parentId === categories.find((cat) => cat.id === category.parentId)?.id,
	);

	return subCategory?.name;
};

const getSignInMethodUsed = (customer) => {
	if (customer?.acr?.includes("aal.3") && customer?.amr === undefined) return "BankID";
	else return "TelenorID";
};

const getPaymentType = (item) => {
	if (item.metadata?.fullPrice) {
		return item.subItems?.some((subItem) => subItem.type === "subscription") ? "Fullpris+Abo" : "Fullpris";
	} else if (item.metadata?.downPayment) {
		return "Nedbetaling";
	} else if (!item.metadata?.downPayment && !item.metadata?.fullPrice && item.metadata?.swap?.agreementProductId) {
		return item.metadata?.swap?.tradein?.gradingData?.deviceReturnId ? "SWAPaSWAP" : "SWAP";
	}

	return null;
};

const getNumberType = (terminals) => {
	if (isImportingSubscription(terminals)) return "PORT_NUMBER";

	if (isOrderingNewSubscription(terminals)) return "NEW_NUMBER";

	if (isChangingExistingSubscription(terminals)) return "KEEP_NUMBER";

	return null;
};

const isImportingSubscription = (terminal) =>
	terminal?.subItems?.some(
		(item) =>
			item?.type === "msisdn" &&
			item?.metadata?.msisdnDetails?.action === "KEEP_NUMBER" &&
			!item?.metadata?.msisdnDetails?.accountId,
	);

const isOrderingNewSubscription = (terminal) => {
	const notChangingOrImportingSubscription = !(
		isChangingExistingSubscription(terminal) || isImportingSubscription(terminal)
	);
	return (
		(notChangingOrImportingSubscription && terminal.subItems?.some((item) => item?.type === "subscription")) ||
		terminal?.type === "subscription"
	);
};

const isChangingExistingSubscription = (terminal) =>
	terminal?.subItems?.some(
		(item) =>
			item?.type === "msisdn" &&
			item?.metadata?.msisdnDetails?.action === "KEEP_NUMBER" &&
			item?.metadata?.msisdnDetails?.accountId,
	);
export {
	updateProductTitle,
	mapProduct,
	getSubCategory,
	getPaymentType,
	getNumberType,
	updateProductGradingValue,
	getSignInMethodUsed,
};
