import customersIntegration from "~/integrations/customers";
import hash from "@/helpers/crypto/hash";
import { _fetchData } from "~/helpers/store";

const cleanPhoneNumber = (phoneNumber) => {
	try {
		// Remove non digits, e.g. +47 999 88 777 -> 4799988777
		let cleanPhoneNumber = phoneNumber.replace(/\D+/g, "");

		// Remove potential leading zeroes (e.g. 0047)
		cleanPhoneNumber = parseInt(cleanPhoneNumber, 10).toString();

		// Add country code if missing
		return cleanPhoneNumber.length === 8 ? `47${cleanPhoneNumber}` : cleanPhoneNumber;
	} catch (e) {
		console.error(e);
		return undefined;
	}
};

const hashedData = async (data) => {
	if (data.emailAddress) data.hashed_emailAddress = await hash(data.emailAddress);

	if (data.phoneNumber) data.hashed_phoneNumber = await hash(cleanPhoneNumber(data.phoneNumber));

	return data;
};

export const state = () => ({
	loading: false,
	data: null,
	customerPromise: null,
	error: null, //Used by _fetch, contains error response
	promise: null,
	failedCalls: [], // contains strings with the failed calls, to easier differentiate what call failed
});

export const actions = {
	async fetchData(context, force = false) {
		return _fetchData(() => customersIntegration.getContactDetails(), {
			context,
			force,
			name: "contactDetails",
		});
	},
	async setContactDetails({ state, commit }) {
		if (!state?.loading && !state.customerPromise) {
			commit("setContactDetailsLoading", true);
			commit("clearFailedCall", "fetchingContactDetails");
			const promise = customersIntegration.getContactDetails();
			commit("customerPromise", promise);
			return promise
				.then(async (contactDetails) => {
					contactDetails = { ...contactDetails, ...(await hashedData(contactDetails)) };
					commit("setContactDetails", contactDetails);
					return contactDetails;
				})
				.catch((err) => {
					console.error(err.message);
					commit("setFailedCall", "fetchingContactDetails");
					return {
						message: "Ops! Vi klarte dessverre ikke å hente din kundeinformasjon",
						error: true,
					};
				})
				.finally(() => {
					commit("setContactDetailsLoading", false);
					commit("customerPromise", null);
				});
		} else {
			return state.customerPromise;
		}
	},
	async updateContactDetails({ commit }, contactDetails) {
		commit("setContactDetailsLoading", true);
		commit("clearFailedCall", "changingContactDetails");

		// Remove blank entries to prevent bad request
		//eslint-disable-next-line no-unused-vars
		contactDetails = Object.fromEntries(Object.entries(contactDetails).filter(([_, v]) => v != null));

		return customersIntegration
			.updateContactDetails(contactDetails)
			.then(async (contactDetails) => {
				contactDetails = { ...contactDetails, ...(await hashedData(contactDetails)) };
				commit("setContactDetails", contactDetails);
				return {
					message: "Din kundeinformasjon er oppdatert",
					success: true,
				};
			})
			.catch((err) => {
				console.error(err.message);
				commit("setFailedCall", "changingContactDetails");
				return {
					message: "Ops! Vi klarte dessverre ikke å oppdatere din kundeinformasjon",
					success: false,
				};
			})
			.finally(() => commit("setContactDetailsLoading", false));
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	setContactDetailsLoading(state, loading) {
		state.loading = loading;
	},
	setContactDetails(state, contactDetails) {
		state.data = contactDetails;
	},
	updateContactDetails(state, contactDetails) {
		state.data = { ...state.data, ...contactDetails };
	},
	setEmailAddress(state, emailAddress) {
		state.data.emailAddress = emailAddress;
	},
	setPhoneNumber(state, phoneNumber) {
		state.data.phoneNumber = phoneNumber;
	},
	customerPromise(state, promise) {
		state.customerPromise = promise;
	},
	clearError(state) {
		state.errors = null;
	},
	clearFailedCall(state, source) {
		state.failedCalls = state.failedCalls.filter((id) => id !== source);
	},
	setFailedCall(state, source) {
		state.failedCalls = state.failedCalls.includes(source) ? state.failedCalls : [...state.failedCalls, source];
	},
};
export const getters = {
	emailAddress(state) {
		return state.data?.emailAddress;
	},
	phoneNumber(state) {
		return state.data?.phoneNumber;
	},
	contactDetails(state) {
		return state.data;
	},
	getHashedPhoneNumber(state) {
		return state.data?.hashed_phoneNumber;
	},
	getHashedEmailAddress(state) {
		return state.data?.hashed_emailAddress;
	},
	isLoading(state) {
		return state.loading;
	},
	errorGettingContactInfo(state) {
		return state.failedCalls.includes("fetchingContactDetails");
	},
	errorChangingContactInfo(state) {
		return state.failedCalls.includes("changingContactDetails");
	},
	error(state) {
		return state.error;
	},
	loading(state) {
		return !!state.promise;
	},
};
