import HardwareSubscriptionsV1 from "~/integrations/hardware-subscriptions-v1";

export const state = () => ({
	selectedProductId: undefined,
	agreedToTerms: false,
	isValidChange: false,
	losesCampaign: false,
	validationResponse: undefined,
	validationErrors: [],
	removedCampaigns: undefined,
	newInsuranceProductId: undefined,
	changeInsuranceResponse: undefined,
	validating: false,
	error: false,
});

export const getters = {
	selectedProductId(state) {
		return state.selectedProductId;
	},

	agreedToTerms(state) {
		return state.agreedToTerms;
	},

	isValidChange(state) {
		return state.isValidChange;
	},
	losesCampaign(state) {
		return state.losesCampaign;
	},
	validationResponse(state) {
		return state.validationResponse;
	},
	validationErrors(state) {
		return state.validationErrors;
	},
	removedCampaigns(state) {
		return state.removedCampaigns;
	},
	newInsuranceProductId(state) {
		return state.newInsuranceProductId;
	},
	changeInsuranceResponse(state) {
		return state.changeInsuranceResponse;
	},
	validating(state) {
		return state.validating;
	},
	error(state) {
		return state.error;
	},
};
export const mutations = {
	init(state) {
		state.selectedProductId = undefined;
		state.agreedToTerms = false;
		state.isValidChange = false;
		state.losesCampaign = false;
		state.validationResponse = undefined;
		state.validationErrors = [];
		state.removedCampaigns = undefined;
		state.newInsuranceProductId = undefined;
		state.changeInsuranceResponse = undefined;
		state.validating = false;
		state.error = false;
	},

	setSelectedProductId(state, id) {
		state.selectedProductId = id;
	},

	setAgreedToTerms(state, agreed) {
		state.agreedToTerms = agreed;
	},
	setIsValidChange(state, isValidChange) {
		state.isValidChange = isValidChange;
	},
	setLosesCampaign(state, losesCampaign) {
		state.losesCampaign = losesCampaign;
	},
	setValidationResponse(state, validationResponse) {
		state.validationResponse = validationResponse;
	},
	setValidationErrors(state, validationErrors) {
		state.validationErrors = validationErrors;
	},
	setRemovedCampaigns(state, removedCampaigns) {
		state.removedCampaigns = removedCampaigns;
	},
	setNewInsuranceProductId(state, newInsuranceProductId) {
		state.newInsuranceProductId = newInsuranceProductId;
	},
	setChangeInsuranceResponse(state, changeInsuranceResponse) {
		state.changeInsuranceResponse = changeInsuranceResponse;
	},
	setValidating(state, validating) {
		state.validating = validating;
	},
	setError(state, error) {
		state.error = error;
	},
};

export const actions = {
	selectProduct({ commit }, { selected, product }) {
		if (selected) {
			commit("setSelectedProductId", undefined);
		} else {
			commit("setSelectedProductId", product?.productId);
		}
	},

	agreeToTerms({ commit }, agreed) {
		commit("setAgreedToTerms", agreed);
	},

	async validateChange({ commit, state, dispatch }, { newInsuranceProductId, imei }) {
		try {
			commit("setValidating", true);

			commit("setNewInsuranceProductId", newInsuranceProductId);

			const response = await HardwareSubscriptionsV1.validateChange({
				imei: imei,
				newInsuranceProductId: newInsuranceProductId,
			});

			commit("setValidationResponse", response);

			commit("setValidationErrors", state.validationResponse.validationErrors);
			commit("setRemovedCampaigns", state.validationResponse.removedCampaigns);
			commit("setLosesCampaign", !!state.removedCampaigns.length);
		} catch (e) {
			console.error(e);
			commit("setError", true);
		} finally {
			commit("setValidating", false);
			if (!state.losesCampaign && !state.error) {
				dispatch("acceptChanges", { imei: imei });
			}
		}
	},

	async acceptChanges({ commit, state }, { imei }) {
		try {
			commit("setValidating", true);
			let response;

			if (!state.validationErrors.length) {
				response = await HardwareSubscriptionsV1.changeInsurance({
					imei: imei,
					newInsuranceProductId: state.newInsuranceProductId,
				});
				commit("setChangeInsuranceResponse", response);
			}
		} catch (e) {
			console.error(e);
			commit("setError", true);
		} finally {
			commit("setValidating", false);
		}
	},
};
