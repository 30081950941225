import tvContent from "~/integrations/tv-content";
import { _fetchData } from "~/helpers/store";

const createCategory = (map, name) => ({ ...map, [name]: [] });
const createAndPush = (map, category, channel) => {
	if (!map[category]) map = createCategory(map, category);
	map[category]?.push(channel);
	return map;
};

export default {
	state() {
		return {
			promise: null,
			data: [],
			error: null,
		};
	},
	actions: {
		async fetchData(context, force = false) {
			return _fetchData(() => tvContent.getTvContent(), { context, force, name: "tv-content" });
		},
	},
	mutations: {
		setData(state, data) {
			state.data = data;
		},
		setPromise(state, promise) {
			state.promise = promise;
		},
		setError(state, error) {
			state.error = error;
		},
	},
	getters: {
		error: (state) => state.error,
		loading: (state) => state.promise !== null,
		category: (state, getters) => (category) => ({
			[category]: getters?.categories?.[category]
				?.map((channel) => (channel?.channelPosition ? channel : { ...channel, channelPosition: "1000" }))
				?.sort((a, b) => a.channelPosition - b.channelPosition),
		}),
		categories(state) {
			return state.data?.reduce((map, channel) => {
				if (channel?.mandatory) {
					map = createAndPush(map, "Fast Innhold", channel);
				} else {
					map = createAndPush(map, "Valgfritt Innhold", channel);
				}
				if (channel.includedInDefaultSetup) {
					map = createAndPush(map, "Standardoppsett", channel);
				}
				if (channel.type === "bundle") {
					map = createAndPush(map, "Kanalpakker", channel);
				}
				if (channel?.type === "streaming") {
					map = createAndPush(map, "Strømmetjenester", channel);
				}
				if (channel.categories.length === 0) {
					map = createAndPush(map, "Other", channel);
				} else {
					for (let category of channel.categories) {
						map = createAndPush(map, category, channel);
					}
				}
				map = createAndPush(map, "All", channel);
				return map;
			}, {});
		},
		getChannels: (state) => state.data,
		getChannelsByIds: (state) => (channelIds) =>
			channelIds?.map((id) => state.data?.find((channel) => channel.id === id)),
		getChannelById: (state) => (channelId) => state.data?.find((channel) => channel.id === channelId),
		allCategories(state) {
			return Array.from(new Set(state.data.flatMap((channel) => channel.categories)));
		},
		multiCategory(state) {
			return state.data.some((channel) => channel.categories.length > 1);
		},
		getChannelsByMutualExclusiveGroup: (state) => (mutuallyExclusiveGroup) => {
			return state.data.filter(
				(channel) => channel?.mutuallyExclusiveGroup && channel?.mutuallyExclusiveGroup === mutuallyExclusiveGroup,
			);
		},
	},
};

export const state = () => ({
	promise: null,
	data: [],
	error: null,
});
export const actions = {
	async fetchData(context, force = false) {
		return _fetchData(() => tvContent.getTvContent(), { context, force, name: "tv-content" });
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
};
export const getters = {
	error: (state) => state.error,
	loading: (state) => state.promise !== null,
	category: (state, getters) => (category) => ({
		[category]: getters?.categories?.[category]
			?.map((channel) => (channel?.channelPosition ? channel : { ...channel, channelPosition: "1000" }))
			?.sort((a, b) => a.channelPosition - b.channelPosition),
	}),
	categories(state) {
		return state.data?.reduce((map, channel) => {
			if (channel?.mandatory) {
				map = createAndPush(map, "Fast Innhold", channel);
			} else {
				map = createAndPush(map, "Valgfritt Innhold", channel);
			}
			if (channel.includedInDefaultSetup) {
				map = createAndPush(map, "Standardoppsett", channel);
			}
			if (channel.type === "bundle") {
				map = createAndPush(map, "Kanalpakker", channel);
			}
			if (channel?.type === "streaming") {
				map = createAndPush(map, "Strømmetjenester", channel);
			}
			if (channel.categories.length === 0) {
				map = createAndPush(map, "Other", channel);
			} else {
				for (let category of channel.categories) {
					map = createAndPush(map, category, channel);
				}
			}
			map = createAndPush(map, "All", channel);
			return map;
		}, {});
	},
	getChannels: (state) => state.data,
	getChannelsByIds: (state) => (channelIds) =>
		channelIds?.map((id) => state.data?.find((channel) => channel.id === id)),
	getChannelById: (state) => (channelId) => state.data?.find((channel) => channel.id === channelId),
	allCategories(state) {
		return Array.from(new Set(state.data.flatMap((channel) => channel.categories)));
	},
	multiCategory(state) {
		return state.data.some((channel) => channel.categories.length > 1);
	},
	getChannelsByMutualExclusiveGroup: (state) => (mutuallyExclusiveGroup) => {
		return state.data.filter(
			(channel) => channel?.mutuallyExclusiveGroup && channel?.mutuallyExclusiveGroup === mutuallyExclusiveGroup,
		);
	},
};
