import agreementsV2 from "~/integrations/agreements-v2";
import hardwareSubscriptionsV1 from "~/integrations/hardware-subscriptions-v1";
import { mapInsuranceProducts } from "~/helpers/hardware-and-insurance/insuranceProductMapper";

export default {
	state() {
		return {
			loading: false,
			filteredAgreementOffers: [],
			availableUpgradeOrDowngradeProducts: [],
		};
	},
	mutations: {
		setLoading(state, loading) {
			state.loading = loading;
		},
		setFilteredAgreementOffers(state, offers) {
			state.filteredAgreementOffers = offers;
		},
		setAvailableUpgradeOrDowngradeProducts(state, products) {
			state.availableUpgradeOrDowngradeProducts = products;
		},
	},
	actions: {
		/**
		 * @deprecated
		 */
		async fetchAgreementOffers({ commit }, offersFromGizmo) {
			commit("setLoading", true);
			const subscriptionId = 53040;

			const offers = await agreementsV2.getOffersForSubscriptions(subscriptionId);
			const filteredOffers = [];
			offersFromGizmo.forEach((gizmoOffer) => {
				const offerFromApi = offers.find(
					(offer) => offer.agreementProductId === JSON.parse(gizmoOffer.agreement.agreementOffer).agreementProductId,
				);
				if (offerFromApi !== undefined) {
					const filteredOffer = {
						...offerFromApi,
						badge: gizmoOffer.agreement.badge,
						preSelected: gizmoOffer.agreement.preSelected,
					};
					filteredOffers.push(filteredOffer);
				}
			});
			commit("setFilteredAgreementOffers", filteredOffers);
			commit("setLoading", false);
		},

		async fetchAgreementOffersV2(
			{ commit },
			{ offersFromGizmo, subscriptionProductOfferId, imei, gtin, hardwareType = "MOBILE_PHONE" },
		) {
			commit("setLoading", true);
			try {
				const offers = await hardwareSubscriptionsV1.getInsuranceProducts({
					subscriptionProductOfferId,
					imei: imei,
					gtin: gtin,
					hardwareType: hardwareType,
				});

				const filteredOffers = mapInsuranceProducts({ products: offers.products, benefits: offersFromGizmo });

				commit("setFilteredAgreementOffers", filteredOffers);
				commit("setLoading", false);
			} catch (e) {
				console.error(e);
			} finally {
				commit("setLoading", false);
			}
		},

		async fetchAvailableUpgradeOrDowngradeProducts({ commit }, { insurances, imei }) {
			commit("setLoading", true);
			try {
				const response = await hardwareSubscriptionsV1.getInsuranceProductsForUpgradingOrDowngrading(imei);

				const products = mapInsuranceProducts({ products: response.products, benefits: insurances });

				commit("setAvailableUpgradeOrDowngradeProducts", products);
				commit("setLoading", false);
			} catch (e) {
				console.error(e);
			} finally {
				commit("setLoading", false);
			}
		},
	},
	getters: {
		filteredAgreementOffers(state) {
			return state.filteredAgreementOffers;
		},

		availableUpgradeOrDowngradeProducts(state) {
			return state.availableUpgradeOrDowngradeProducts;
		},

		featureList(state) {
			const newList = [];

			state.filteredAgreementOffers.forEach((offer) => {
				offer.features.forEach((feature) => {
					if (!newList.some((item) => item === feature.text)) {
						newList.push(feature.text);
					}
				});
			});

			return newList;
		},
	},
};

export const state = () => ({
	loading: false,
	filteredAgreementOffers: [],
	availableUpgradeOrDowngradeProducts: [],
});
export const actions = {
	/**
	 * @deprecated
	 */
	async fetchAgreementOffers({ commit }, offersFromGizmo) {
		commit("setLoading", true);
		const subscriptionId = 53040;

		const offers = await agreementsV2.getOffersForSubscriptions(subscriptionId);
		const filteredOffers = [];
		offersFromGizmo.forEach((gizmoOffer) => {
			const offerFromApi = offers.find(
				(offer) => offer.agreementProductId === JSON.parse(gizmoOffer.agreement.agreementOffer).agreementProductId,
			);
			if (offerFromApi !== undefined) {
				const filteredOffer = {
					...offerFromApi,
					badge: gizmoOffer.agreement.badge,
					preSelected: gizmoOffer.agreement.preSelected,
				};
				filteredOffers.push(filteredOffer);
			}
		});
		commit("setFilteredAgreementOffers", filteredOffers);
		commit("setLoading", false);
	},

	async fetchAgreementOffersV2(
		{ commit },
		{ offersFromGizmo, subscriptionProductOfferId, imei, gtin, hardwareType = "MOBILE_PHONE" },
	) {
		commit("setLoading", true);
		try {
			const offers = await hardwareSubscriptionsV1.getInsuranceProducts({
				subscriptionProductOfferId,
				imei: imei,
				gtin: gtin,
				hardwareType: hardwareType,
			});

			const filteredOffers = mapInsuranceProducts({ products: offers.products, benefits: offersFromGizmo });

			commit("setFilteredAgreementOffers", filteredOffers);
			commit("setLoading", false);
		} catch (e) {
			console.error(e);
		} finally {
			commit("setLoading", false);
		}
	},

	async fetchAvailableUpgradeOrDowngradeProducts({ commit }, { insurances, imei }) {
		commit("setLoading", true);
		try {
			const response = await hardwareSubscriptionsV1.getInsuranceProductsForUpgradingOrDowngrading(imei);

			const products = mapInsuranceProducts({ products: response.products, benefits: insurances });

			commit("setAvailableUpgradeOrDowngradeProducts", products);
			commit("setLoading", false);
		} catch (e) {
			console.error(e);
		} finally {
			commit("setLoading", false);
		}
	},
};
export const mutations = {
	setLoading(state, loading) {
		state.loading = loading;
	},
	setFilteredAgreementOffers(state, offers) {
		state.filteredAgreementOffers = offers;
	},
	setAvailableUpgradeOrDowngradeProducts(state, products) {
		state.availableUpgradeOrDowngradeProducts = products;
	},
};
export const getters = {
	filteredAgreementOffers(state) {
		return state.filteredAgreementOffers;
	},

	availableUpgradeOrDowngradeProducts(state) {
		return state.availableUpgradeOrDowngradeProducts;
	},

	featureList(state) {
		const newList = [];

		state.filteredAgreementOffers.forEach((offer) => {
			offer.features.forEach((feature) => {
				if (!newList.some((item) => item === feature.text)) {
					newList.push(feature.text);
				}
			});
		});

		return newList;
	},
};
