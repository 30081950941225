import streamingPlusIntegration from "~/integrations/streaming-plus";
import { _fetchData } from "~/helpers/store";
const getDefaultState = () => {
	return {
		promise: null,
		data: [],
		error: null,
	};
};

export const state = () => getDefaultState();
export const actions = {
	async fetchData(context, { force, accountId, bundleId, providerIds }) {
		if (!providerIds.length) {
			return;
		}
		return _fetchData(
			() => {
				const requests = providerIds.map((providerId) => {
					return new Promise((resolve) => {
						streamingPlusIntegration
							.fetchStreamingPlusThirdPartyStatus({
								accountId: accountId,
								bundleId: bundleId,
								providerId: providerId,
							})
							.then((result) => {
								resolve(result);
							})
							.catch((error) => {
								console.error(error);
								resolve({
									providerId: providerId,
									error: error,
								});
							});
					});
				});
				return Promise.all(requests);
			},
			{
				context,
				force,
				name: "tv-content-third-party-status",
			},
		);
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
};
export const getters = {
	data: (state) => {
		return state.data;
	},
	error: (state) => {
		return state.error;
	},
	loading: (state) => {
		return !!state.promise;
	},
	getThirdPartyStatusByProviderId: (state) => (providerId) => {
		return state.data.find((status) => status.providerId === providerId)?.thirdPartyStatus;
	},
	getThirdPartyStatusErrorByProviderId: (state) => (providerId) => {
		return state.data.find((status) => status.providerId === providerId)?.error;
	},
};
