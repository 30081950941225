export default (comp) => {
	if (!comp.segmentation) {
		return false;
	}
	return (
		Object.keys(comp.segmentation).filter((key) => {
			if (Array.isArray(comp.segmentation[key])) return comp.segmentation[key].length > 0;
			return comp.segmentation[key];
		}).length > 0
	);
};
