import fixedSubscription from "~/integrations/fixed-subscription";
import { _fetchData } from "~/helpers/store";

export default {
	state() {
		return {
			promise: null,
			data: null,
			error: null,
		};
	},
	actions: {
		async fetchData(context, productId, force = true) {
			return _fetchData(() => fixedSubscription.getDevices(), { context, force, name: "subscriptionDevices" });
		},
	},
	mutations: {
		setData(state, data) {
			state.data = data;
		},
		setPromise(state, promise) {
			state.promise = promise;
		},
		setError(state, error) {
			state.error = error;
		},
	},
	getters: {
		devices(state) {
			return state?.data?.devices || [];
		},
		devicesLoading: (state) => !!state.promise,
		devicesFetched: (state) => !!state.data,
	},
};

export const state = () => ({
	promise: null,
	data: null,
	error: null,
});
export const actions = {
	async fetchData(context, productId, force = true) {
		return _fetchData(() => fixedSubscription.getDevices(), { context, force, name: "subscriptionDevices" });
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
};
export const getters = {
	devices(state) {
		return state?.data?.devices || [];
	},
	devicesLoading: (state) => !!state.promise,
	devicesFetched: (state) => !!state.data,
};
