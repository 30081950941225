/**
 * Capitalizes all words in a string. This can be overridden by setting `onlyCapitalizeFirstWord` to `true`.
 * @param {string} text - The text you want to capitalize.
 * @param {boolean} [onlyCapitalizeFirstWord=false] - If set to `true`, only the first word will be capitalized.
 * @returns {string}
 */
export default (text, onlyCapitalizeFirstWord = false) => {
	const pattern = new RegExp("[\\wæøåÆØÅ][\\S.]*", onlyCapitalizeFirstWord ? "" : "g");
	return text
		?.trim()
		?.toLowerCase()
		?.replace(pattern, (w) => w?.replace(/^\w|[æøå]/, (c) => c?.toUpperCase()));
};
