import apigeeIntegration from "./self-service-adapter";
import apigeeSystemIntegration from "./self-service-system-adapter";

const api = apigeeIntegration("/agreements/v2");
const systemUserApi = apigeeSystemIntegration("/agreements/v2/");

const getInventory = () => api.get("/inventory");
const getOffers = () => api.get("/offers");
const getOffersForLoggedOutUsers = () => systemUserApi.get("/offers");
const getOffersForSubscriptions = (subscriptionProductOfferId) =>
	api.get(`/offers/subscription-products/${subscriptionProductOfferId}`);
const validateOrder = (order) => api.post("/order/validate", order);
const submitOrder = (order) => api.post("/order", order);

export default {
	getInventory,
	getOffers,
	getOffersForLoggedOutUsers,
	getOffersForSubscriptions,
	validateOrder,
	submitOrder,
};
