import securitySubscriptionsIntegration from "~/integrations/security-subscriptions-integration";

export const state = () => {
	return {
		isProductsFetched: false,
		isOffersFetched: false,
		products: [],
		offersAsOwner: [],
		offersAsUser: [],
		error: null,
		isChangeSubscriptionFetched: false,
		changeSubscriptionResp: null,
		isOrderPlaced: false,
	};
};
export const actions = {
	async fetchOffers({ commit }) {
		try {
			const offers = await securitySubscriptionsIntegration.getOffers();
			if (offers) {
				commit("setOffers", offers);
				commit("setOffersFetched", true);
			}
		} catch (err) {
			throw new Error(err);
		}
	},
	async fetchOffersBySubscriptionId({ commit }, { subscriptionId }) {
		try {
			const offers = await securitySubscriptionsIntegration.getOffersBySubscriptionId(subscriptionId);
			if (offers) {
				commit("setOffers", offers);
				commit("setOffersFetched", true);
			}
		} catch (err) {
			throw new Error(err);
		}
	},
	async fetchSubscriptions({ commit }) {
		try {
			const products = await securitySubscriptionsIntegration.getProducts();
			if (products) {
				commit("setProducts", products);
				commit("setProductsFetched", true);
			}
		} catch (err) {
			throw new Error(err);
		}
	},
	async createNewOrder({ commit }, order) {
		try {
			const resp = await securitySubscriptionsIntegration.createNewOrder(order);
			if (resp) {
				commit("setChangeSubscriptionResp", resp);
				commit("setIsOrderPlaced", true);
			}
		} catch (err) {
			throw new Error(err);
		}
	},
	async createChangeSubscriptionOrder({ commit }, { subscriptionId, productOfferId, isValidation }) {
		try {
			const resp = await securitySubscriptionsIntegration.createChangeSubscriptionOrder(subscriptionId, {
				productOrders: [
					{
						productOfferId,
						actionType: "NEW",
					},
				],
				isValidation,
			});
			if (resp) {
				commit("setChangeSubscriptionResp", resp);
				commit("setIsOrderPlaced", true);
			}
		} catch (err) {
			throw new Error(err);
		} finally {
			commit("setChangeSubscriptionFetched", true);
		}
	},

	async terminateSubscription({ commit }, { subscriptionId }) {
		try {
			const resp = await securitySubscriptionsIntegration.terminate(subscriptionId);
			if (resp) {
				commit("setIsOrderPlaced", true);
			}
		} catch (err) {
			throw new Error(err);
		}
	},
};
export const mutations = {
	setOffers(state, data) {
		state.offersAsOwner = data.offersAsOwner;
		state.offersAsUser = data.offersAsUser;
	},
	setOffersFetched(state, data) {
		state.isOffersFetched = data;
	},
	setProducts(state, data) {
		state.products = data;
	},
	setProductsFetched(state, data) {
		state.isProductsFetched = data;
	},
	setStatusError(state, data) {
		state.error = data;
	},
	setChangeSubscriptionResp(state, data) {
		state.changeSubscriptionResp = data;
	},
	setChangeSubscriptionFetched(state, data) {
		state.isChangeSubscriptionFetched = data;
	},
	setIsOrderPlaced(state, data) {
		state.isOrderPlaced = data;
	},
};
export const getters = {
	isOffersFetched(state) {
		return state.isOffersFetched;
	},
	isProductsFetched(state) {
		return state.isProductsFetched;
	},
	offers(state) {
		return {
			offersAsOwner: state.offersAsOwner,
			offersAsUser: state.offersAsUser,
		};
	},
	subscribedProducts(state) {
		return state.products;
	},
	isChangeSubscriptionFetched(state) {
		return state.isChangeSubscriptionFetched;
	},
	changeSubscriptionResp(state) {
		return state.changeSubscriptionResp;
	},
	isOrderPlaced(state) {
		return state.isOrderPlaced;
	},
};
