import { _fetchData } from "~/helpers/store";
import addressSearch from "~/integrations/address-search";

export const state = () => {
	return {
		promise: null,
		data: {},
		error: null,
		suggestions: null,
		lastSearchedAddress: null,
		suggestionsError: false,
		apartments: [],
		apartmentsError: false,
	};
};
export const actions = {
	async fetchData(context, { addressId, isLoggedIn, force = false }) {
		return _fetchData(() => addressSearch.fetchAddress({ addressId: addressId, isLoggedIn: isLoggedIn }), {
			context,
			force,
			name: "address-search",
		});
	},
	fetchAddressSuggestions(context, query) {
		addressSearch
			.searchWithBFF(query)
			.then((response) => context.commit("setSuggestions", response))
			.catch((error) => {
				console.error(error);
				context.commit("setSuggestionsError", true);
			});
	},
	async fetchAddressAndApartments(context, { addressId, isLoggedIn, force = true }) {
		await context.dispatch("fetchData", { addressId, isLoggedIn, force });
		context.commit("setApartmentsError", false);
		try {
			const response = await addressSearch.fetchApartments({ address: context.getters.address, isLoggedIn });
			context.commit("setApartments", response.addresses);
			if (response.addresses.length === 1) {
				context.commit("setData", response.addresses[0]);
			}
		} catch (error) {
			console.error(error);
			context.commit("setApartmentsError", true);
		}
	},
	resetData({ commit }) {
		commit("setData", {});
		commit("setSuggestions", null);
		commit("setApartments", []);
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	setSuggestions(state, suggestions) {
		state.suggestions = suggestions;
	},
	setLastSearchedAddress(state, lastSearchedAddress) {
		state.lastSearchedAddress = lastSearchedAddress;
	},
	setSuggestionsError(state, suggestionsError) {
		state.suggestionsError = suggestionsError;
	},
	setApartments(state, apartments) {
		state.apartments = apartments;
	},
	setApartmentsError(state, error) {
		state.apartmentsError = error;
	},
};
export const getters = {
	address(state) {
		return state.data;
	},
	addressType: (state, getters) => getters.address.addressType,
	loading: (state) => !!state.promise,
	suggestions(state) {
		return state.suggestions?.addresses?.map((address) => ({
			...address,
			formattedAddress: address?.address
				?.replace(/^\w/, (m) => m.toUpperCase())
				?.replace(/[\s.][\w]/g, (m) => m.toUpperCase()),
		}));
	},
	lastSearchedAddress: (state) => state.lastSearchedAddress,
	error: (state) => state.error,
	apartments: (state) => state.apartments,
	getCadestralAddress: (state, getters) => (getters.address.addressType === "M" ? getters.address : null),
	apartmentsError: (state) => state.apartmentsError,
	suggestionsError: (state) => state.suggestionsError,
};
