import apigeeIntegration from "./self-service-adapter";
import apigeeSystemUserIntegration from "./self-service-system-adapter.js";

const api = apigeeIntegration("/fixed-subscription/v2");
const apiV1 = apigeeIntegration("/fixed-subscription/v1");
const apiSystemV1 = apigeeSystemUserIntegration("/fixed-subscription/v1");

const getSubscriptions = async () => api.get("/subscriptions");
const getSubscription = async (id) => api.get(`/subscriptions/${id}`);
const getSubscriptionOwner = async (id) => apiV1.get(`/subscriptions/${id}/owner`);
const getSubscriptionPayer = async (id) => apiV1.get(`/subscriptions/${id}/payer`);
const getSubscriptionUser = async (id) => apiV1.get(`/subscriptions/${id}/user`);
const getDevices = async () => apiV1.get(`/devices`);
const getAddonOffers = async (id) => apiV1.get(`/subscriptions/${id}/offers/addons`);
const getTweSubscriptions = async () => apiV1.get("/twe");
const getTweSubscriptionsKAS = async (kasNumber) => apiSystemV1.get("/twe/" + kasNumber);
const getSubscriptionProducts = async (id) =>
	apiV1.get(`subscriptions/${id}/products`, {
		cache: { override: true },
		timeout: 35000,
	});

export default {
	getSubscriptions,
	getSubscription,
	getAddonOffers,
	getTweSubscriptions,
	getTweSubscriptionsKAS,
	getDevices,
	getSubscriptionOwner,
	getSubscriptionPayer,
	getSubscriptionUser,
	getSubscriptionProducts,
};
