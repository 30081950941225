import apigeeIntegration from "./self-service-adapter";

const api = apigeeIntegration("/handsets/v1");

const getHandsets = () => api.get("/");

export default {
	getHandsets,
};

export { getHandsets };
