import tvContent from "~/integrations/tv-content";
import { _fetchData } from "~/helpers/store";
const getDefaultState = () => {
	return {
		promise: null,
		data: [],
		error: null,
	};
};
export default {
	state: getDefaultState,
	actions: {
		async fetchData(context, force) {
			return _fetchData(() => tvContent.getPremiumTvContent(), {
				context,
				force,
				name: "premiumTvContent",
			});
		},
	},
	mutations: {
		setData(state, data) {
			state.data = data;
		},
		setPromise(state, promise) {
			state.promise = promise;
		},
		setError(state, error) {
			state.error = error;
		},
		resetState(state) {
			Object.assign(state, getDefaultState());
		},
	},
	getters: {
		loading: (state) => {
			return state.promise !== null;
		},
		error: (state) => {
			return state.error;
		},
		premiumTvContent: (state) => {
			return state.data;
		},
	},
};

export const state = () => getDefaultState();
export const actions = {
	async fetchData(context, force) {
		return _fetchData(() => tvContent.getPremiumTvContent(), {
			context,
			force,
			name: "premiumTvContent",
		});
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	},
};
export const getters = {
	loading: (state) => {
		return state.promise !== null;
	},
	error: (state) => {
		return state.error;
	},
	premiumTvContent: (state) => {
		return state.data;
	},
};
