import deviceReturns from "~/integrations/device-returns";
import validateImeiNumber from "~/helpers/validateImeiNumber";

export const state = () => ({
	loading: false,
	deviceReturnId: null,
	phoneData: {
		IMEINumber: null,
		gtin: null,
		manufacturer: null,
		model: null,
		memorySize: null,
		agreement: null,
		imageUrl: null,
		name: null,
		isPreSelected: false,
		msisdn: null,
		color: null,
		deviceOrder: null,
	},
	deviceResultData: [],
	gradingData: {},
	gradingQuestions: {},
	gradingAnswers: [],
	accountNumber: null,
	emailAddress: "",
	phoneNumber: "",
	insuranceClaimData: {
		submitError: {
			status: false,
			message: "Noe gikk galt. Prøv igjen eller kontakt kundeservice hvis problemet gjentar seg.",
		},
		outsideInsuranceDateError: {
			status: false,
			message: "",
		},
	},
	hasSwap: false,
	isCheckout: false,
	steps: {
		imeiStepCompleted: false,
		gradingStepCompleted: false,
		hasValidInsurance: false,
		wantsToUseInsurance: false,
		goToAccountNumber: false,
		accountNumberStepCompleted: false,
		tradeInCompleted: false,
		goToSummary: false,
	},
	needCustomerFeedback: false,
	error: null,
});
export const actions = {
	resetGrading({ commit, dispatch }) {
		dispatch("resetGradingAnswers");
		commit("setGradingData", {});
	},

	checkForAgreement({ commit, state }, imei) {
		const device = state?.deviceResultData?.find((d) => d.imei === imei);
		if (device?.agreement) {
			commit("setAgreement", device.agreement);

			if (device.agreement?.hasSwap) {
				commit("setHasSwap", device.agreement.hasSwap);
			}
		}
	},

	checkForDeviceOrder({ commit, state }, imei) {
		const device = state?.deviceResultData?.find((d) => d.imei === imei);
		if (device?.deviceOrder) {
			commit("setDeviceOrder", device.deviceOrder);
		}
	},

	async init({ commit, dispatch, rootGetters }) {
		commit("setLoading", true);

		try {
			await dispatch("shoppingCart/init", null, { root: true });

			const terminal = rootGetters["checkout/currentTerminal"];

			if (terminal?.metadata?.swap?.tradein) {
				dispatch("reinstate", terminal.metadata.swap.tradein);
			}
		} catch (err) {
			console.error(err);
			throw err;
		} finally {
			commit("setLoading", false);
		}
	},

	async addTradeIn({ commit, dispatch }, { device, msisdn, insuranceAgreement, metadata, preSelectSwapPhone = false }) {
		await commit("setIMEINumber", device?.imei);
		await commit("setManufacturer", device?.manufacturer || device?.vendor);
		await commit("setModel", device?.model);
		await commit("setMemorySize", device?.storage);
		await commit("setGtin", device?.gtin);
		await commit("setImageUrl", device?.imageUrl);
		await commit("setName", device?.name);
		await commit("setAgreement", insuranceAgreement);
		await commit("setPreSelectSwapPhone", preSelectSwapPhone);
		await commit("setMsisdn", msisdn);
		await commit("setHasSwap");
		await commit("setIsCheckout");

		await dispatch("shoppingCart/setMetadata", metadata, { root: true });
	},
	async searchIMEI({ commit }, imeiNumber) {
		const searchData = await deviceReturns.searchIMEINumber(imeiNumber);
		commit("setDeviceResultData", searchData);
	},
	async searchIMEILoggedIn({ commit }, imeiNumber) {
		const searchData = await deviceReturns.searchIMEINumberLoggedInUser(imeiNumber);
		commit("setDeviceResultData", searchData);
	},

	async searchForPhone({ commit, dispatch, state }, { imeiNumber, isLoggedIn = false }) {
		dispatch("resetGrading");
		dispatch("resetPhoneData");
		commit("setHasSwap", false);

		const imeiNumberClean = imeiNumber.replace(/\D/g, "");

		if (imeiNumberClean && imeiNumberClean.toString().trim() !== "") {
			if (imeiNumberClean.length === 15 && validateImeiNumber(imeiNumberClean)) {
				try {
					commit("setLoading", true);
					commit("setIMEINumber", imeiNumberClean);
					if (isLoggedIn) {
						await dispatch("searchIMEILoggedIn", imeiNumberClean);
					} else {
						await dispatch("searchIMEI", imeiNumberClean);
					}
					commit("setLoading", false);

					if (state?.deviceResultData && state?.deviceResultData?.length === 1) {
						const device = state?.deviceResultData?.find((d) => d.imei === imeiNumberClean);
						dispatch("addDeviceData", device);
					} else {
						dispatch("checkForDeviceOrder", imeiNumberClean);
						commit("setNeedCustomerFeedback", true);
					}
				} catch (e) {
					console.error(e);
				}
			}
		}
	},

	addDeviceData({ commit, dispatch }, device) {
		commit("setManufacturer", device?.manufacturer);
		commit("setModel", device?.model);
		commit("setMemorySize", device?.memorySize ?? device?.storage);
		commit("setGtin", device?.gtin);
		commit("setModel", device?.model);
		commit("setImageUrl", device?.imageUrl);
		commit("setName", `${device?.manufacturer} ${device?.model}`);
		commit("setColor", device?.color);
		dispatch("checkForAgreement", device?.imei);
		dispatch("checkForDeviceOrder", device?.imei);
	},

	async getEstimatedValue({ commit, state }, answers) {
		commit("setGradingData", {});

		try {
			const result = await deviceReturns.getEstimatedValue(state.phoneData.IMEINumber, {
				deviceInfo: state.phoneData,
				answers,
				isBusiness: false,
			});

			commit("setGradingData", result);
			commit("setGradingStepCompleted");

			return true;
		} catch (e) {
			if (e.response?.data?.errorCode === 400020) {
				// API returns error, but it is "not" an error. It just means you cannot tradein this device without fixing it without using insurance first.
				return true;
			}

			const existingSwapOrderId =
				e?.response?.data?.description?.split("ID:")?.[1] || e?.response?.data?.data?.description?.split("ID:")?.[1];
			if (existingSwapOrderId) {
				commit("setGradingData", { existingSwapOrderId });
			} else if (e.response?.data?.description?.includes("106")) {
				commit("setGradingData", { imeiHasOrder: true });
			} else {
				commit("setGradingData", { failedToGetEstimateError: true });
			}

			return false;
		}
	},

	async getGradingQuestions({ commit }) {
		try {
			const gradingQuestions = await deviceReturns.getGradingQuestions();
			commit("setGradingQuestions", gradingQuestions);

			const answers = [];
			gradingQuestions.questions.map((question) => {
				answers.push({
					questionId: question.id,
					answerCode: null,
				});
			});
			commit("setGradingAnswers", answers);
		} catch (e) {
			console.error(e);
		}
	},

	updateGradingAnswers({ commit }, updatedAnswers) {
		commit("setGradingAnswers", updatedAnswers);
	},

	resetGradingAnswers({ commit, state }) {
		if (state.gradingQuestions?.questions?.length) {
			const answers = [];
			state.gradingQuestions.questions.map((question) => {
				answers.push({
					questionId: question.id,
					answerCode: null,
				});
			});

			commit("setGradingAnswers", answers);
		}
	},

	resetPhoneData({ commit }) {
		commit("setPhoneData", {
			IMEINumber: null,
			gtin: null,
			manufacturer: null,
			model: null,
			memorySize: null,
			agreement: null,
			imageUrl: null,
			name: null,
			isPreSelected: false,
			msisdn: null,
			color: null,
			deviceOrder: null,
		});
	},

	async submitInsuranceClaim({ commit, state }, insuranceClaimForm) {
		try {
			await deviceReturns.submitInsuranceClaim(state.gradingData.deviceReturnId, insuranceClaimForm);
			commit("setInsuranceClaimData", insuranceClaimForm);
			commit("setGoToAccountNumber");

			return true;
		} catch (e) {
			console.error(e);
			if (e?.response?.data?.description?.toLowerCase()?.indexOf("date of damage") > -1) {
				commit("setInsuranceClaimOutsideDateError", true);
				return false;
			}
			commit("setInsuranceClaimErrorStatus", true);
			return false;
		}
	},
	reinstate({ commit }, reinstate) {
		if (reinstate.phoneData) {
			commit("setPhoneData", reinstate.phoneData);
		}
		if (reinstate.gradingData) {
			commit("setGradingData", reinstate.gradingData);
		}
		if (reinstate.gradingQuestions) {
			commit("setGradingQuestions", reinstate.gradingQuestions);
		}
		if (reinstate.gradingAnswers) {
			commit("setGradingAnswers", reinstate.gradingAnswers);
		}
		if (reinstate.accountNumber) {
			commit("setAccountNumber", reinstate.accountNumber);
		}
		if (reinstate.insuranceClaimData) {
			commit("setInsuranceClaimData", reinstate.insuranceClaimData);
		}
		if (reinstate.steps) {
			commit("setSteps", reinstate.steps);
		}
		if (reinstate.hasSwap) {
			commit("setHasSwap");
		}
		if (reinstate.isCheckout) {
			commit("setIsCheckout");
		}
	},
	async resetTradein({ commit, dispatch, rootGetters }) {
		const terminal = rootGetters["checkout/currentTerminal"];

		if (terminal?.metadata?.swap?.tradein) {
			await dispatch(
				"shoppingCart/setMetadata",
				{ swap: { ...terminal.metadata.swap, tradein: null } },
				{ root: true },
			);
		}

		const fullState = {
			loading: false,
			deviceReturnId: null,
			phoneData: {
				IMEINumber: null,
				gtin: null,
				manufacturer: null,
				model: null,
				memorySize: null,
				agreement: null,
				imageUrl: null,
				name: null,
				isPreSelected: false,
				msisdn: null,
				color: null,
				deviceOrder: null,
			},
			deviceResultData: [],
			gradingData: {},
			gradingQuestions: {},
			gradingAnswers: [],
			accountNumber: null,
			emailAddress: "",
			phoneNumber: "",
			insuranceClaimData: {
				submitError: {
					status: false,
					message: "Noe gikk galt. Prøv igjen eller kontakt kundeservice hvis problemet gjentar seg.",
				},
				outsideInsuranceDateError: {
					status: false,
					message: "",
				},
			},
			hasSwap: false,
			isCheckout: false,
			steps: {
				imeiStepCompleted: false,
				gradingStepCompleted: false,
				hasValidInsurance: false,
				wantsToUseInsurance: false,
				goToAccountNumber: false,
				accountNumberStepCompleted: false,
				tradeInCompleted: false,
				goToSummary: false,
			},
			needCustomerFeedback: false,
			error: null,
		};

		commit("setState", fullState);
	},
};
export const mutations = {
	setGoToSummary(state, goToSummary = true) {
		state.steps.goToSummary = goToSummary;
	},
	setLoading(state, loading) {
		state.loading = loading;
	},
	setPhoneData(state, phoneData) {
		state.phoneData = phoneData;
	},
	setGradingData(state, gradingData) {
		state.gradingData = gradingData;
	},
	setGradingQuestions(state, gradingQuestions) {
		state.gradingQuestions = gradingQuestions;
	},
	setGradingAnswers(state, gradingAnswers) {
		state.gradingAnswers = gradingAnswers;
	},
	setAccountNumber(state, accountNumber) {
		state.accountNumber = accountNumber;
	},
	setEmailAddress(state, emailAddress) {
		state.emailAddress = emailAddress;
	},
	setPhoneNumber(state, phoneNumber) {
		state.phoneNumber = phoneNumber;
	},
	setInsuranceClaimData(state, insuranceClaimData) {
		state.insuranceClaimData.description = insuranceClaimData.description;
		state.insuranceClaimData.dateOfDamage = insuranceClaimData.dateOfDamage;
		state.insuranceClaimData.damageCodes = insuranceClaimData.damageCodes;
	},
	setInsuranceClaimErrorStatus(state, errorStatus) {
		state.insuranceClaimData.submitError = {
			status: errorStatus,
			message: "Noe gikk galt. Prøv igjen eller kontakt kundeservice hvis problemet gjentar seg.",
		};
	},
	setInsuranceClaimOutsideDateError(state, errorStatus) {
		state.insuranceClaimData.outsideInsuranceDateError = {
			status: errorStatus,
			message: "Forsikringen din var ikke aktiv da skaden skjedde. Prøv igjen, eller kontakt kundeservice.",
		};
	},
	setIMEINumber(state, IMEINumber) {
		state.phoneData.IMEINumber = IMEINumber;
	},
	setManufacturer(state, manufacturer) {
		state.phoneData.manufacturer = manufacturer;
	},
	setModel(state, model) {
		state.phoneData.model = model;
	},
	setMemorySize(state, memorySize) {
		state.phoneData.memorySize = memorySize;
	},
	setGtin(state, gtin) {
		state.phoneData.gtin = gtin;
	},
	setAgreement(state, agreement) {
		state.phoneData.agreement = agreement;
	},
	setColor(state, color) {
		state.phoneData.color = color;
	},
	setDeviceResultData(state, deviceResultData) {
		state.deviceResultData = deviceResultData;
	},
	setHasSwap(state, hasSwap = true) {
		state.hasSwap = hasSwap;
	},
	setIsCheckout(state, isCheckout = true) {
		state.isCheckout = isCheckout;
	},
	setSteps(state, steps) {
		state.steps = steps;
	},
	setImeiStepCompleted(state, imeiStepCompleted = true) {
		state.steps.imeiStepCompleted = imeiStepCompleted;
	},
	setGradingStepCompleted(state, gradingStepCompleted = true) {
		state.steps.gradingStepCompleted = gradingStepCompleted;
	},
	setHasValidInsurance(state, hasValidInsurance = true) {
		state.steps.hasValidInsurance = hasValidInsurance;
	},
	setWantsToUseInsurance(state, wantsToUseInsurance = true) {
		state.steps.wantsToUseInsurance = wantsToUseInsurance;
	},
	setGoToAccountNumber(state, goToAccountNumber = true) {
		state.steps.goToAccountNumber = goToAccountNumber;
	},
	setAccountNumberStepCompleted(state, accountNumberStepCompleted = true) {
		state.steps.accountNumberStepCompleted = accountNumberStepCompleted;
	},
	setTradeInCompleted(state, tradeInCompleted = true) {
		state.steps.tradeInCompleted = tradeInCompleted;
	},
	setImageUrl(state, imageUrl) {
		state.phoneData.imageUrl = imageUrl;
	},
	setName(state, name) {
		state.phoneData.name = name;
	},
	setPreSelectSwapPhone(state, status) {
		state.phoneData.isPreSelected = status;
	},
	setMsisdn(state, msisdn) {
		state.phoneData.msisdn = msisdn;
	},
	setNeedCustomerFeedback(state, needCustomerFeedback) {
		state.needCustomerFeedback = needCustomerFeedback;
	},
	setDeviceOrder(state, deviceOrder) {
		state.phoneData.deviceOrder = deviceOrder;
	},
	setError(state, error) {
		state.error = error;
	},
	setState(state, newState) {
		state = newState;
	},
};
export const getters = {
	canContinue(state) {
		return (
			!!(
				state?.phoneData?.gtin ||
				(state?.needCustomerFeedback &&
					state?.phoneData?.manufacturer &&
					state?.phoneData?.model &&
					state?.phoneData?.memorySize)
			) &&
			!state.phoneData?.agreement?.hasSwap &&
			!state?.phoneData?.deviceOrder?.orderId &&
			validateImeiNumber(state?.phoneData?.IMEINumber)
		);
	},
	goToSummary(state) {
		return state.steps.goToSummary;
	},
	hasDeviceOrder(state) {
		return !!state.phoneData?.deviceOrder;
	},

	fullState(state) {
		return state;
	},
	phoneData(state) {
		return state.phoneData;
	},
	deviceResultData(state) {
		return state.deviceResultData;
	},
	gradingData(state) {
		return state.gradingData;
	},
	hasDeviceReturnId(state) {
		return !!state.gradingData.deviceReturnId;
	},
	gradingQuestions(state) {
		return state.gradingQuestions;
	},
	gradingAnswers(state) {
		return state.gradingAnswers;
	},
	accountNumber(state) {
		return state.accountNumber;
	},
	emailAddress(state) {
		return state.emailAddress;
	},
	phoneNumber(state) {
		return state.phoneNumber;
	},
	insuranceClaimData(state) {
		return state.insuranceClaimData;
	},
	hasSwap(state) {
		return state.hasSwap;
	},
	isCheckout(state) {
		return state.isCheckout;
	},
	// EDGES FOR FLOW NAVIGATION //
	isImeiStepCompleted(state) {
		return state.steps.imeiStepCompleted;
	},
	isGradingStepCompleted(state) {
		return state.steps.gradingStepCompleted;
	},
	hasValidInsurance(state) {
		return state.steps.hasValidInsurance;
	},
	wantsToUseInsurance(state) {
		return state.steps.wantsToUseInsurance;
	},
	goToAccountNumber(state) {
		return state.steps.goToAccountNumber;
	},
	isAccountNumberStepCompleted(state) {
		return state.steps.accountNumberStepCompleted;
	},
	isTradeInCompleted(state) {
		return state.steps.tradeInCompleted;
	},
	msisdn(state) {
		return state?.phoneData?.msisdn;
	},
	loading(state) {
		return state.loading;
	},
	needCustomerFeedback(state) {
		return state.needCustomerFeedback;
	},
};
