import fixedOrder from "~/integrations/fixed-order";
import { getAddonProducts } from "~/components/broadband-consumer/FixedOrder/steps/GizmoFixedOrderSummary/FixedSummaryModel.js";
import { _fetchData } from "~/helpers/store";
import {
	getMonthlyDiscountedPrice,
	getMonthlyDiscount,
	getMonthlyPrice,
} from "~/components/broadband-consumer/FixedOrder/steps/FixedOfferCards/FixedOfferModel";

export const state = () => ({
	promise: null,
	data: null,
	error: null,
});
export const actions = {
	async fetchData(context) {
		const fixedState = context?.rootState?.["fixedOrder"]?.input;
		const fixedFlow = context?.rootGetters?.["fixedOrder/fixedOrderFlow/currentFlow"];
		const fixedGetters = context?.rootGetters;
		return _fetchData(
			() =>
				fixedOrder.validate({
					validator: fixedFlow?.name,
					order: fixedGetters?.["fixedOrder/input/apiCompatibleOrder"],
					subscriptionId: fixedState.subscriptionId,
				}),
			{ context, force: true, name: "validate" },
		); // allways try to validate an order
	},
};
export const mutations = {
	setData(state, data) {
		if (!data.successful) {
			state.error = data.validationErrors?.[0];
		}
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
};
export const getters = {
	validating: (state) => !!state?.promise,
	validatedOrder: (state) => state?.data,
	validationError: (state) => state?.error,

	totalMonthlyDiscountedChangeCost(state, getters) {
		return getters?.validatedOrder?.orderInformation?.offers[0]?.products
			?.filter((product) => product?.action === "NEW")
			?.reduce(
				(acc, curr) => {
					acc = {
						...acc,
						amount: acc.amount + (getMonthlyDiscountedPrice(curr) || 0),
						currency: acc.currency || curr?.prices?.MONTHLY?.currency,
					};
					return acc;
				},
				{
					amount: -getMonthlyDiscount(getters?.validatedOrder?.orderInformation?.offers[0]),
					currency: "",
					priceType: "MONTHLY",
					vat: null,
				},
			);
	},

	totalMonthlyChangeCostWithoutDiscounts(state, getters) {
		return getters?.validatedOrder?.orderInformation?.offers[0]?.products
			?.filter((product) => product?.action === "NEW")
			?.reduce(
				(acc, curr) => {
					acc = {
						...acc,
						amount: acc.amount + (getMonthlyPrice(curr) || 0),
						currency: acc.currency || curr?.prices?.MONTHLY?.currency,
					};
					return acc;
				},
				{
					amount: -getMonthlyDiscount(getters?.validatedOrder?.orderInformation?.offers[0]),
					currency: "",
					priceType: "MONTHLY",
					vat: null,
				},
			);
	},
	totalMonthlyDiscountedCost: (state, getters) => getters?.validatedOrder?.orderInformation?.totalMonthlyDiscountedCost,

	totalMonthlyCostWithoutDiscounts: (state, getters) =>
		getters.validatedOrder?.orderInformation?.totalMonthlyCostWithoutDiscounts,

	totalOneTimeDiscountedCost: (state, getters) => getters?.validatedOrder?.orderInformation?.totalOneTimeDiscountedCost,

	totalOneTimeCostWithoutDiscounts: (state, getters) =>
		getters?.validatedOrder?.orderInformation?.totalOneTimeCostWithoutDiscounts,

	totalCostFirstYear: (state, getters) => getters?.validatedOrder?.orderInformation?.totalCostFirstYear,

	totalFreightCost: (state, getters) => getters?.validatedOrder?.orderInformation?.totalFreightCost,

	validatedAddons: (state, getters) => getAddonProducts(getters?.validatedOrder),
};
