import streamingPlusIntegration from "~/integrations/streaming-plus";
import { _fetchData } from "~/helpers/store";

const getDefaultState = () => {
	return {
		promise: null,
		data: [],
		error: null,
		selectedSubscribedBundleId: null,
		latestSelectedProviderId: null,
		planVariantsMarkedForAddition: [],
		planVariantsMarkedForRemoval: [],
	};
};
export const state = getDefaultState;
export const actions = {
	async fetchData(context, { force }) {
		return _fetchData(() => streamingPlusIntegration.fetchStreamingPlusSubscribedBundles(), {
			context,
			force,
			name: "streaming-plus-subscribed-bundles",
		});
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	resetMarkedPlanVariants(state) {
		state.planVariantsMarkedForAddition = [];
		state.planVariantsMarkedForRemoval = [];
	},
	setSelectedSubscribedBundleId(state, id) {
		state.selectedSubscribedBundleId = id;
	},
	setLatestSelectedProviderId(state, providerId) {
		state.latestSelectedProviderId = providerId;
	},
	//planVariant = {variantId: String, planId: String, providerId: String}
	pushPlanVariantsMarkedForAddition(state, planVariant) {
		if (state.planVariantsMarkedForAddition.find((p) => p.variantId === planVariant.variantId)) {
			console.error("PlanVariant already marked for addition", `PlanVariant: ${planVariant.variantId}`);
			return;
		} else {
			state.planVariantsMarkedForAddition.push(planVariant);
		}
	},
	splicePlanVariantsMarkedForAddition(state, planVariant) {
		for (let i = 0; i < state.planVariantsMarkedForAddition.length; i++) {
			if (state.planVariantsMarkedForAddition[i].providerId === planVariant.providerId) {
				state.planVariantsMarkedForAddition.splice(i, 1);
			}
		}
	},
	pushPlanVariantsMarkedForRemoval(state, planVariant) {
		if (state.planVariantsMarkedForRemoval.find((p) => p.variantId === planVariant.variantId)) {
			console.error("PlanVariant already marked for removal", `PlanVariant: ${planVariant.variantId}`);
			return;
		} else {
			state.planVariantsMarkedForRemoval.push(planVariant);
		}
	},
	splicePlanVariantsMarkedForRemoval(state, planVariant) {
		for (let i = 0; i < state.planVariantsMarkedForRemoval.length; i++) {
			if (state.planVariantsMarkedForRemoval[i].providerId === planVariant.providerId) {
				state.planVariantsMarkedForRemoval.splice(i, 1);
			}
		}
	},
};
export const getters = {
	data: (state) => {
		return state.data;
	},
	error: (state) => {
		return state.error;
	},
	loading: (state) => {
		return !!state.promise;
	},
	subscribedBundles: (state, getters) => {
		return getters.data?.subscribedBundles;
	},
	selectedSubscribedBundleId: (state) => {
		return state.selectedSubscribedBundleId;
	},
	selectedSubscribedBundle: (state, getters) => {
		return getters.subscribedBundles?.find((bundle) => bundle?.bundleId === getters.selectedSubscribedBundleId) || null;
	},
	isSubscribed: (state, getters) => {
		return !!getters.selectedSubscribedBundle;
	},
	planVariantsMarkedForAddition: (state) => {
		return state.planVariantsMarkedForAddition;
	},
	subscribedPlanVariants: (state, getters) => {
		return getters.selectedSubscribedBundle?.subscribedPlanVariant || [];
	},
	planVariantsMarkedForRemoval: (state) => {
		return state.planVariantsMarkedForRemoval;
	},
	getPlanVariantWithContentByVariantId: (state, getters, rootState, rootGetters) => (variantId) => {
		const getPlanVariantByVariantId =
			rootGetters["mittTelenor/streamingPlus/streamingPlusBundleCatalog/getPlanVariantByVariantId"];
		const planVariant = getPlanVariantByVariantId(variantId);
		return getters.addContentToPlanVariant(planVariant);
	},
	allSelectedPlanVariantsWithContent: (state, getters) => {
		const allSelectedPlanVariants = [
			...getters.subscribedPlanVariants,
			...getters.planVariantsMarkedForAddition,
		].filter(
			(planVariant) =>
				planVariant.variantId !==
				getters.planVariantsMarkedForRemoval.find((p) => p.variantId === planVariant.variantId)?.variantId,
		);
		return allSelectedPlanVariants.map((planVariant) => getters.addContentToPlanVariant(planVariant));
	},
	planVariantsMarkedForRemovalWithContent: (state, getters) => {
		return getters.planVariantsMarkedForRemoval.map((planVariant) => getters.addContentToPlanVariant(planVariant));
	},
	addContentToPlanVariant: (state, getters, rootState, rootGetters) => (planVariant) => {
		const getMappedProviderContentAndCatalogByProviderId =
			rootGetters[
				"mittTelenor/streamingPlus/streamingPlusProviderContent/getMappedProviderContentAndCatalogByProviderId"
			];
		const provider = getMappedProviderContentAndCatalogByProviderId(planVariant?.providerId);
		const plan = provider.plans?.find((plan) => plan.planId === planVariant.planId);
		const variant = plan?.variants?.find((variant) => {
			return variant.variantId === planVariant.variantId;
		});
		const planVariantWithContent = {
			providerId: planVariant?.providerId,
			planId: planVariant?.planId,
			variantId: planVariant?.variantId,
			lockedUntil: planVariant?.lockedUntil,
			lockedReason: planVariant?.lockedReason,
			logo: plan?.logo || provider?.logo,
			provider: provider,
			plan: plan,
			variant: variant,
		};
		return planVariantWithContent;
	},
	totalPoints: (state, getters, rootState, rootGetters) => {
		const selectedAvailableBundle =
			rootGetters["mittTelenor/streamingPlus/streamingPlusBundleCatalog/selectedAvailableBundle"];
		return (
			selectedAvailableBundle?.includedPointCount || 0 + (getters.selectedSubscribedBundle?.extraPointsBought || 0)
		);
	},
	usedPoints: (state, getters) => {
		return getters.allSelectedPlanVariantsWithContent.reduce(
			(acc, planVariant) => acc + planVariant.variant.pointCost,
			0,
		);
	},
	subscribedUsedPoints: (state, getters) => {
		return getters.selectedSubscribedBundle?.usedPointCount || 0;
	},
	remainingPoints: (state, getters) => {
		return getters.totalPoints - getters.usedPoints;
	},
	latestSelectedProviderId: (state) => {
		return state.latestSelectedProviderId;
	},
	changesExist: (state) => {
		return !!state.planVariantsMarkedForAddition.length || !!state.planVariantsMarkedForAddition.length;
	},
	getLockedUntilByVariantId: (state, getters) => (variantId) => {
		return getters.allSelectedPlanVariantsWithContent.find((p) => p.variantId === variantId)?.lockedUntil;
	},
};
