import scopes from "@/telenorid/scopes";

import testMetadata from "~/telenorid/test.metadata";
import prodMetadata from "~/telenorid/prod.metadata";

export default (domain) => {
	const IS_PROD_ENV = [
		"www.telenor.no",
		"telenor.no",
		"staging.telenor.no",
		"tnweb-nuxt3-staging.web.apc.telenor.net",
	].includes(domain);

	let authority = "https://id-test.telenor.no";
	let metadata = testMetadata;

	if (IS_PROD_ENV) {
		authority = "https://id.telenor.no";
		metadata = prodMetadata;
	}

	return {
		authority,
		IS_PROD_ENV,
		client_id: "tnn-openweb-selfservice-web",
		silent_redirect_uri: `https://${domain}/silentLoginCallback`,
		redirect_uri: `https://${domain}/loginCallback`,
		popup_redirect_uri: `https://${domain}/popupLoginCallback`,
		popup_signout_redirect_uri: `https://${domain}/popupLogoutCallback`,
		post_logout_redirect_uri: `https://${domain}/logoutCallback`,
		response_type: "code",
		scope: scopes.join(" "),
		loadUserInfo: true,
		metadata,
	};
};
