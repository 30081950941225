import fixedOrderSearch from "~/integrations/fixed-order-search";

export const state = () => ({
	promises: {},
	fetched: [],
	errors: [],
	orders: [],
});
export const actions = {
	async getOrders({ commit, getters }, orderType) {
		if (!getters.isFetched(orderType)) {
			const promise = getters.getEndPoint(orderType)();
			commit("setPromise", { name: orderType, promise });
			return promise
				.then((orders) => {
					commit("updateOrders", orders);
					commit("setFetched", orderType);
					commit("removeError", orderType);
				})
				.catch((error) => {
					console.error(error);
					commit("setError", orderType);
				})
				.finally(() => commit("removePromise", orderType));
		} else if (getters.isLoading(orderType)) getters.getPromise(orderType);
		else return getters.getOrders(orderType);
	},
};
export const mutations = {
	updateOrders(state, orders) {
		state.orders = [...state.orders, ...orders];
	},
	setPromise(state, { name, promise }) {
		state.promises[name] = promise;
	},
	setError(state, name) {
		state.errors = [...state.errors, name];
	},
	removeError(state, name) {
		state.errors = [...state.errors.filter((error) => error !== name)];
	},
	removePromise(state, name) {
		delete state.promises[name];
	},
	setFetched(state, name) {
		state.fetched = [...state.fetched, name];
	},
};
export const getters = {
	orderTypes() {
		return { SUBSCRIPTION: "SUBSCRIPTION" };
	},
	isFetched(state, getters) {
		return (orderType) => state.fetched.includes(getters.orderTypes[orderType]);
	},
	isLoading(state) {
		return (orderType) => Object.keys(state.promises).includes(orderType);
	},
	getPromise(state) {
		return (orderType) => state.promises[orderType];
	},
	getOrders(state) {
		return (orderType) => state.orders.filter((order) => order.orderType === orderType) || [];
	},
	getSubscriptionOrders(state) {
		return state.orders.map((order) => ({
			...order,
			address: { ...order.installationAddress, addressLine1: order.installationAddress?.streetAddress },
		}));
	},
	getEndPoint(state, getters) {
		//Equipment is probably coming soon
		return (orderType) => {
			if (orderType === getters.orderTypes.SUBSCRIPTION) return fixedOrderSearch.getSubscriptionOrders;
		};
	},
	getOrder(state) {
		return (orderId) => state.orders.find((order) => order.orderId === orderId);
	},
	projectOrderHasBinding(state, getters) {
		return (orderId) =>
			getters.getOrder(orderId)?.productChanges.some((product) => product.productCategory === "BINDING");
	},
};
