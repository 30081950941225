import { defineNuxtPlugin } from "#app";

export default defineNuxtPlugin(({ store, $config, vueApp }) => {
	/*

        This directive is used to enable inline editing for text fields.
        Do not use this for richtext-fields. In those cases, use TnRichTextEditor.

        You can provide a string with a JSON path relative to the component root to this directive.

        For example: 'content.header'.

        Or you can omit this string and use it without a value. In that case Gizmo will resolve the correct key
        based on the old value. This has some limitations:

        - It's a bit slower (but shouldn't be noticeable)
        - The field MUST have an existing value in order to be resolved
        - If, for some strange reason, you have more than one of this value in the same component, updates will
         only be done to the first occurrence

         */

	vueApp.directive("editable-string", {
		mounted(el, binding, vnode) {
			if ($config.public.isPreview && !store?.state?.selectTriggerElement) {
				let oldValue = !binding.value && el.innerText;

				if (!oldValue && !binding.value) return;

				el.contentEditable = store.state.gizmo.editingEnabled ? "true" : "false";
				el.addEventListener("click", (event) => {
					event.stopPropagation();
				});

				store.subscribe((mutation, state) => {
					if (mutation.type === "gizmo/setEditingEnabled") el.contentEditable = mutation.payload ? "true" : "false";
				});

				el.classList += " editable-property";
				el.title = "Click to edit";

				el.addEventListener("focusout", (event) => {
					let value = el.innerText.replace("&nbsp;", "");
					//vnode.context.$emit("contentChanged");
					el.dispatchEvent(
						new CustomEvent("contentChanged", {
							detail: {
								prop: binding.value,
								oldValue,
								unknownProp: !binding.value,
								value,
							},
							bubbles: true,
							composed: true,
						}),
					);
					oldValue = !binding.value && value;
				});
			}
		},
	});
});
