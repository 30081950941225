const EpostConstants = {
	genericMessage: {
		apiCallError: {
			title: "Tekniske feil",
			message:
				"Vi beklager! Noe gikk galt og vi kunne ikke laste denne modulen. Prøv igjen senere. Vennligst kontakt kundeservice dersom feilen fortsetter.",
		},
	},

	// account status
	STATUS: {
		ACTIVE: "ACTIVE", // in-use accounts
		SUSPENDED: "SUSPENDED", // barred
		DEACTIVATED: "INACTIVE", // terminated | inactive
	},
	// account rights / permissions
	RIGHTS: {
		OWNER: "OWNER", // owns & pays account only
		USER: "USER", // uses account only, no administer rights in terms of package changes etc
		OWNER_USER: "OWNER_AND_USER", // owns, pays, uses account,
		PAYER: "PAYER",
	},

	// functional actions
	ACTIONS: {
		CHANGE_PASSWORD: "CHANGE_PASSWORD",
		CHANGE_MOBILENUMBER: "CHANGE_MOBILENUMBER",
		CHANGE_USER: "CHANGE_USER",
		UPDATE_ALIAS: "UPDATE_ALIAS",
		DEACTIVATE_ACCOUNT: "DEACTIVATE_ACCOUNT",
	},
	// functional navigation links
	PAGES: {
		HOME: "/mitt-telenor/",
		DASHBOARD: "/mitt-telenor/e-post/",
		CHANGE_PASSWORD: "/mitt-telenor/e-post/instillinger/endre-passord/?accountName=",
		CHANGE_MOBILENUMBER: "/mitt-telenor/e-post/instillinger/endre-mobilenummer/?accountName=",
		SETTINGS: "/mitt-telenor/e-post/instillinger/?accountName=",
		ADD_EXISTING_EMAIL: "/mitt-telenor/e-post/",
		NEW_ACCOUNT: "/mitt-telenor/e-post/opprett-e-postkonto/",
		CHANGE_SUBSCRIPTION: "/mitt-telenor/e-post/instillinger/endre-pakke/?accountName=",
		DEACTIVATE_ACCOUNT: "/mitt-telenor/e-post/slett-konto/?accountName=",
	},
};
export default EpostConstants;
