import axios from "axios";

import { setupCache } from "axios-cache-interceptor";

const api = axios.create({
	baseURL: "/api",
});

if (import.meta.client) {
	setupCache(api, {
		maxAge: 15 * 60 * 1000, // 15 minutes,
		exclude: {
			query: false,
		},
		cacheTakeover: false, // TODO: Remove when Apigee fixes cors pragma issue
	});
}

export default api;
