import streamingPlusIntegration from "~/integrations/streaming-plus";
import { _fetchData } from "~/helpers/store";

const getDefaultState = () => {
	return {
		promise: null,
		data: [],
		error: null,
	};
};
export const state = getDefaultState;
export const actions = {
	async fetchData(context, force = false) {
		return _fetchData(() => streamingPlusIntegration.fetchStreamingPlusProviderContent(), {
			context,
			force,
			name: "streaming-plus-provider-content",
		});
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
};
export const getters = {
	data(state) {
		return state.data;
	},
	error(state) {
		return state.error;
	},
	loading(state) {
		return !!state.promise;
	},
	getProviderContentByProviderId: (state, getters) => (providerId) => {
		return getters?.data?.contents?.find((providerContent) => providerContent.providerId === providerId);
	},
	getMappedProviderContentAndCatalogByProviderId: (state, getters, rootState, rootGetters) => (providerId) => {
		const providerCatalog =
			rootGetters["mittTelenor/streamingPlus/streamingPlusBundleCatalog/getProviderCatalogByProviderId"](providerId);
		const providerContent = getters.getProviderContentByProviderId(providerId);
		const mappedPlanVariantPlans = providerCatalog?.plans?.map((providerCatalogPlan) => {
			const providerContentPlan = providerContent?.plans?.find(
				(providerContentPlan) => providerContentPlan?.planId === providerCatalogPlan?.planId,
			);
			const mappedPlan = {
				...providerCatalogPlan,
				...providerContentPlan,
			};
			mappedPlan.variants = providerCatalogPlan?.variants
				?.map((catalogVariant) => {
					const contentVariant = providerContentPlan?.variants?.find(
						(contentVariant) => contentVariant.variantId === catalogVariant.variantId,
					);
					return { ...catalogVariant, ...contentVariant };
				})
				.sort((a, b) => b.variantLevel - a.variantLevel);
			return mappedPlan;
		});
		return {
			...providerContent,
			plans: mappedPlanVariantPlans?.sort((a, b) => a.planLevel - b.planLevel),
		};
	},
};
