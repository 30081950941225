import tvContent from "~/integrations/tv-content";
import { _fetchData } from "~/helpers/store";

const getDefaultState = () => {
	return {
		promise: null,
		data: [],
		error: null,
	};
};

export const state = getDefaultState;

export const actions = {
	async fetchData(context, { force, subscriptionId, channels, isTweBoxWebApp = false }) {
		if (!channels?.length) {
			return;
		}
		const getRequest = (channelId) => {
			return isTweBoxWebApp
				? tvContent.getTvContentThirdPartyStatusByProductIdStb(channelId)
				: tvContent.getTvContentThirdPartyStatusByProductId(subscriptionId, channelId);
		};
		return _fetchData(
			() => {
				const requests = channels.map((channel) => {
					return new Promise((resolve) => {
						getRequest(channel.id)
							.then((result) => {
								if (result?.length) {
									resolve(result?.[0]);
								} else {
									resolve({
										providerName: channel?.thirdPartyInfo?.providerName,
										id: channel.id,
										error: { response: { data: { message: "Not found" } } },
									});
								}
							})
							.catch((error) => {
								console.error(error);
								resolve({
									providerName: channel?.thirdPartyInfo?.providerName,
									id: channel.id,
									error: error,
								});
							});
					});
				});
				return Promise.all(requests);
			},
			{
				context,
				force,
				name: "tv-content-third-party-status",
			},
		);
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	},
};
export const getters = {
	data: (state) => {
		return state.data;
	},
	error: (state) => {
		return state.error;
	},
	loading: (state) => {
		return !!state.promise;
	},
	getThirdPartyStatusByChannelId: (state) => (channelId) => {
		return state.data.find((status) => status.id === channelId);
	},
	getThirdPartyStatusErrors: (state) => {
		return state.data.filter((status) => status?.error);
	},
};
