import axiosClient from "axios";
import adapterHelper from "@/integrations/adapters/adapterHelper";
export default function (baseURL) {
	let apiUrl = adapterHelper.apiUrl();

	const axios = axiosClient.create({
		baseURL: apiUrl + baseURL,
		withCredentials: true,
	});

	axios.interceptors.request.use(
		async (request) => {
			const accessToken = window.$nuxt.$store.state.mittTelenor.fixed.stb.stbAuth.accessToken;
			if (accessToken) {
				request.headers.Authorization = "Bearer " + accessToken;
			} else {
				// Cancel request`
				return {
					...request,
					cancelToken: new axiosClient.CancelToken((cancel) =>
						cancel("Cancelled request because no authentication token is present. URL: " + request.url),
					),
				};
			}

			return request;
		},
		(error) => {
			return Promise.reject(error);
		},
	);

	axios.interceptors.response.use(
		function (response) {
			return response.data;
		},
		async function (error) {
			if (error.response && error.response.status === 401) {
				window.$nuxt.$store.commit("mittTelenor/fixed/stb/stbAuth/setError", error);
				return Promise.reject(error);
			}
			return Promise.reject(error);
		},
	);
	return axios;
}
