import apigeeIntegration from "./self-service-system-adapter";

const api = apigeeIntegration("/dealersearch/v2");

const getAll = async () =>
	api.get("/dealers/all", {
		cache: { override: true },
	});

const getById = (id) =>
	api.get(`/dealers/${id}`, {
		cache: { override: true },
	});

const getClosest = async (latitude, longitude, size = 1, isConsumer = true) => {
	const q = {
		size,
		query: {
			bool: {
				must: [
					{
						term: {
							"dealerRole.keyword": "Forhandler",
						},
					},
				],
				should: [],
				filter: [
					{
						exists: {
							field: "address.location",
						},
					},
				],
				minimum_should_match: 1,
			},
		},
		sort: [
			{
				_geo_distance: {
					"address.location": {
						lat: latitude,
						lon: longitude,
					},
					order: "asc",
					unit: "km",
					mode: "min",
					distance_type: "arc",
					ignore_unmapped: true,
				},
			},
		],
	};

	if (isConsumer) {
		q.query.bool.must.push({
			match: {
				"chain.name": "Telenorbutikken",
			},
		});

		q.query.bool.should.push({
			term: {
				"segment.keyword": "ALL",
			},
		});

		q.query.bool.should.push({
			term: {
				"segment.keyword": "CONSUMER",
			},
		});
	} else {
		q.query.bool.should.push({
			term: {
				"segment.keyword": "CORPORATE",
			},
		});
	}

	return api.post("/dealers/search", q, {
		cache: { override: true },
		headers: {
			"x-apicache-bypass": true,
		},
	});
};

const search = async (query, from = 0, size = 10, tnbOnly = true) => {
	const q = {
		from,
		size,
		query: {
			bool: {
				must: [
					{
						query_string: {
							query: query || "*",
						},
					},
					{
						term: {
							"dealerRole.keyword": "Forhandler",
						},
					},
				],
				filter: [
					{
						exists: {
							field: "address.location",
						},
					},
				],
				should: [],
				minimum_should_match: 1,
			},
		},
	};

	if (tnbOnly) {
		q.query.bool.filter.push({
			match: {
				"chain.name": "Telenorbutikken",
			},
		});

		q.query.bool.should.push({
			term: {
				"segment.keyword": "ALL",
			},
		});

		q.query.bool.should.push({
			term: {
				"segment.keyword": "CONSUMER",
			},
		});
	} else {
		q.query.bool.should.push({
			term: {
				"segment.keyword": "CORPORATE",
			},
		});
	}

	return api.post("/dealers/search", q, {
		cache: { override: true },
		headers: {
			"x-apicache-bypass": true,
		},
	});
};

export default {
	getAll,
	getClosest,
	search,
	getById,
};
