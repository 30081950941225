import apigeeIntegration from "./self-service-adapter";
const api = apigeeIntegration("/security-subscriptions/v1");

// get Offers
const getOffers = () => api.get("/products/offers");
const getOffersBySubscriptionId = (subscriptionId) => api.get(`/products/${subscriptionId}/offers`);
// get subscribed products
const getProducts = () => api.get(`/products?time=${new Date().getTime()}`);
// create new order
const createNewOrder = (body) => api.post("/products", body);
// switch to different subscription
const createChangeSubscriptionOrder = (subscriptionId, body) => api.post(`/products/${subscriptionId}`, body);
// deactivate
const terminate = (subscriptionId) => api.delete(`/products/${subscriptionId}?isValidation=false`);

export default {
	getOffers,
	getOffersBySubscriptionId,
	getProducts,
	createNewOrder,
	createChangeSubscriptionOrder,
	terminate,
};
