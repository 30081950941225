import trackingHandler from "./tracking-handler";
import { onLoad } from "~/helpers/onLoad.js";
import { trackingPluginExclusion as EXCLUDED_ROUTES, routeIsExcluded } from "../../config/excludedRoutes.js";
import {
	updateProductTitle,
	mapProduct,
	getSubCategory,
	updateProductGradingValue,
} from "~/components/ecommerce/helpers/ecommerceTrackingHelper.js";

import abTestTracking from "@telenornorgeinternal/tn-gizmo-abtest-plugin";

export default defineNuxtPlugin(({ $store, $router, vueApp, $config }) => {
	const route = useRoute();

	let currentPage = route;
	let tracking = trackingHandler({
		$store,
		config: $config.public,
		path: currentPage.path,
	});

	if (import.meta.client) window.utag_cfg_ovrd = { noview: true };

	const init = function (a, b, c, d) {
		a = `//tags.tiqcdn.com/utag/telenor/${tracking.tealium_profile || "telenor.no"}/${
			tracking.tealium_env || "prod"
		}/utag.js`;
		b = document;
		c = "script";
		d = b.createElement(c);
		d.src = a;
		d.type = "text/javascript";
		d.async = true;
		a = b.getElementsByTagName(c)[0];
		a.parentNode.insertBefore(d, a);
	};

	if (currentPage.name.indexOf("esim-microsoft") < 0)
		onLoad(() => tracking.use_tealium && !$config.public.isPreview && init());

	const track = async (data, type, customTokens) => {
		if (!tracking.use_tealium && $config.public.isPreview) return;

		const pageData = await getPageData(currentPage, customTokens);
		const trackingData = { ...pageData, ...data };
		sendTracking(trackingData, type, 0, true);
	};

	vueApp.config.globalProperties.$track = track;

	let sendTrackingTimeout;

	const sendTracking = (value, type = "view", loop = 0, force = false) => {
		const metadata = $store.state.page.metadata;
		try {
			if (loop < 100) {
				if (abTestTracking.vuePageIsAbTest($store.state.page)) abTestTracking.frontend($store.state.page, value);
				else if (force || !metadata?.disableAnalyticsPageLoad) {
					window.utag[type](value);
					clearTimeout(sendTrackingTimeout);
				}
			}

			if ($config.public.enableTrackingDebugging) {
				console.table({
					...{ ":: UTAG METHOD ::": type.toUpperCase() },
					...value,
				});
			}
		} catch (e) {
			sendTrackingTimeout = setTimeout(function () {
				sendTracking(value, type, ++loop, force);
			}, 100);
		}
	};

	async function sendPageData(page) {
		if (routeIsExcluded(page, EXCLUDED_ROUTES, true)) return;

		const data = await getPageData(page);
		if (!data || (data && data.page_name === "Hjem:")) return; // No pagename set, skip tracking

		const metadata = $store.state.page.metadata;

		if (metadata) {
			data.article_shared_category = metadata.analyticsCategory;
			data.article_main_category = metadata.category;
			if (metadata.subcategories) data.article_sub_category = metadata.subcategories.join(",");
		}
		sendTracking(data);
	}

	async function getPageData(page, customTokens) {
		if ($store.state.customer && !$store.getters["contactDetails/contactDetails"]) {
			await $store.dispatch("contactDetails/setContactDetails");
		}

		tracking = trackingHandler({
			$store,
			config: $config.public,
			path: page.path,
			customPageTokens: customTokens,
		});

		if (tracking.use_tealium) {
			const base = {
				server: tracking.server,
				site_section: tracking.site_section,
				page_name: tracking.page_name,
				page_hierarchy: tracking.page_hierarchy,
			};

			if (tracking.gizmo_segment) base.gizmo_segment = tracking.gizmo_segment;

			if (tracking.kurt) base.kurt = tracking.kurt;

			if (tracking.gizmo_segmented_content_ID) base.gizmo_segmented_content_ID = tracking.gizmo_segmented_content_ID;

			if (tracking.gizmo_segmented_component_name)
				base.gizmo_segmented_component_name = tracking.gizmo_segmented_component_name;

			if (tracking.hashed_emailAddress) base.hashed_emailAddress = tracking.hashed_emailAddress;

			if (tracking.hashed_phoneNumber) base.hashed_phoneNumber = tracking.hashed_phoneNumber;

			if ($store.state.ecommerce?.product?.id) {
				const product = $store.state.ecommerce?.product;

				const productTitle = updateProductTitle(product.title);

				base.inventory = product.inventory?.[0]?.amount;
				base.pageUrl = $config.public.domain + $store.state.page.publishUrl;
				base.categoryId = `${product.category} > ${product.brand}`;
				base.value = Math.round(product.salePriceWithVat) || Math.round(product.priceWithVat);
				base.sc_events = {
					prodView: 1,
				};
				base.message = productTitle;

				base.productName = productTitle;
				base.hardwareProductId = product.fallbackId;

				base.products = mapProduct(
					product.category,
					productTitle,
					product.id,
					Math.round(product.salePriceWithVat) || Math.round(product.priceWithVat),
					product.brand,
					product.cleanName,
					product.memory,
					product.color,
					getSubCategory(product.categories),
					null,
					null,
					updateProductGradingValue(product.condition),
				);
				base.productCategories = [product.category];
				base.productNames = [productTitle];
				base.productIDs = [product.id];
				base.productCounts = [1];
				base.productPrices = [Math.round(product.salePriceWithVat) || Math.round(product.priceWithVat)];
			}

			return base;
		}
	}

	return {
		provide: {
			track,
			sendPageData,
		},
	};
});
