import clickAndCollectIntegration from "~/integrations/click-and-collect-integration";
import shippingIntegration from "~/integrations/shipping-integration";
import { getPriceObject } from "~/helpers/ecommerce/productPrices";
import moment from "moment";

const getDefaultProductState = () => {
	return {
		accessories: [],
		accessoryFor: [],
		additionalfilters: [],
		allowCollectAtStore: false,
		attributes: {},
		brand: "",
		bundleContent: [],
		categories: [],
		category: "",
		categordyIds: [],
		categorynames: [],
		cleanName: "",
		clickAndCollectStores: [],
		color: "",
		colorCode: "",
		condition: "",
		createdDate: "",
		description: "",
		descriptionSummary: "",
		fallbackId: "",
		gtin: "",
		id: null,
		ignoreInventory: false,
		imageLink: "",
		imageLink400pxHeight: "",
		images: [],
		itemGroupId: -1,
		link: "",
		loading: false,
		loadingClickAndCollect: true,
		memory: "",
		model: "",
		numberOfStoresWithProduct: 0,
		parentCategory: "",
		parentId: -1,
		price: "",
		priceWithVat: "",
		priority: "0",
		rating: -1,
		recommendedAccessories: [],
		requiresItemInStock: false,
		reviewCount: -1,
		rootCategory: "",
		salePrice: "",
		salePriceWithVat: "",
		sapProductId: "",
		size: "",
		sizes: [],
		stockValue: -1,
		swapDiscountedMonthlyPrice: -1,
		swapDiscountedTotalPrice: -1,
		swapMonthlyPrice: -1,
		swapTotalPrice: -1,
		title: "",
		type: "",
		updatedDate: "",
		variantParentId: "",
		variants: [],
		webonProductId: "",
		memberPrice: undefined,
	};
};

export const state = () => {
	return {
		product: getDefaultProductState(),
		detailsTab: 0,
		totalDetailsTabs: 0,
		variantAccessories: [],
		selectedWebShopOption: "SWAP",
		clickAndCollectModalOpen: false,
		deliveryAvailability: null,
		deliveryPostalCode: "",
		loadingPorterBuddyAvailability: false,
		categoriesThatDontRequireStock: ["Mobiltelefoner", "Smartklokker", "Nettbrett", "Ruter"],
		categoriesThatRequireStock: ["God Som Ny", "Lyd", "Tilbehør"],
	};
};
export const mutations = {
	setProduct(state, product) {
		state.product = {
			...product,
			prices: getPriceObject(product),
			clickAndCollectStores: [],
			loadingClickAndCollect: false,
		};
	},
	setDetailsTab(state, tab) {
		state.detailsTab = tab;
	},
	setTotalDetailsTabs(state, total) {
		state.totalDetailsTabs = total;
	},
	setSelectedWebShopOption(state, value) {
		state.selectedWebShopOption = value;
	},
	setLoadingClickAndCollect(state, loading) {
		state.product.loadingClickAndCollect = loading;
	},
	setClickAndCollectStores(state, stores) {
		state.product.clickAndCollectStores = stores;
	},
	resetProduct(state) {
		Object.assign(state.product, getDefaultProductState());
	},
	setVariantAccessories(state, accessories) {
		state.variantAccessories = accessories;
	},
	toggleClickAndCollectModal(state) {
		state.clickAndCollectModalOpen = !state.clickAndCollectModalOpen;
	},
	setNumberOfStoresWithProduct(state, value) {
		state.product.numberOfStoresWithProduct = value || 0;
	},
	setDeliveryAvailability(state, value) {
		state.deliveryAvailability = value;
	},
	setDeliveryPostalCode(state, value) {
		state.deliveryPostalCode = value;
	},
	setLoadingPorterBuddyAvailability(state, value) {
		state.loadingPorterBuddyAvailability = value;
	},
};
export const actions = {
	async getNumberOfStoresWithProduct({ commit }, hardwareProductId) {
		commit("setLoadingClickAndCollect", true);

		if (!hardwareProductId) return;

		try {
			const { dealerCount } = await clickAndCollectIntegration.checkAvailability(hardwareProductId);
			commit("setNumberOfStoresWithProduct", dealerCount);
		} catch (e) {
			console.error(e);
		} finally {
			commit("setLoadingClickAndCollect", false);
		}
	},
	setVariantAccessories({ commit }, payload) {
		commit("setVariantAccessories", payload);
	},
	setTotalDetailsTabs({ commit }, payload) {
		commit("setTotalDetailsTabs", payload);
	},
	async checkPorterBuddyDeliveryAvailability({ commit }, postalCode) {
		const isPostalCodeValid = /^[0-9]{4}$/.test(postalCode);

		if (isPostalCodeValid) {
			commit("setLoadingPorterBuddyAvailability", true);

			try {
				const res = await shippingIntegration.porterBuddy.availability({ postalCode });
				commit("setDeliveryAvailability", res);
				commit("setDeliveryPostalCode", postalCode);
			} catch (e) {
				console.error(e);
			} finally {
				commit("setLoadingPorterBuddyAvailability", false);
			}
		}
	},
};
export const getters = {
	segment: (state, getters, rootState) => {
		return {
			privat: "consumer",
			bedrift: "business",
		}[rootState.siteSegment];
	},
	productHasNoAccessories(state) {
		return state.product.id && state.product.accessories.length === 0 && state.variantAccessories.length === 0;
	},
	productHasAccessories(state) {
		return (state.product.id && state.product.accessories.length > 0) || state.variantAccessories.length > 0;
	},
	productMemberPrice(state) {
		const today = moment();
		const activeMemberPrice = today.isBetween(
			moment(state.product?.memberPrice?.startDate),
			moment(state.product?.memberPrice?.endDate),
			null,
			"[]",
		);
		return activeMemberPrice && state.product?.memberPrice ? state.product?.memberPrice : undefined;
	},
	isDeliveryWindowOpen(state) {
		if (!state.deliveryAvailability) {
			return false;
		}

		return state.deliveryAvailability?.deliveryWindows?.some((deliveryWindow) =>
			moment(deliveryWindow.expiresAt).isAfter(),
		);
	},
	firstDeliveryWindow(state) {
		if (!state.deliveryAvailability) {
			return null;
		}

		return state.deliveryAvailability?.deliveryWindows?.reduce((earliest, current) => {
			return moment(current.start).isBefore(moment(earliest.start)) ? current : earliest;
		});
	},
};
