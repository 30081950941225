import apigeeSelfServiceIntegration from "./self-service-adapter";
import apigeeSystemIntegration from "./self-service-system-adapter";

const selfServiceApi = apigeeSelfServiceIntegration("/nettvern-report/v1");
const systemAPI = apigeeSystemIntegration("/nettvern-report/v1");

const getReportForLoggedInUser = async () => selfServiceApi.get();

const getReportForLoggedOutUser = async () => systemAPI.get();

export default {
	getReportForLoggedInUser,
	getReportForLoggedOutUser,
};
