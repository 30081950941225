export default function validateImeiNumber(input) {
	if (!input) return false;
	let number = input.replace(/\s/g, "");
	if (!new RegExp(/^[0-9]*$/, "g").test(number))
		// Only numbers
		return false;
	if (!new RegExp(/^\d{15}$/, "g").test(number))
		// 15 numbers
		return false;
	try {
		number = parseInt(number);
		number = number
			.toString()
			.split("")
			.map((i) => {
				return parseInt(i);
			});
	} catch (e) {
		return false;
	}
	// Preforming Luhn algorithm validation
	// https://en.wikipedia.org/wiki/Luhn_algorithm
	let controlNumber = number[number.length - 1];
	number.pop(); // removing control number from calculations;
	number = number.map((num, index) => {
		return (index + 1) % 2 === 0 ? num * 2 : num; // Every second number, multiply by two
	});
	let numberString = number.join("");
	number = numberString
		.toString()
		.split("")
		.map((i) => {
			return parseInt(i);
		});
	let sum = number.reduce((a, b) => {
		return a + b;
	});
	const roundUpTens = Math.ceil(sum / 10) * 10;
	return roundUpTens - sum === controlNumber;
}
