import apigeeIntegration from "./self-service-adapter";
import selfServiceAdapter from "./self-service-system-adapter";
import { FixedSaveV1ApiFactory } from "@telenornorgeinternal/fixed-save-api/dist/api/fixed-save-v1-api";
import systemReCaptcha from "./self-service-system-recaptcha";

const api = apigeeIntegration("/productfeasibility/v2");
const fixedSave = apigeeIntegration("/fixed-save");
const systemReCaptchaApi = systemReCaptcha("/productfeasibility/v2");
const selfServiceApi = selfServiceAdapter("/productfeasibility/v2");
const getAvailableOffersForAddressAndCampaign = async ({ addressId, campaignId, channelId }) =>
	api.get(`/productfeasibility/v1/${addressId}/offers/${campaignId}?channelId=${channelId}`);
const getExistingOffersForSubscription = async (subscriptionId) =>
	api.get(`/subscription/${subscriptionId}/offers`, {
		cache: { override: true },
		timeout: 35000,
	});

const getNewOffersForAddress = async (farId) => api.get(`/address/${farId}/offers`);

const hostnamesUsingSelfServiceApi = ["localhost", "tnweb-nuxt-sdu-acquire-and-retain.web.test.apc.telenor.net"];

// rcToken :: reCaptcha token
const getNewOffersForLoggedOutCustomers = async (farId, rcToken) => {
	// Locally we want to go through the selfServiceApi
	if (hostnamesUsingSelfServiceApi.includes(window.location.hostname)) {
		console.info("Using self-service API");
		return selfServiceApi.get(`/address/${farId}/offers`);
	}
	return systemReCaptchaApi.get(`/address/${farId}/offers`, { rcToken: rcToken });
};

const getOldOffersForLoggedOutCustomers = async (farId, rcToken) =>
	systemReCaptchaApi.get(`/address/${farId}/offers`, { rcToken: rcToken });

const fetchSaveOffers = async (subscriptionId) => {
	const fixedSaveApi = new FixedSaveV1ApiFactory(null, null, fixedSave);

	return fixedSaveApi.getOffers({ subscriptionId });
};

// retrieve?
const fetch = ({ fetcher, isLoggedIn, rcToken, addressId, subscriptionId }) => {
	switch (fetcher) {
		case "newsale":
		case "add-equipment": // TODO(odavig, flyrev): this is a workaround temporarily
			return isLoggedIn ? getNewOffersForAddress(addressId) : getNewOffersForLoggedOutCustomers(addressId, rcToken);
		case "change-bandwidth":
		case "add-tv":
			return getExistingOffersForSubscription(subscriptionId);
		case "termination-save":
			return fetchSaveOffers(subscriptionId);
		default:
			throw Error("Du må laste inn siden på nytt");
	}
};

export default {
	fetch,
	getNewOffersForAddress,
	getExistingOffersForSubscription,
	getAvailableOffersForAddressAndCampaign,
	getNewOffersForLoggedOutCustomers,
	getOldOffersForLoggedOutCustomers,
};
