import mobileSubscriptionApi from "~/integrations/mobile-subscription";
import agreementsV2Api from "~/integrations/agreements-v2";
import handsetsApi from "~/integrations/handsets";
import securitySubscriptionApi from "~/integrations/security-subscriptions-integration";

const getDefaultState = () => {
	return {
		subscriptions: {
			promise: null,
			data: [],
			isFetched: false,
		},
		agreements: {
			promise: null,
			data: [],
			isFetched: false,
		},
		handsets: {
			promise: null,
			data: [],
			isFetched: false,
		},
		securitySubscriptions: {
			promise: null,
			data: [],
			isFetched: false,
		},
	};
};

const hasAgreement =
	(state) =>
	(root, addon = undefined) => {
		return (
			state.agreements.data &&
			state.agreements.data.some((agreement) => {
				const found = [agreement.categories.includes(root)];

				if (typeof addon !== "undefined")
					found.push(agreement.agreementAddons.some((add) => add.categories.includes(addon)));

				return found.every((x) => x);
			})
		);
	};

const hasSecuritySubscription = (state) => (root) => {
	return (
		state.securitySubscriptions &&
		state.securitySubscriptions.some((o) => {
			return o.productName == root && o.subscribed;
		})
	);
};

const _fetchData = async ({ commit, state }, type, api, force = undefined) => {
	if (state[type].data.length && !force) return Promise.resolve(state[type].data);

	if (state[type].promise) return state[type].promise;

	const promise = api()
		.then((data) => {
			commit("setData", { type, data });
			return data;
		})
		.catch((e) => {
			throw e;
		});

	commit("setPromise", { type, promise });
	return promise;
};

export const state = getDefaultState;
export const mutations = {
	setData(state, { type, data }) {
		state[type].data = data;
		state[type].promise = null;
		state[type].isFetched = true;
	},
	setPromise(state, { type, promise }) {
		state[type].promise = promise;
		state[type].isFetched = false;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	},
};
export const actions = {
	async fetchSubscriptions(state, force = false) {
		return _fetchData(state, "subscriptions", mobileSubscriptionApi.getSubscriptions, force);
	},
	async fetchAgreements(state, force = false) {
		return _fetchData(state, "agreements", agreementsV2Api.getInventory, force);
	},
	async fetchHandsets(state, force = false) {
		return _fetchData(state, "handsets", handsetsApi.getHandsets, force);
	},
	async fetchSecuritySubscriptions(state, force = false) {
		return _fetchData(state, "securitySubscriptions", securitySubscriptionApi.getProducts, force);
	},
};
export const getters = {
	getSubscriptions: (state) => state.subscriptions.data || [],
	getAgreements: (state) => state.agreements.data || [],
	getHandsets: (state) => state.handsets.data || [],
	getSecuritySubscriptions: (state) => state.securitySubscriptions || [],
	subscriptionsWatcher: (state) => state.subscriptions.isFetched,
	agreementsWatcher: (state) => state.agreements.isFetched,
	handsetsWatcher: (state) => state.handsets.isFetched,
	hasSafe: (state) => hasAgreement(state)("SAFE"),
	hasSwap: (state) => hasAgreement(state)("DEVICE", "SWAP"),
	hasInsurance: (state) => hasAgreement(state)("DEVICE", "INSURANCE"),
	hasSecuritySubscriptionSafePlus: (state) => hasSecuritySubscription(state)("SAFE+"),
};
