import idGuardV1 from "~/integrations/id-guard-v1";

const priorities = Object.freeze({
	hasUnreadBreaches: 1,
	hasUnverifiedAssets: 2,
	noAssets: 3,
});

const errorMessages = {
	general: {
		title: "Tekniske feil",
		message:
			"Vi beklager! Noe gikk galt og vi kunne ikke laste denne modulen. Prøv igjen senere. Vennligst kontakt kundeservice dersom feilen fortsetter.",
	},
};

export const state = () => {
	return {
		textResponse: {
			isFetched: false,
			error: null,
			texts: undefined,
		},
		assetResponse: {
			isFetched: false,
			error: null,
			assetList: [],
			singleAsset: null,
			isAssetListUpdated: false,
		},
		leakedAssetResponse: {
			isFetched: false,
			error: null,
			leakedList: [],
		},
		breachResponse: {
			isFetched: false,
			error: null,
			domainList: [],
			assetList: [],
		},
		status: {
			isFetched: false,
			error: null,
			data: {
				hasUnreadBreaches: null,
				hasUnverifiedAssets: null,
				noAssets: null,
			},
		},
	};
};
export const actions = {
	async fetchAssets({ commit }) {
		try {
			const apiResponse = await idGuardV1.getAssets();
			commit("setAssetList", apiResponse);
			commit("setIsAssetsFetched", true);
		} catch (error) {
			commit("setAssetError", errorMessages.general);
		}
	},
	async fetchLeakedAssets({ state, commit, dispatch }) {
		try {
			if (!state.textResponse.isFetched) {
				await dispatch("fetchTexts");
			}
			const apiResponse = await idGuardV1.getLeakedAssets();
			commit("setLeakedAssets", apiResponse);
			commit("setIsLeakedAssetsFetched", true);
		} catch (error) {
			commit("setLeakedAssetsError", error);
			throw new Error("bubbling error to component");
		}
	},
	// This action is only relevant for use cases where customer is removing an asset from monitoring.
	// To minimize traffic and operations, action will call for specific asset.
	async fetchSingleAsset({ commit }, value) {
		try {
			const apiResponse = await idGuardV1.getSingleAsset(value);
			commit("setSingleAsset", apiResponse);
			return apiResponse;
		} catch (error) {
			commit("setAssetError", error);
			throw new Error("bubbling error to component");
		}
	},
	async createAsset({ commit }, { asset, type = "Email" }) {
		try {
			await idGuardV1.createAsset(asset, type);
			commit("setIsAssetListUpdated", true);
			commit("setIsAssetsFetched", false);
		} catch (error) {
			throw new Error(error);
		}
	},
	async removeAsset({ state, commit }, value) {
		try {
			await idGuardV1.deleteAsset(value);
			commit("setIsAssetListUpdated", true);
			if (state.assetResponse.isFetched === false) {
				commit("removeSingleAsset");
			} else {
				const index = state.assetResponse.assetList.findIndex((element) => element.id === value);
				commit("removeAsset", index);
			}
		} catch (error) {
			throw new Error(error);
		}
	},
	async fetchTexts({ commit }) {
		try {
			const texts = await idGuardV1.getTexts();
			commit("setTexts", texts);
			commit("setTextsFetched", true);
		} catch (error) {
			throw new Error(error);
		}
	},

	async fetchBreaches({ state, commit, dispatch }, value) {
		try {
			if (!state.textResponse.isFetched) {
				await dispatch("fetchTexts");
			}
			const breaches = await idGuardV1.getLeaksByDomain(value);
			commit("setBreachResponse", breaches);
			commit("setIsBreachesFetched", true);
		} catch (error) {
			commit("setBreachError", errorMessages.general);
			throw new Error(error);
		}
	},

	/* 		async fetchBreachesById({ commit }, value) {
        try {
            const breaches = await idGuardV1.getSpecifiedMatches(value);
            commit("setBreachList", breaches);
            commit("setIsBreachesFetched", true);
        } catch (error) {
            throw new Error(error);
        }
    }, */
	async fetchStatus({ commit }) {
		try {
			const apiResponse = await idGuardV1.getStatus();
			commit("setStatus", apiResponse);
			commit("setStatusFetched", true);
		} catch (error) {
			commit("setStatusError", error);
			throw new Error(error);
		}
	},

	async acknowledgeBreaches({ commit }, matchIds) {
		try {
			await idGuardV1.verifyMathces(matchIds, true);
			commit("setIsBreachesFetched", false);
		} catch (error) {
			throw new Error(error);
		}
	},
};
export const mutations = {
	setIsAssetsFetched(state, data) {
		state.assetResponse.isFetched = data;
	},
	setIsBreachesFetched(state, data = true) {
		state.breachResponse.isFetched = data;
	},
	setStatusFetched(state, data) {
		state.status.isFetched = data;
	},
	setAssetList(state, data) {
		state.assetResponse.assetList = data;
	},
	setIsAssetListUpdated(state, data) {
		state.assetResponse.isAssetListUpdated = data;
	},
	setBreachResponse(state, data) {
		state.breachResponse.domainList = data.domains;
		state.breachResponse.assetList = data.assets;
	},
	setStatus(state, data) {
		state.status.data.noAssets = !data.hasAssets;
		state.status.data.hasUnreadBreaches = data.hasUnreadBreaches;
		state.status.data.hasUnverifiedAssets = data.hasUnverifiedAssets;
	},

	setAssetError(state, data) {
		state.assetResponse.error = data;
	},
	setBreachError(state, data) {
		state.breachResponse.error = data;
	},
	setStatusError(state, data) {
		state.status.error = data;
	},
	removeSingleAsset(state) {
		state.assetResponse.singleAsset = null;
	},
	removeAsset(state, data) {
		state.assetResponse.assetList.splice(data, 1);
	},
	setSingleAsset(state, data) {
		state.assetResponse.singleAsset = data;
	},
	setLeakedAssets(state, data) {
		state.leakedAssetResponse.leakedList = data;
	},
	setIsLeakedAssetsFetched(state, data) {
		state.leakedAssetResponse.isFetched = data;
	},
	setLeakedAssetsError(state, data) {
		state.leakedAssetResponse.error = data;
	},
	setTexts(state, data) {
		state.textResponse.texts = data;
	},
	setTextsFetched(state, data) {
		state.textResponse.isFetched = data;
	},
};
export const getters = {
	assets(state) {
		return state.assetResponse.assetList;
	},
	isAssetsFetched(state) {
		return state.assetResponse.isFetched;
	},
	isAssetListUpdated(state) {
		return state.assetResponse.isAssetListUpdated;
	},
	assetError(state) {
		return state.assetResponse.error;
	},
	assetById: (state) => (id) => {
		return state.assetResponse.assetList.find((asset) => asset.id === id);
	},
	unverifiedAssets(state) {
		return state.assetResponse.assetList.filter((asset) => asset.verified === false);
	},
	verifiedAssets(state) {
		return state.assetResponse.assetList.filter((asset) => asset.verified === true);
	},
	assetCount(state) {
		return state.assetResponse.assetList.length;
	},
	leakedAssets(state) {
		return state.leakedAssetResponse.leakedList.assets;
	},

	breachDomains(state) {
		return state.breachResponse.domainList;
	},
	breachAssets(state) {
		return state.breachResponse.assetList;
	},
	isBreachesFetched(state) {
		return state.breachResponse.isFetched;
	},
	breachError(state) {
		return state.breachResponse.error;
	},
	breachesById: (state) => (idArray) => {
		return state.breachResponse.assetList.filter((asset) =>
			asset.matchIds.filter((matchId) => idArray.some((id) => matchId === id)),
		);
	},
	domainsById: (state) => (idArray) => {
		return state.breachResponse.domainList.filter((domain) =>
			domain.matchIds.filter((matchId) => idArray.some((id) => matchId === id)),
		);
	},
	unreadBreaches(state) {
		return state.breachResponse.assetList.filter((element) => element.read === false);
	},

	status(state) {
		return state.status.data;
	},
	isStatusFetched(state) {
		return state.status.isFetched;
	},
	priorityLevel(state) {
		const priorityType = Object.keys(state.status.data).find((key) => state.status.data[key]);
		if (priorityType === undefined && state.status.isFetched) return 4;
		return priorities[priorityType];
	},
};
