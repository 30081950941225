import fixedOrder from "~/integrations/fixed-order";
import { _fetchData } from "~/helpers/store";

export const state = () => ({
	promise: null,
	data: null,
	error: null,
});

export const actions = {
	async fetchData(context, { mock = false }) {
		const fixedState = context?.rootState?.["fixedOrder"].input;
		const address = context?.rootGetters?.["address/address"];
		const fixedFlow = context?.rootGetters?.["fixedOrder/fixedOrderFlow/currentFlow"];
		const fixedGetters = context?.rootGetters;
		context.commit("fixedOrder/tracking/setTrackingType", "order", { root: true });
		if (!mock) {
			return _fetchData(
				() =>
					fixedOrder.dispatch({
						dispatcher: fixedFlow?.name,
						subscriptionId: fixedState.subscriptionId || "",
						order: fixedGetters?.["fixedOrder/input/apiCompatibleOrder"],
						validatedOrder: fixedGetters["fixedOrder/validations/validatedOrder"],
						summary: fixedState.summary,
						address: address,
						bundleOfferId: fixedGetters["fixedOrder/input/selectedBundle"],
					}),
				{ context, force: true, name: "fixedOrder" },
			); // force::true allways try to order when dispatching
		} else {
			return {
				orderSuccessful: true,
				orderId: 12345,
			};
		}
	},
};

export const mutations = {
	setData(state, orderResponse) {
		state.data = orderResponse?.data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
};

export const getters = {
	ordering(state) {
		return !!state?.promise;
	},
	order(state) {
		return state?.data;
	},
};
