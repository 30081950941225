import epostConstants from "~/components/security-consumer/epost/common/epostConstants.js";

const filterAndSort = (list, RIGHTS) => {
	let _list = list.filter((o) => {
		return o.rights === RIGHTS;
	});
	_list.sort((a, b) => a.emailAddress.toLowerCase().localeCompare(b.emailAddress.toLowerCase()));
	return _list;
};

class EpostListTransformer {
	constructor(emailAccounts) {
		this.emailAccounts = emailAccounts || [];
	}

	// api response list sorted by status
	getByStatus(status) {
		let li = status ? this.emailAccounts.filter((o) => o.subscriptionStatus === status) : this.emailAccounts;

		return {
			count: li.length,
			ownerAndUserAccounts: filterAndSort(li, epostConstants.RIGHTS.OWNER_USER),
			ownerAccounts: filterAndSort(li, epostConstants.RIGHTS.OWNER),
			userAccounts: filterAndSort(li, epostConstants.RIGHTS.USER),
			payerAccounts: filterAndSort(li, epostConstants.RIGHTS.PAYER),
		};
	}

	// api response list sorted by account permission / rights
	getByRights(rights) {
		return filterAndSort(this.emailAccounts, rights);
	}
}

export default EpostListTransformer;
