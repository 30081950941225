// makeNewOrder :: JSON -> JSON
export const makeNewOrder = ({
	address,
	subscriptionId,
	deliveryAddress,
	offer,
	offerSelection,
	productSelections,
	customerInfo,
	contactDetails,
	equipmentSelections,
	installationSelections,
	fees,
	antennaSelections,
	OTConsent,
	connectDate,
	isWholesale,
}) => ({
	circuitId: offer?.meta?.orderProperties?.circuitId,
	consent: { acceptConsentFromChannel: OTConsent },
	addressId: address?.addressId,
	ispChange: offer?.meta?.orderProperties?.ispChange,
	valueChain: offer?.meta?.orderProperties?.valueChain,
	offerId: offerSelection,
	personals: {
		email: contactDetails?.emailAddress,
		phoneNumber: formatPhoneNumber(contactDetails?.phoneNumber),
		firstName: customerInfo?.firstName,
		lastName: customerInfo?.lastName,
		dateOfBirth: customerInfo?.birthDate,
	},
	customerId: customerInfo?.kurtId,
	connectDate: connectDate,
	isWholesale: isWholesale,
	products: makeProductOrder({
		productSelections,
		equipmentSelections,
		antennaSelections,
		installationSelections,
		fees,
	}),
	...(equipmentSelections?.reuseWifiEquipment ? { reuseWifiEquipment: equipmentSelections?.reuseWifiEquipment } : {}),
	...(deliveryAddress?.deliveryAddressType ? { deliveryAddressType: deliveryAddress?.deliveryAddressType } : {}),
	...(subscriptionId ? { subscriptionId: subscriptionId } : {}),
	// ...( cendData?.channelId ? { offerChannelid: cendData?.channelId } : {})
	// ...( cendData?.campaignId ? { campaignId: cendData?.campaignId } : {})
});

// makeProductOrder :: JSON -> JSON
export const makeProductOrder = ({
	antennaSelections,
	equipmentSelections,
	productSelections,
	installationSelections,
	fees,
}) => {
	return productSelections.reduce(
		(acc, curr) => ({
			...acc,
			[curr.category]: {
				productId: curr.productId,
				technologyType: curr?.technologyType,
				discounts: curr?.discounts,
				addons: [...antennaSelections, ...equipmentSelections, ...installationSelections, ...fees]
					?.filter((selection) => selection?.category === curr?.category)
					?.map((addon) => ({
						productId: addon?.productId,
						numSelected: addon?.numSelected,
						discounts: addon?.discounts,
					}))
					?.filter((addon) => addon?.numSelected !== 0),
			},
		}),
		{},
	);
};

export const formatPhoneNumber = (phoneNumber) => {
	let number = phoneNumber;
	if (/\+/.test(number)) {
		number = number.slice(1);
	}
	if (/\s/g.test(number)) {
		number = number.replaceAll(" ", "");
	}
	return number;
};

export const addProducts = ({ equipmentSelections, customerInfo, contactDetails }) => {
	return {
		order: equipmentSelections.map((item) => {
			return {
				offerId: item?.productOfferId,
				products: [
					{
						count: item?.numSelected,
						productId: item?.productId,
					},
				],
			};
		}),
		personals: {
			email: contactDetails?.emailAddress,
			phoneNumber: formatPhoneNumber(contactDetails?.phoneNumber),
			firstName: customerInfo?.firstName,
			lastName: customerInfo?.lastName,
			dateOfBirth: customerInfo?.birthDate,
		},
	};
};
