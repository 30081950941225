import epostConstants from "~/components/security-consumer/epost/common/epostConstants";
import epostApigeeIntegration from "~/components/security-consumer/epost/common/epostApigeeIntegration";
import { _fetchData } from "~/helpers/store";
import EpostListTransformer from "~/components/security-consumer/epost/common/epostListTransformer";

export const state = () => ({
	promise: null,
	isFetched: false,
	error: null,

	// constants
	...epostConstants,

	// all accounts
	emailAccounts: [],
	// filtered
	ownerAndUserAccounts: [],
	ownerAccounts: [],
	userAccounts: [],

	// selected
	emailAccount: null,

	actionState: {
		accountName: "",
		emailAddress: "",
		action: "",
		status: "",
		messageTitle: "",
		messageDescription: "",
	},

	needPageReload: false,
	needDataRefresh: false,
});
export const actions = {
	async fetchData(context, force = true) {
		return _fetchData(() => epostApigeeIntegration.getAccounts(), { context, force, name: "emailAccounts" });
	},
	setActionState({ commit }, data) {
		commit("setActionState", data);
	},
	resetActionState({ commit }, data) {
		commit("resetActionState", data);
	},
	setNeedPageReload({ commit }, data) {
		commit("setNeedPageReload", data);
	},
	setNeedDataRefresh({ commit }, data) {
		commit("setNeedDataRefresh", data);
	},
	setEmailAccount({ commit }, data) {
		commit("setEmailAccount", data);
	},
};
export const mutations = {
	setData(state, data) {
		state.emailAccounts = data || [];
		const transformedEpostList = new EpostListTransformer(state.emailAccounts);
		state.ownerAndUserAccounts = transformedEpostList.getByRights(state.RIGHTS.OWNER_USER);
		state.ownerAccounts = transformedEpostList.getByRights(state.RIGHTS.OWNER);
		state.userAccounts = transformedEpostList.getByRights(state.RIGHTS.USER);
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setIsFetched(state, data) {
		state.isFetched = data;
	},
	setError(state, error) {
		state.error = error;
	},

	//
	setEmailAccount(state, data) {
		state.emailAccount = data;
	},

	setActionState(state, data) {
		state.actionState = data;
	},
	resetActionState(state) {
		state.actionState = {
			action: "",
			status: "",
			messageTitle: "",
			messageDescription: "",
		};
	},
	setNeedPageReload(state, data) {
		state.needPageReload = data;
	},
	setNeedDataRefresh(state, data) {
		state.needDataRefresh = data;
	},
};
export const getters = {
	loading(state) {
		return !!state.promise;
	},
	dataFetched: (state) => !!state.emailAccounts.length,

	RIGHTS(state) {
		return state.RIGHTS;
	},
	STATUS(state) {
		return state.STATUS;
	},
	ACTIONS(state) {
		return state.ACTIONS;
	},
	PAGES(state) {
		return state.PAGES;
	},
	emailAccounts(state) {
		return state.emailAccounts;
	},
	ownerAndUserAccounts(state) {
		return state.ownerAndUserAccounts;
	},
	ownerAccounts(state) {
		return state.ownerAccounts;
	},
	userAccounts(state) {
		return state.userAccounts;
	},
	//
	emailAccountCached(state) {
		return state.emailAccount;
	},

	actionState(state) {
		return state.actionState;
	},

	needPageReload(state) {
		return state.needPageReload;
	},
	needDataRefresh(state) {
		return state.needDataRefresh;
	},
};
