import telenorid from "~/telenorid/telenorid";
import customers from "~/integrations/customers";

export const state = () => ({
	recipient: {
		firstName: "",
		lastName: "",
		nameChanged: false,
	},
	postalAddress: {
		streetName: "",
		streetNumber: "",
		postalPlace: "",
		postalCode: "",
		farId: -1,
	},
	shippingOption: null,
	loading: false,
	error: null,
});
export const actions = {
	async getPostalAddressFromCustomerApi({ commit }) {
		try {
			commit("setLoading", true);
			await telenorid.getOrLoginUser();
			const customer = await customers.getCustomerData();
			commit("setRecipient", {
				firstName: customer.firstName,
				lastName: customer.lastName,
				nameChanged: false,
			});
			commit("setPostalAddress", customer.postalAddress);

			return true;
		} catch (err) {
			commit("setError", err);
		} finally {
			commit("setLoading", false);
		}
	},
	// unused action?
	async getContactDetailsFromCustomerApi({ commit }) {
		try {
			commit("setLoading", true);
			await telenorid.getOrLoginUser();
			const contactDetails = await customers.getContactDetails();
			console.log("CONTACT DETAILS", contactDetails);
		} catch (e) {
			console.error(e);
		} finally {
			commit("setLoading", false);
		}
	},
	setPostalAddress({ state, commit }, customer) {
		if (state.recipient.firstName !== customer.firstName || state.recipient.lastName !== customer.lastName) {
			commit("setRecipient", {
				firstName: customer.firstName,
				lastName: customer.lastName,
				nameChanged: true,
			});
		}

		commit("setPostalAddress", customer.postalAddress);
	},
	setShippingOption({ commit }, shippingOption) {
		commit("setShippingOption", shippingOption);
	},
};
export const mutations = {
	setPostalAddress(state, postalAddress) {
		state.postalAddress = postalAddress;
	},
	setRecipient(state, recipient) {
		state.recipient = recipient;
	},
	setShippingOption(state, shippingOption) {
		state.shippingOption = shippingOption;
	},
	setLoading(state, loading) {
		state.loading = loading;
	},
	setError(state, error) {
		state.error = error;
	},
};
export const getters = {
	deliveryAddress(state) {
		if (state.postalAddress?.streetName)
			return {
				recipient: state.recipient,
				postalAddress: state.postalAddress,
			};
	},
	addressId(state) {
		if (state.addressId) return state.postalAddress.farId;
	},
	shippingOption(state) {
		if (state.shippingOption) {
			return state.shippingOption;
		}
	},
	address(state) {
		return state.data;
	},
	delivery(state) {
		if (state.recipient.nameChanged) {
			return {
				addressId: state.postalAddress.farId,
				deliveryService: "POSTEN", // TODO: FIX THIS
				name: `${state.recipient.firstName} ${state.recipient.lastName}`,
			};
		} else {
			return {
				addressId: state.postalAddress.farId,
				deliveryService: "POSTEN", // TODO: FIX THIS
			};
		}
	},
};
