import axios from "axios";
import apigeeIntegration from "./self-service-adapter";
import systemUserApigeeIntegration from "./self-service-system-adapter";
import { getQuery } from "~/components/broadband-consumer/AddressSearch/model.js";

const api = apigeeIntegration("/address-search/v1");
const systemUserApi = systemUserApigeeIntegration("/address-search/v1");

const bffUrl = axios.create({
	baseURL: "/api/address-search/",
	timeout: 2000,
});

function concatAddress(address) {
	let addressText = "";

	if (address.streetName) addressText += address.streetName;

	if (address.houseNumber) {
		addressText += " " + address.houseNumber;
		if (address.houseLetter) addressText += address.houseLetter;
	}

	if (address.postName) addressText += ", " + address.postName;

	return addressText;
}

const search = async (searchString) => api.get("/search/" + searchString);
const searchForLoggedOutUsers = (searchString) => systemUserApi.get("/search/" + searchString);
const getAddressById = async (addressid) => api.get(`/search/addressid/${encodeURIComponent(addressid)}`);
const getAddressByIdForLoggedOutUsers = async (addressid) =>
	systemUserApi.get(`/search/addressid/${encodeURIComponent(addressid)}`);
const getApartmentNumbers = async (query) => {
	return api.post(`/query`, query);
};
const getApartmentNumbersForLoggedOutUsers = async (query) => {
	return systemUserApi.post(`/query`, query, {
		headers: {
			"x-apicache-bypass": true,
		},
		cache: { override: true },
	});
};
const fetchApartments = ({ address, isLoggedIn }) => {
	return isLoggedIn ? getApartmentNumbers(getQuery(address)) : getApartmentNumbersForLoggedOutUsers(getQuery(address));
};

const fetchAddress = ({ addressId, isLoggedIn }) =>
	isLoggedIn ? getAddressById(addressId) : getAddressByIdForLoggedOutUsers(addressId);

const searchWithBFF = async (query) => {
	const res = await systemUserApi.get("/search/" + query);

	return {
		addresses: res
			.map((item) => {
				return {
					id: item.addressId,
					address: concatAddress(item).toLowerCase(),
					postCode: item.postCode,
				};
			})
			.sort((a, b) => (a.address > b.address ? 1 : b.address > a.address ? -1 : 0)),
	};
};

export default {
	search,
	searchForLoggedOutUsers,
	getAddressById,
	getAddressByIdForLoggedOutUsers,
	getApartmentNumbers,
	getApartmentNumbersForLoggedOutUsers,
	fetchApartments,
	fetchAddress,
	searchWithBFF,
};
