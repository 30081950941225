const match = (field, fieldValue) => ({ match: { [field]: fieldValue } });
const exists = (fieldName) => ({ exists: { field: fieldName } });
const MUST = "must";
const MUST_NOT = "must_not";
const matchNonNull = (body, keys, predefined = {}) => {
	const nonNullKeys = Object.keys(body).filter((key) => !!body[key] && keys.includes(key));
	return nonNullKeys.reduce((acc, key) => {
		acc[MUST] = acc[MUST] || [];
		acc[MUST].push(match(key, body[key]));
		return acc;
	}, predefined);
};

const cadastralStreetSearch = (body, size = 1) => {
	// Because the api for some reason returns "0" whilst UNR = null and actual null for FNR we have to make special rules.
	// So if 0 is entered it maps to must not exist
	let allowedKeys = ["propertyId", "propertyPartNumber", "municipalName", "sectionNumber"];
	let predefined = { [MUST_NOT]: [exists("apartmentNumber")], [MUST]: [match("statusId", "AV")] };
	if (body.leaseHoldNumber === "0") predefined.must_not = [...predefined.must_not, exists("leaseHoldNumber")];
	else if (body.leaseHoldNumber) allowedKeys = [...allowedKeys, "leaseHoldNumber"];
	return {
		size: size,
		query: {
			bool: {
				...matchNonNull(body, allowedKeys, predefined),
				minimum_should_match: "100%",
			},
		},
	};
};
//Theese keys should not match because the stem from a street address.
const excludedKeys = ["addressId", "apartmentNumber", "floorNumber"];
const prefix = (addressDTO, field) => {
	if (field === "leaseHoldNumber" && addressDTO[field] === "0") return MUST_NOT;
	else return addressDTO[field] ? MUST : MUST_NOT;
};
const value = (addressDTO, field) => {
	if (field === "leaseHoldNumber" && addressDTO[field] === "0") return exists(field);
	else return addressDTO[field] ? match(field, addressDTO[field]) : exists(field);
};
const streetAddressToAptQuery = (addressDTO, predefined) =>
	Object.keys(addressDTO).reduce((query, addressField) => {
		if (excludedKeys.includes(addressField)) return query;
		query[prefix(addressDTO, addressField)] = query[prefix(addressDTO, addressField)] || [];
		query[prefix(addressDTO, addressField)].push(value(addressDTO, addressField));
		return query;
	}, predefined);
const cadastralAptSearch = (addressDTO, size) => ({
	size: size,
	query: {
		bool: {
			...streetAddressToAptQuery(addressDTO, { [MUST]: [exists("apartmentNumber")] }),
			minimum_should_match: "100%",
		},
	},
});

export { cadastralStreetSearch, cadastralAptSearch };
