import {
	getOfferById,
	hasDiscounts,
} from "~/components/broadband-consumer/FixedOrder/steps/FixedOfferCards/FixedOfferModel";
import { makeNewOrder, addProducts } from "~/components/broadband-consumer/FixedOrder/models/FixedOrderModel";
import { _fetchData } from "~/helpers/store";
import productfeasibility from "~/integrations/productfeasibility";

const unique = (xs) => Array.from(new Set(xs));

const sortedBroadband = (offers) =>
	offers?.map((offer) => ({
		...offer,
		...(offer?.mainProducts?.BB
			? {
					mainProducts: {
						...offer.mainProducts,
						BB: offer.mainProducts?.BB?.sort(byDecreasingSpeed),
					},
				}
			: {}),
		...(offer?.mainProducts?.TV
			? {
					mainProducts: {
						...offer.mainProducts,
						TV: offer?.mainProducts?.TV,
					},
				}
			: {}),
	}));

const downloadSpeed = (product) => product?.bandwidth?.download?.value;

// byDecreasingSpeed :: (JSON, JSON) -> Number
const byDecreasingSpeed = (first, second) => downloadSpeed(second) - downloadSpeed(first);

export const state = () => {
	return {
		data: [],
		promise: null,
		promiseSettled: null,
		error: null,
		metaInformation: [],
		subscriptionId: "",
		currentStep: "",
		steps: [],
		deliveryAddress: null,
		selections: {
			offerId: null,
			mainProducts: [],
			installations: [],
			equipment: [],
			antenna: [],
			fees: [],
		},
		recommendedProducts: [],
		recommendationValues: {},
		connectDate: null,
		isAuthenticated: false,
		summary: null,
		acceptedTermsAndConditions: [],
	};
};
export const actions = {
	async fetchData(context, { isLoggedIn, force = false, addressId, subscriptionId }) {
		await _fetchData(
			() =>
				productfeasibility.fetch({
					fetcher: context.rootGetters?.["fixedOrder/fixedOrderFlow/currentFlow"]?.name,
					isLoggedIn: isLoggedIn,
					rcToken: context?.rootState?.reCaptcha?.token,
					addressId: addressId ?? context?.rootState?.address?.data?.addressId,
					subscriptionId: subscriptionId ?? context?.rootState["fixedOrder"].input.subscriptionId,
				}),
			{ context, force, name: "productfeasibility" },
		);
		return context.state.data;
	},
	//* Selects and resets
	resetAll({ commit }) {
		commit("setEquipment", []);
		commit("setMainProducts", []);
		commit("setInstallations", []);
		commit("setAntenna", []);
		commit("setFees", []);
		commit("setConsentedTermsAndCondition", []);
	},
	selectOffer({ commit, dispatch }, { offerId }) {
		dispatch("resetAll");
		commit("setOffer", offerId);
	},
	selectMainProducts({ commit, dispatch }, product) {
		dispatch("resetAll");
		commit("setMainProducts", product?.mainProducts);
		commit("setFees", product.fees);
	},
	selectEquipment({ commit }, equipment) {
		commit("setInstallations", []); // resetting installations when selecting equipment
		commit("setEquipment", equipment);
	},

	//* Navigation
	setCurrentStep({ state, commit }, location) {
		const currentStep = location ? state.steps.find((step) => step.name === location)?.name : location;
		commit("setCurrentStep", currentStep);
	},
	completeStep({ state, commit, dispatch, getters }) {
		const newSteps = state.steps.map((step, index) =>
			getters.currentStepIndex >= index ? { ...step, completed: true } : { ...step, completed: false },
		);
		commit("setSteps", newSteps);
		dispatch("nextStep");
	},
	nextStep({ state, getters, commit }) {
		const newCurrentStep =
			getters.currentStepIndex < state.steps.length - 1
				? state.steps[getters.currentStepIndex + 1]?.name
				: state.currentStep;
		commit("setCurrentStep", newCurrentStep);
	},
	previousStep({ state, getters, commit }) {
		const newCurrentStep =
			getters.currentStepIndex - 1 >= 0 ? state.steps[getters.currentStepIndex - 1]?.name : state.currentStep;
		commit("setCurrentStep", newCurrentStep);
	},
	clearRecommendations({commit}) {
			commit("setRecommendedProducts", []);
	},
	clearRecommendationValues({commit}) {
			commit("setRecommendationValues", {});
	},
	resetOfferId({commit}) {
			commit("setOffer", null);
	},
};
export const mutations = {
    setData(state, data) {
        if (data?.existingTechOffers) {
            state.data = sortedBroadband(data?.existingTechOffers);
        } else if (data?.offers) {
            state.data = sortedBroadband(data?.offers);
		} else {
			state.data = data;
        }

		if (data?.metaInformation) {
			state.metaInformation = data.metaInformation;
		}
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setPromiseSettled(state, promiseSettled) {
		state.promiseSettled = promiseSettled;
	},
	setError(state, error) {
		state.error = error;
	},
	setValidationPromise(state, promise) {
		state.validatedOrder.promise = promise;
	},
	setOrderPromise(state, promise) {
		state.orderResponse.promise = promise;
	},
	setValidatedOrder(state, data) {
		state.validatedOrder.data = data;
	},
	setOrderResponse(state, data) {
		state.orderResponse.data = data;
	},
	setValidationError(state, error) {
		state.validationError = error;
	},
	setCurrentStep(state, step) {
		state.currentStep = step;
	},
	setAddress(state, address) {
		state.address = address;
	},
	setAddressId(state, addressId) {
		state.addressId = addressId;
	},
	setOffer(state, offerId) {
		state.selections = {
			...state.selections,
			offerId: offerId,
		};
	},
	setOTConsent(state, OTConsent) {
		state.OTConsent = OTConsent;
	},
	setDeliveryAddress(state, deliveryAddress) {
		state.deliveryAddress = deliveryAddress;
	},
	setMainProducts(state, data) {
		state.selections.mainProducts = data;
	},
	setEquipment(state, data) {
		state.selections.equipment = data;
	},
	setInstallations(state, data) {
		state.selections.installations = data;
	},
	setAntenna(state, data) {
		state.selections.antenna = data;
	},
	setFees(state, data) {
		state.selections.fees = data;
	},
	setFlow(state, flow) {
		state.flow = flow;
	},
	setSteps(state, steps) {
		state.steps = steps;
	},
	setSubscriptionId(state, subscriptionId) {
		state.subscriptionId = subscriptionId;
	},
	setRecommendedProducts(state, recommendedProducts) {
		state.recommendedProducts = recommendedProducts;
	},
	setRecommendationValues(state, recommendationValues) {
		state.recommendationValues = recommendationValues;
	},
	setConnectDate(state, date) {
		state.connectDate = date;
	},
	setIsAuthenticated(state, isAuthenticated) {
		state.isAuthenticated = isAuthenticated;
	},
	setSummaryTiles(state, summary) {
		state.summary = summary;
	},
	setConsentedTermsAndCondition(state, acceptedTerms) {
		state.acceptedTermsAndConditions = acceptedTerms;
	},
};
export const getters = {
	offers(state) {
		return state?.data;
	},
	loading(state) {
		return !!state.promise;
	},
	dataFetched(state) {
		return !!state.data.length;
	},
	selectedOffer(state) {
		return getOfferById(state.data)(state.selections.offerId);
	},
	hasDiscounts(state, getters) {
		return hasDiscounts(getters.selectedOffer);
	},
	context(state) {
		return {
			address: state.address, // the address used for productfeasibility
			subscriptionId: state.subscriptionId, // the subscriptionId used for productfeasitility
		};
	},
	selectedBundle(state, getters) {
		return getters.selectedOffer?.meta?.bundleOfferId;
	},
	hasTv(state, getters) {
		return getters.selectedBundle?.includes("TV");
	},
	OTConsentRequired(state) {
		return state.data?.some((offer) => offer?.projectInformation?.publicGrantInformation?.consentRequired);
	},
	serviceTechnology(state, getters) {
		return getters?.selectedOffer?.serviceTechnology;
	},
	homeStatus(state, getters) {
		/* Address has been connected to infrastructure (not reliable data for HFC) */
		return getters.serviceTechnology === "HFC" || getters.serviceTechnology?.includes("DSL")
			? "UNKNOWN"
			: getters.selectedOffer?.meta?.productProperties?.categories?.BB?.homeStatus === "CONNECTED"
				? "ON-NET"
				: "OFF-NET";
	},
	isTBB(state, getters) {
		return getters.selectedOffer?.serviceTechnology === "FWA";
	},
	bbOnly(state, getters) {
		return getters.selectedOffer?.serviceTechnology === "GPON";
	},
	hasOrderedEquipment(state) {
		return [...state.selections.antenna, ...state.selections.equipment]?.some((eq) => eq?.numSelected > 0);
	},
	isBrownfield(state, getters) {
		return getters.selectedOffer?.offerType === "plannedTechnology";
	}, // i.e. plannedTechnology
	//* Selections ==========================
	selectedMainProducts(state) {
		return state.selections?.mainProducts;
	},
	selectedEquipment(state) {
		return state?.selections?.equipment;
	},
	selectedAntenna(state) {
		return state.selections?.antenna;
	},
	selectedInstallations(state) {
		return state?.selections?.installations;
	},
	selectedFees(state) {
		return state?.selections?.fees;
	},

	currentStep(state) {
		return state.currentStep;
	},

	apiCompatibleOrder(state, getters, rootState, rootGetters) {
		return rootGetters["fixedOrder/fixedOrderFlow/currentFlow"]?.name === "add-equipment"
			? addProducts({
					equipmentSelections: state.selections.equipment,
					customerInfo: { ...rootState.customer },
					contactDetails: { ...rootGetters["contactDetails/contactDetails"] },
				})
			: makeNewOrder({
					address: rootState.address.data,
					subscriptionId: state.subscriptionId,
					deliveryAddress: state.deliveryAddress,
					offer: getters.selectedOffer,
					offerSelection: state.selections.offerId,
					productSelections: state.selections.mainProducts,
					equipmentSelections: state.selections.equipment,
					installationSelections: state.selections.installations,
					fees: state.selections.fees,
					antennaSelections: state.selections.antenna,
					customerInfo: { ...rootState.customer },
					contactDetails: { ...rootGetters["contactDetails/contactDetails"] },
					serviceInfo: [], // TODO remove?
					OTConsent: getters.OTConsent,
					connectDate: getters.connectDate,
					isWholesale: getters.isWholesale,
				});
	},
	recommendedProducts(state) {
		return state?.recommendedProducts;
	},
	recommendationValues(state) {
		return state?.recommendationValues;
	},
	availableRouters(state) {
		return (
			state.selections?.mainProducts
				.find((product) => product.category.includes("BB"))
				?.additionalProducts?.filter((product) => product?.texts?.name?.text?.toLowerCase().includes("ruter")) || []
		);
	},
	availableExtenders(state) {
		return (
			state.selections?.mainProducts
				.find((product) => product.category.includes("BB"))
				?.additionalProducts?.filter((product) => product?.texts?.name?.text?.toLowerCase().includes("forsterker")) ||
			[]
		);
	},
	availableTweEquipment(state) {
		return (
			state.selections?.mainProducts
				.find((product) => product.category.includes("TV"))
				?.additionalProducts?.filter((product) => product?.texts?.name?.text.toLowerCase().includes("t-we boks")) || []
		);
	},
	deliveryAddress(state) {
		return state.address;
	},
	getSteps(state) {
		return state.steps;
	},
	isChangeFlow(state) {
		return state.flow?.apiResource === "change";
	},
	isAddEquipmentFlow(state) {
		return state.flow?.apiResource === "add";
	},
	connectDate(state) {
		return state.connectDate;
	},
	isWholesale(state, getters) {
		const selectedOffer = getters.selectedOffer;
		return (
			state.metaInformation?.feasibilitySummary?.technology?.filter((technology) => technology.wholesale)?.[0]?.type ===
				selectedOffer?.serviceTechnology && !!selectedOffer?.serviceTechnology
		);
	},
	offersArePlanned(state, getters) {
		return getters.offers?.some((offer) => offer?.offerType === "plannedTechnology");
	},
	offersAreCabin(state, getters) {
		return getters.offers?.some((offer) => offer?.meta?.bundleOfferId?.includes("CABIN"));
	},
	valueChains(state, getters) {
		return unique(getters.offers?.map((offer) => offer?.meta?.orderProperties?.valueChain));
	},
	technologies(state, getters) {
		if (getters.isMdu) {
			return ["kollektivavtale"];
		} else if (!getters?.offers?.length) {
			return ["kanikkelevere"];
		}
		return unique(
			getters.offers
				?.flatMap((offer) => offer.technologies)
				?.filter((x) => x)
				?.sort(),
		);
	},
	plannedFWA(state, getters) {
		return getters.FWAFeasibilitySummary.answerCode === 15;
	},
	plannedFWADate(state, getters) {
		return getters.FWAFeasibilitySummary.fwa?.plannedDate;
	},
	FWAFeasibilitySummary: (state) =>
		state.metaInformation?.feasibilitySummary?.technology?.find((tech) => tech.type === "FWA") || {},
	acceptedTermsAndConditions: (state) => state.acceptedTermsAndConditions,
	bindingInformation(state) {
		return state.selections.mainProducts?.[0]?.bindingInformation;
	},
	offersHaveBinding(state, getters) {
		return (
			getters.selectedOffer?.mainProducts?.BB?.some((speed) =>
				speed?.additionalProducts.some((product) => product.actualProductId === "FE0006"),
			) || false
		);
	},
	promiseSettled(state) {
		return state.promiseSettled;
	},
	dualPlayAvailable(state) {
		return state.data.some((offer) => ["TV", "BB"].every((category) => offer?.categories?.includes(category)));
	},
	availableTechnology(state) {
		return state.data.find((offer) => offer?.categories?.includes("BB"))?.serviceTechnology;
	},
	getOfferAddressId(state, getters) {
		if (getters.promiseSettled) {
			return state.metaInformation.feasibilitySummary?.address?.addressId;
		}
		return null;
	},
	getFeasibilityError(state) {
		return state.error;
	},
	OTConsent(state) {
		return state.acceptedTermsAndConditions.includes("otconsent");
	},
};


