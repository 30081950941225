export default {
	computed: {
		segmentation() {
			return this.component?.segmentation || this.layout?.segmentation;
		},
		mustSegments() {
			let mustSegments = [];
			if (this.$config.public.isPreview && this.$store.state.gizmo?.labels) {
				if (this.segmentation?.must) mustSegments = [...this.segmentation?.must];
				if (this.segmentation?.device?.must) mustSegments = [...mustSegments, ...this.segmentation?.device?.must];
			}
			return mustSegments;
		},
		mustNotSegments() {
			let mustNotSegments = [];
			if (this.$config.public.isPreview && this.$store.state.gizmo?.labels) {
				if (this.segmentation?.mustNot) mustNotSegments = [...this.segmentation?.mustNot];
				if (this.segmentation?.device?.mustNot)
					mustNotSegments = [...mustNotSegments, ...this.segmentation?.device?.mustNot];
			}
			return mustNotSegments;
		},
	},
};
