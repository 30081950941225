export const getInsuranceClaimLink = ({ useRedirectLink, imei, insuranceClaimLinkForRedirect, insuranceClaimLink }) => {
	window.location.href = useRedirectLink && imei ? `${insuranceClaimLinkForRedirect}${imei}` : insuranceClaimLink;
};

export const getBenefits = ({ benefits, deviceType = "PHONE", isSwapInsurance = false, isPlusInsurance = false }) => {
	if (deviceType === "PHONE") {
		if (isSwapInsurance) {
			if (isPlusInsurance) {
				return benefits?.swapPlusInsurance;
			} else {
				return benefits?.swapScreenInsurance;
			}
		} else {
			if (isPlusInsurance) {
				return benefits?.plusInsurance;
			} else {
				return benefits?.screenInsurance;
			}
		}
	} else if (deviceType === "TABLET") {
		if (isSwapInsurance) {
			if (isPlusInsurance) {
				return benefits?.tabletSwapPlusInsurance;
			} else {
				return benefits?.tabletSwapScreenInsurance;
			}
		} else {
			if (isPlusInsurance) {
				return benefits?.tabletPlusInsurance;
			} else {
				return undefined;
			}
		}
	} else if (deviceType === "SMART_WATCH") {
		if (isPlusInsurance) {
			return benefits?.watch;
		} else {
			return undefined;
		}
	} else {
		return benefits?.plusInsurance;
	}
};

export const getInsuranceExtraInfoText = ({ selectedInfoText, poid }) => {
	return selectedInfoText?.find((info) => info?.poid === poid)?.infoText;
};
